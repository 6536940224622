(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var _app = require('./app.js');

var MODULE_NAME = 'admin.application.prod'; /**
                                             * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                             *
                                             * This program is free software: you can redistribute it and/or modify it under
                                             * the terms of the GNU Affero General Public License as published by the Free
                                             * Software Foundation, either version 3 of the License, or (at your option) any
                                             * later version.
                                             *
                                             * This program is distrubuted in the hope that it will be useful,
                                             * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                             * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                             * GNU Affero General Public License for more details.
                                             *
                                             * You should have received a copy of the GNU Affero General Public License
                                             * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                             */

angular.module(MODULE_NAME, [_app.MODULE_NAME]);

angular.element(document).ready(function () {

    angular.bootstrap(document, [MODULE_NAME]);
});

},{"./app.js":2}],2:[function(require,module,exports){
'use strict';

var _security = require('./modules/security/security.js');

var _update = require('./modules/update/update.js');

var _user = require('./modules/user/user.js');

var _tagContainer = require('./modules/tagContainer/tagContainer.js');

var _tag = require('./modules/tag/tag.js');

var _trigger = require('./modules/trigger/trigger.js');

var _variable = require('./modules/variable/variable.js');

var _integration = require('./modules/integration/integration.js');

var _condition = require('./modules/condition/condition.js');

var _debug = require('./modules/debug/debug.js');

var _alert = require('./../modules/alert/alert.js');

var _translation = require('./../modules/translation/translation.js');

var _form = require('./../modules/form/form.js');

var _confirm = require('./../modules/confirm/confirm.js');

var _sanitize = require('./../modules/sanitize/sanitize.js');

var _clipboard = require('./../modules/clipboard/clipboard.js');

var _pageInfo = require('./../modules/pageInfo/pageInfo.js');

var _helpSystem = require('./../modules/helpSystem/helpSystem.js');

var _validator = require('./../modules/validator/validator.js');

var _textEditor = require('./../modules/textEditor/textEditor.js');

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'admin.application';

angular.module('admin.application.plugins', [_tag.MODULE_NAME]);

angular.module(MODULE_NAME, ['pascalprecht.translate', 'cgBusy', 'ui.router', 'ui.bootstrap', 'checklist-model', 'http-auth-interceptor', _security.MODULE_NAME, _update.MODULE_NAME, _user.MODULE_NAME, _condition.MODULE_NAME, _trigger.MODULE_NAME, _variable.MODULE_NAME, _integration.MODULE_NAME, _tag.MODULE_NAME, _tagContainer.MODULE_NAME, _alert.MODULE_NAME, _translation.MODULE_NAME, _form.MODULE_NAME, _confirm.MODULE_NAME, _sanitize.MODULE_NAME, _clipboard.MODULE_NAME, _pageInfo.MODULE_NAME, _helpSystem.MODULE_NAME, _validator.MODULE_NAME, _debug.MODULE_NAME, _textEditor.MODULE_NAME, 'ngClipboard', 'admin.application.plugins']).run(['$rootScope', '$state', 'clearcode.tm.alert.$alert', 'clearcode.tm.security.security', 'clearcode.tm.security.oauthResource', 'clearcode.tm.security.tokenStorage', 'authService', 'clearcode.tm.variable.$variableForm', function ($rootScope, $state, $alertProvider, security, OAuthResource, tokenStorage, authService, $variableFormProvider) {

    $variableFormProvider.addType('dataLayer', {
        templateUrl: 'app/modules/variable/views/types/dataLayer.html'
    }).addType('cookie', {
        templateUrl: 'app/modules/variable/views/types/cookie.html'
    }).addType('constant', {
        templateUrl: 'app/modules/variable/views/types/constant.html'
    }).addType('document', {
        templateUrl: 'app/modules/variable/views/types/document.html'
    }).addType('random', {
        templateUrl: 'app/modules/variable/views/types/random.html'
    }).addType('url', {
        templateUrl: 'app/modules/variable/views/types/url.html'
    });

    $alertProvider.addMessagePattern('error.invalid', 'Form is invalid. Please correct selected fields');

    $alertProvider.addMessagePattern('success.create', 'Settings saved successfully');

    $alertProvider.addMessagePattern('success.edit', 'Settings saved successfully');

    $alertProvider.addMessagePattern('success.remove', 'Deleted successfully');

    $rootScope.$state = $state;
    $rootScope.animateSidebarReady = false;

    $rootScope.isContainerView = function () {
        return false;
    };

    $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {

        $rootScope.isContainerView = function () {

            var hasContainerList = toState.data.hasContainerList !== undefined ? toState.data.hasContainerList : false,
                isAuthenticated = security.isAuthenticated();

            if ($rootScope.animateSidebarReady === false && hasContainerList && isAuthenticated) {

                $rootScope.animateSidebarReady = true;
            }

            return hasContainerList && isAuthenticated;
        };

        $rootScope.activeContainerId = toStateParams.containerId;
    });

    $rootScope.$on('event:auth-loginRequired', function () {

        var authToken = tokenStorage.getItem();

        if (undefined !== authToken) {

            OAuthResource.refreshToken(authToken.refreshToken).then(function (data) {

                tokenStorage.addItem(data);

                authService.loginConfirmed('success', function (config) {

                    config.headers.Authorization = tokenStorage.getToken();

                    return config;
                });
            }, function () {

                authService.loginCancelled('invalid');
            });
        }
    });
}]).config(['$urlRouterProvider', 'ngClipProvider', '$translateProvider', function ($urlRouterProvider, ngClipProvider, $translateProvider) {

    $urlRouterProvider.otherwise('/containers');
    ngClipProvider.setPath('ZeroClipboard.swf');

    $translateProvider.useSanitizeValueStrategy('escape');

    $translateProvider.useStaticFilesLoader({
        prefix: '/api/translations/',
        suffix: '.json'
    });

    $translateProvider.preferredLanguage('en');
    $translateProvider.useLocalStorage();
}]).value('cgBusyDefaults', {
    wrapperClass: 'loading'
}).value('longDataFormat', 'dd MMMM yyyy').value('defaultDateFormat', 'yyyy-MM-dd HH:mm').filter('reverse', function () {

    return function (items) {

        return items.slice().reverse();
    };
});

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./../modules/alert/alert.js":91,"./../modules/clipboard/clipboard.js":99,"./../modules/confirm/confirm.js":101,"./../modules/form/form.js":108,"./../modules/helpSystem/helpSystem.js":111,"./../modules/pageInfo/pageInfo.js":116,"./../modules/sanitize/sanitize.js":120,"./../modules/textEditor/textEditor.js":126,"./../modules/translation/translation.js":129,"./../modules/validator/validator.js":131,"./modules/condition/condition.js":3,"./modules/debug/debug.js":9,"./modules/integration/integration.js":17,"./modules/security/security.js":24,"./modules/tag/tag.js":39,"./modules/tagContainer/tagContainer.js":52,"./modules/trigger/trigger.js":60,"./modules/update/update.js":65,"./modules/user/user.js":76,"./modules/variable/variable.js":90}],3:[function(require,module,exports){
'use strict';

var _ConditionResource = require('./resource/ConditionResource.js');

var _ConditionResource2 = _interopRequireDefault(_ConditionResource);

var _Condition = require('./services/Condition.js');

var _Condition2 = _interopRequireDefault(_Condition);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.condition';

_Condition2.default.$inject = [MODULE_NAME + '.conditionResource', 'clearcode.tm.tagContainer.currentContainer'];

_ConditionResource2.default.$inject = ['$http', '$q'];

angular.module(MODULE_NAME, ['clearcode.tm.tagContainer']).service(MODULE_NAME + '.conditionResource', _ConditionResource2.default).service(MODULE_NAME + '.condition', _Condition2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./resource/ConditionResource.js":5,"./services/Condition.js":6}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var conditionFactory = function conditionFactory() {

  /**
   * @name Condition
   * @description Condition entity
   */

  var Condition = function Condition() {
    _classCallCheck(this, Condition);

    /**
     * @type {number}
     */
    this.id = undefined;

    /**
     * @type {string}
     */
    this.variable = undefined;

    /**
     * @type {string}
     */
    this.condition = undefined;

    /**
     * @type {string}
     */
    this.value = undefined;
  };

  return Condition;
};

exports.default = conditionFactory;

},{}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Condition = require('../entity/Condition.js');

var _Condition2 = _interopRequireDefault(_Condition);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * @name ConditionResource
 */

var ConditionResource = function () {

    /**
     * @param  {$http} $http
     * @param  {$q} $q
     */

    function ConditionResource($http, $q) {
        _classCallCheck(this, ConditionResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Condition}
     */


    _createClass(ConditionResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Condition = (0, _Condition2.default)();

            return new Condition();
        }

        /**
         * @description Send request to get conditions with their specific variables
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + id + '/conditions'
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return ConditionResource;
}();

exports.default = ConditionResource;

},{"../entity/Condition.js":4}],6:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * Finding type by type id
 * @param  {Array} array
 * @param  {Number} type
 * @return {Object}
 */
var findTypeByType = function findTypeByType(array, type) {

    var tempType = type;

    return array.filter(function (element) {
        return element.type === tempType;
    });
};

var currentContainer = undefined;
var conditionResource = undefined;

var Condition = function () {

    /**
     * @param  {ConditionResource} $conditionResource
     * @param {CurrentContainer} $currentContainer
     */

    function Condition($conditionResource, $currentContainer) {
        _classCallCheck(this, Condition);

        currentContainer = $currentContainer;
        conditionResource = $conditionResource;

        if (currentContainer.getId() !== undefined) {
            this.conditionResourcePromise = conditionResource.query(currentContainer.getId());
        }
    }

    /**
     * Return array of all types indexed by their type
     * @return {Array}
     */


    _createClass(Condition, [{
        key: 'getArrayOfTypes',
        value: function getArrayOfTypes() {

            return this.conditionResourcePromise.then(function (resp) {

                var typesTemp = [],
                    typesIndex = resp.length;

                while (typesIndex--) {

                    typesTemp[resp[typesIndex].type] = resp[typesIndex].name;
                }

                return typesTemp;
            });
        }

        /**
         * All variables from specific condition
         * @return {Array}
         */

    }, {
        key: 'allVariables',
        value: function allVariables() {

            return this.conditionResourcePromise.then(function (resp) {
                var variables = [];
                for (var index = 0; index < resp.length; index++) {
                    variables = variables.length ? mergeVariables(variables, resp[index].variables) : resp[index].variables;
                }

                return variables;
            });
        }

        /**
         * Get variables from specific condition
         * @param  {Number} type
         * @return {Array}
         */

    }, {
        key: 'getVariables',
        value: function getVariables(type) {

            var self = this;

            this.type = type;

            return this.conditionResourcePromise.then(function (resp) {

                var responsedTypes = findTypeByType(resp, self.type);

                if (responsedTypes.length === 1) {

                    return responsedTypes[0].variables;
                } else {

                    return false;
                }
            });
        }

        /**
         * Get required variables from specific condition
         * @param  {Number} type
         * @return {Array}
         */

    }, {
        key: 'getRequired',
        value: function getRequired(type) {

            var self = this;

            this.type = type;

            return this.conditionResourcePromise.then(function (resp) {

                var responsedTypes = findTypeByType(resp, self.type);

                if (responsedTypes.length === 1) {

                    return responsedTypes[0].variables.filter(function (variable) {

                        return variable.name === 'Event' && responsedTypes[0].name === 'Event';
                    });
                } else {

                    return false;
                }
            });
        }

        /**
         * Get actions from specific condition
         * @param  {Number} type
         * @return {Array}
         */

    }, {
        key: 'getActions',
        value: function getActions(type) {

            var self = this;

            this.type = type;

            return this.conditionResourcePromise.then(function (resp) {

                var responsedTypes = findTypeByType(resp, self.type);

                if (responsedTypes.length === 1) {

                    return responsedTypes[0].actions;
                } else {

                    return false;
                }
            });
        }
    }, {
        key: 'refresh',
        value: function refresh() {

            this.conditionResourcePromise = conditionResource.query(currentContainer.getId());
        }
    }]);

    return Condition;
}();

function mergeVariables(arrayFirst, arraySecond) {
    var final = [];
    for (var indexFirst in arrayFirst) {
        var shared = false;
        for (var indexSecond in arraySecond) {
            if (isVariablesNameEqual(arraySecond[indexSecond], arrayFirst[indexFirst])) {
                shared = true;
                break;
            }
        }
        if (!shared) {
            final.push(arrayFirst[indexFirst]);
        }
    }

    return final.concat(arraySecond);
}

function isVariablesNameEqual(variableFirst, variableSecond) {
    return variableFirst.name === variableSecond.name;
}

exports.default = Condition;

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$state}
 */
var state;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {CurrentContainer}
 */
var currentContainer;

/**
 * @type {$scope}
 */
var scope;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Debug';

/**
 * @name DebugOptionsController
 */

var DebugOptionsController =

/**
 * @param {ContainerResource} containerResource
 * @param {$state} $state
 * @param {$stateParams} $stateParams
 * @param {currentContainer} CurrentContainer
 * @param {PageInfo} PageInfo
 * @param {$scope} $scope
 * @param {$translate} $translate
 */
function DebugOptionsController(containerResource, $state, $stateParams, CurrentContainer, PageInfo, $scope, $translate) {
    var _this = this;

    _classCallCheck(this, DebugOptionsController);

    var self = this;

    state = $state;
    stateParams = $stateParams;
    currentContainer = CurrentContainer;
    scope = $scope;

    this.container = containerResource.getEntityObject();

    this.containerPromise = containerResource.get(stateParams.containerId);

    this.containerPromise.then(function (resp) {

        self.container = resp;
    });

    this.translate = $translate;

    currentContainer.getContainer().then(function () {

        if (currentContainer.$container.hasPermission('noaccess')) {

            state.go('container');
        }

        _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(currentContainer.$container.name, 'tags', {
                containerId: currentContainer.$container.id
            }).add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    });

    scope.$on('pageInfo.reload', function (event, args) {

        _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(args.name, 'tags', {
                containerId: args.id
            }).add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    });
};

exports.default = DebugOptionsController;

},{}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$state}
 */
var state;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {CurrentContainer}
 */
var currentContainer;

/**
 * @type {WebsiteResource}
 */
var websitesResource;

/**
 * @name WebsitesController
 */

var WebsitesController = function () {

    /**
     * @param {WebsiteResource} $websitesResource
     * @param {$state} $state
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {CurrentContainer} $currentContainer
     * @param $translate
     * @param $scope
     */

    function WebsitesController($websitesResource, $state, $stateParams, $alert, $currentContainer, $translate, $scope) {
        var _this = this;

        _classCallCheck(this, WebsitesController);

        state = $state;
        stateParams = $stateParams;
        alert = $alert;
        currentContainer = $currentContainer;
        websitesResource = $websitesResource;

        this.websites = [];
        this.websitesPromise = websitesResource.query(stateParams.containerId);

        this.websitesPromise.then(function (resp) {

            _this.websites = resp.data;
        });

        $translate(['by hash', 'by query']).then(function (translations) {

            _this.parameterTypes = [{
                value: 0,
                name: translations['by query']
            }, {
                value: 1,
                name: translations['by hash']
            }];
        });

        $scope.getWebsiteUrlWithParameter = function (website) {

            return website.getUrlWithParameter();
        };
    }

    /**
     * Method to create new container website
     */


    _createClass(WebsitesController, [{
        key: 'addContainerWebsite',
        value: function addContainerWebsite() {

            if (!currentContainer.$container.hasPermission('edit')) {
                return;
            }

            if (!this.websites) {

                this.websites = [];
            }

            this.websites.push(websitesResource.getEntityObject());
        }

        /**
         * Method to remove container websites
         *
         * @param {Number} index
         */

    }, {
        key: 'removeContainerWebsite',
        value: function removeContainerWebsite(index) {

            this.websites.splice(index, 1);
        }

        /**
         * Handle form submit action
         */

    }, {
        key: 'submitForm',
        value: function submitForm() {
            var _this2 = this;

            var websites = [];

            for (var i = 0; this.websites && i < this.websites.length; i++) {

                var website = websitesResource.getEntityObject();

                website.url = this.websites[i].url;
                website.parameterType = this.websites[i].parameterType;

                websites.push(website);
            }

            this.websitePromise = websitesResource.put(stateParams.containerId, websites);

            this.websitePromise.then(function () {

                currentContainer.get(stateParams.containerId); // triggers refresh of data in f.e. 'Preview and debug'
                alert.success('success.edit');

                state.go('debug', { containerId: stateParams.containerId });
            }, function () {

                alert.error('error.invalid');

                _this2.validateContainer = true;
            });
        }
    }]);

    return WebsitesController;
}();

exports.default = WebsitesController;

},{}],9:[function(require,module,exports){
'use strict';

var _DebugOptionsController = require('./controllers/DebugOptionsController.js');

var _DebugOptionsController2 = _interopRequireDefault(_DebugOptionsController);

var _WebsitesController = require('./controllers/WebsitesController.js');

var _WebsitesController2 = _interopRequireDefault(_WebsitesController);

var _WebsiteResource = require('./resource/WebsiteResource.js');

var _WebsiteResource2 = _interopRequireDefault(_WebsiteResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.debug'; /**
                                         * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                         *
                                         * This program is free software: you can redistribute it and/or modify it under
                                         * the terms of the GNU Affero General Public License as published by the Free
                                         * Software Foundation, either version 3 of the License, or (at your option) any
                                         * later version.
                                         *
                                         * This program is distrubuted in the hope that it will be useful,
                                         * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                         * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                         * GNU Affero General Public License for more details.
                                         *
                                         * You should have received a copy of the GNU Affero General Public License
                                         * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                         */

var MODULE_CONTAINER = 'clearcode.tm.tagContainer';
var MODULE_DIR = 'app/modules/debug';

_WebsiteResource2.default.$inject = ['$http', '$q'];

_WebsitesController2.default.$inject = [MODULE_NAME + '.websiteResource', '$state', '$stateParams', 'clearcode.tm.alert.alert', MODULE_CONTAINER + '.currentContainer', '$translate', '$scope'];

_DebugOptionsController2.default.$inject = [MODULE_CONTAINER + '.containerResource', '$state', '$stateParams', MODULE_CONTAINER + '.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$translate'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'ngCookies', 'clearcode.tm.condition', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.user']).run(['$rootScope', MODULE_CONTAINER + '.currentContainer', '$stateParams', 'clearcode.tm.alert.$alert', function ($rootScope, currentContainer, $stateParams, $alertProvider) {

    $rootScope.currentContainerLoading = true;
    $rootScope.currentContainer = currentContainer;

    var loadContainer = function loadContainer(containerId) {

        if (containerId !== $stateParams.containerId) {

            var currentContainerPromise = currentContainer.get(containerId);

            currentContainerPromise.then(function (container) {

                $rootScope.$broadcast('pageInfo.reload', container);
            });
        }
    };

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

        loadContainer(toParams.containerId);
    });
}]).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('debug', {
        url: '/containers/{containerId}/debug',
        controller: MODULE_NAME + '.DebugOptionsController as view',
        templateUrl: MODULE_DIR + '/views/options.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true,
            permission: ['view']
        }
    });
}]).controller(MODULE_NAME + '.DebugOptionsController', _DebugOptionsController2.default).controller(MODULE_NAME + '.WebsitesController', _WebsitesController2.default).service(MODULE_NAME + '.websiteResource', _WebsiteResource2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/DebugOptionsController.js":7,"./controllers/WebsitesController.js":8,"./resource/WebsiteResource.js":11}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var websiteFactory = function websiteFactory(resource) {

    var DEBUG_PARAM_NAME = '_stg_debug';
    var URL_MIN_LENGTH = 10;

    /**
     * @name Website
     * @description Website entity
     */

    var Website = function () {
        function Website() {
            _classCallCheck(this, Website);

            /**
             * @type {string}
             */
            this.url = '';

            /**
             * @type {Integer}
             */
            this.parameterType = 0;
        }

        /**
         * @returns {string}
         */


        _createClass(Website, [{
            key: 'getUrlWithParameter',
            value: function getUrlWithParameter() {

                if (this.hasUrl() === false) {

                    return '';
                }

                return this.url + this.getSeparator() + DEBUG_PARAM_NAME;
            }

            /**
             * @returns {string}
             */

        }, {
            key: 'getSeparator',
            value: function getSeparator() {

                var separator = this.parameterType === 1 ? '#' : '?';

                if (this.hasUrl() === false) {

                    return separator;
                }

                return this.url.indexOf(separator) === -1 ? separator : '&';
            }
        }, {
            key: 'hasUrl',
            value: function hasUrl() {

                return this.url !== undefined && this.url.length > URL_MIN_LENGTH;
            }
        }]);

        return Website;
    }();

    return Website;
};

exports.default = websiteFactory;

},{}],11:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Website = require('../entity/Website.js');

var _Website2 = _interopRequireDefault(_Website);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {
    var _this = this;

    if (angular.isArray(resp)) {
        return resp.map(function (websiteData) {
            return transformResponse.call(_this, websiteData);
        });
    }

    var website = this.getEntityObject();

    if (resp !== undefined) {

        website.url = resp.url;
        website.parameterType = resp.parameter_type;
    }

    return website;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name WebsiteResource
 *
 */

var WebsiteResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function WebsiteResource($http, $q) {
        _classCallCheck(this, WebsiteResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Website}
     */


    _createClass(WebsiteResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Website = (0, _Website2.default)(this);

            return new Website();
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {number} containerId
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(containerId, params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + containerId + '/websites',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (isResponseSuccess(resp, status)) {
                        for (var i = 0; i < resp.data.length; i++) {
                            if (resp.data[i] instanceof Object) {
                                resp.data[i] = transformResponse.call(_this2, resp.data[i]);
                            }
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {
                deferred.resolve(resp);
            }).error(function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }, {
        key: 'put',
        value: function put(containerId, websites) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                data: {
                    websites: websites
                },
                url: 'api/containers/' + containerId + '/websites',
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (isResponseSuccess(resp, status)) {
                        return transformResponse.call(_this3, resp.data);
                    }

                    return resp;
                })
            }).success(function (resp) {
                deferred.resolve(resp);
            }).error(function (err) {
                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return WebsiteResource;
}();

function isResponseSuccess(resp, status) {
    return status < 400 && resp.data !== undefined;
}

exports.default = WebsiteResource;

},{"../entity/Website.js":10}],12:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name BaseController
 */

var BaseController = function () {

    /**
     * @param {IntegrationResource} $Integration
     * @param {PageInfo} $PageInfo
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$translate} $translate
     */

    function BaseController($Integration, $PageInfo, $stateParams, $alert, $state, $translate) {
        var _this = this;

        _classCallCheck(this, BaseController);

        this.integration = $Integration.getEntityObject();

        var BREADCRUMB_TEXT = 'Integrations';

        $translate([BREADCRUMB_TEXT, this.getBreadcrumbText()]).then(function (translations) {

            $PageInfo.clear().add(translations[BREADCRUMB_TEXT], 'integration').add(translations[_this.getBreadcrumbText()]).broadcast();
        });

        this.stateParams = $stateParams;
        this.alert = $alert;
        this.state = $state;

        this.formTouched = false;
    }

    /**
     * Handle form submit action
     *
     * @param {Integration} Integration
     */


    _createClass(BaseController, [{
        key: 'submitForm',
        value: function submitForm(Integration) {
            var _this2 = this;

            this.integrationPromise = Integration.save(this.stateParams.id);

            this.integrationPromise.then(function (resp) {

                _this2.alert.success(_this2.getAlertType());

                if (_this2.state.includes('integrationCreate')) {

                    _this2.state.go('integrationEdit', {
                        'integrationId': resp.id
                    });
                } else {
                    _this2.state.go('integration');
                }
            }, function () {

                _this2.alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {
            this.validateIntegration = true;
            this.alert.error('error.invalid');
        }
    }]);

    return BaseController;
}();

exports.default = BaseController;

},{}],13:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name CreateController
 */

var CreateController = function (_BaseController) {
  _inherits(CreateController, _BaseController);

  /**
   * @param {IntegrationResource} $IntegrationResource
   * @param {PageInfo} $PageInfo
   * @param {$stateParams} $stateParams
   * @param {Alert} $alert
   * @param {$state} $state
   * @param {$translate} $translate
   */

  function CreateController($IntegrationResource, $PageInfo, $stateParams, $alert, $state, $translate) {
    _classCallCheck(this, CreateController);

    var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(CreateController).call(this, $IntegrationResource, $PageInfo, $stateParams, $alert, $state, $translate));

    _this.integration = $IntegrationResource.getEntityObject();
    return _this;
  }

  /**
   * Get breadcrumb text
   */


  _createClass(CreateController, [{
    key: 'getBreadcrumbText',
    value: function getBreadcrumbText() {

      return 'Add a new integration';
    }

    /**
     * Get alert type
     */

  }, {
    key: 'getAlertType',
    value: function getAlertType() {

      return 'success.create';
    }
  }]);

  return CreateController;
}(_BaseController3.default);

exports.default = CreateController;

},{"./BaseController.js":12}],14:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {IntegrationResource} $IntegrationResource
     * @param {PageInfo} $PageInfo
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$translate} $translate
     */

    function EditController($IntegrationResource, $PageInfo, $stateParams, $alert, $state, $translate) {
        _classCallCheck(this, EditController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, $IntegrationResource, $PageInfo, $stateParams, $alert, $state, $translate));

        _this.integrationPromise = $IntegrationResource.get($stateParams.integrationId);

        _this.integrationPromise.then(function (resp) {
            _this.integration = resp;
        });

        _this.formTouched = true;
        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(EditController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Edit integration';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.edit';
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":12}],15:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var $alert;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @name ManageController
 */

var ManageController = function () {

    /**
     * @param {ngTableParams} TableParams
     * @param {$stateParams} $stateParams
     * @param {IntegrationResource} Integration
     * @param {Alert} alert
     * @param {ParamConverter} paramConverter
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function ManageController(TableParams, $stateParams, Integration, alert, paramConverter, PageInfo, $translate) {
        _classCallCheck(this, ManageController);

        $alert = alert;
        stateParams = $stateParams;

        this.permissions = [];

        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                Integration.query(paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });

        var BREADCRUMB_TEXT = 'Integrations';

        $translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    }

    _createClass(ManageController, [{
        key: 'removeIntegration',
        value: function removeIntegration(Integration) {
            var _this = this;

            Integration.remove().then(function () {

                $alert.success('success.remove');

                if (_this.tableParams.data.length === 1 && _this.tableParams.page() > 1) {

                    _this.tableParams.page(_this.tableParams.page() - 1);
                } else {

                    _this.tableParams.reload();
                }
            }, function () {

                $alert.error('error.remove');

                _this.tableParams.reload();
            });
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],16:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var integrationFactory = function integrationFactory(resource) {

    /**
     * @name Integration
     * @description Integration entity
     */

    var Integration = function () {
        function Integration() {
            _classCallCheck(this, Integration);

            /**
             * @type {number}
             */
            this.id = undefined;

            /**
             * @type {string}
             */
            this.name = undefined;

            /**
             * @type {string}
             */
            this.clientId = undefined;

            /**
             * @type {string}
             */
            this.clientSecret = undefined;

            /**
             * @type {string}
             */
            this.createdAt = undefined;
            /**
             * @type {string}
             */
            this.updatedAt = undefined;
            /**
             * @type {Object}
             */
            this.user = undefined;
        }

        /**
         * @returns {deferred.promise}
         */


        _createClass(Integration, [{
            key: 'save',
            value: function save() {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'updatedAt', 'createdAt', 'clientId', 'clientSecret', 'user'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        if (property === 'type') {

                            result[property] = this[property].id;
                        } else {

                            result[property] = this[property];
                        }
                    }
                }

                if (this.id === undefined) {

                    if (this.user.email) {
                        result.user = {};
                        result.user.email = this.user.email;
                    }

                    promise = resource.post(result);
                } else {

                    promise = resource.put(this.id, result);
                }

                return promise;
            }

            /**
             * @returns {deferred.promise|Boolean}
             */

        }, {
            key: 'remove',
            value: function remove() {

                if (this.id === undefined) {

                    return false;
                }

                return resource.delete(this.id);
            }
        }]);

        return Integration;
    }();

    return Integration;
};

exports.default = integrationFactory;

},{}],17:[function(require,module,exports){
'use strict';

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _IntegrationResource = require('./resource/IntegrationResource.js');

var _IntegrationResource2 = _interopRequireDefault(_IntegrationResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.integration';
var MODULE_DIR = 'app/modules/integration';

_ManageController2.default.$inject = ['ngTableParams', '$stateParams', MODULE_NAME + '.IntegrationResource', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_CreateController2.default.$inject = [MODULE_NAME + '.IntegrationResource', 'clearcode.tm.pageInfo.pageInfo', '$stateParams', 'clearcode.tm.alert.alert', '$state', '$translate'];

_EditController2.default.$inject = [MODULE_NAME + '.IntegrationResource', 'clearcode.tm.pageInfo.pageInfo', '$stateParams', 'clearcode.tm.alert.alert', '$state', '$translate'];

_IntegrationResource2.default.$inject = ['$http', '$q'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.tagContainer']).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('integration', {
        url: '/integration',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    }).state('integrationCreate', {
        url: '/integration/create',
        controller: MODULE_NAME + '.CreateController as view',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    }).state('integrationEdit', {
        url: '/integration/{integrationId}/edit',
        controller: MODULE_NAME + '.EditController as view',
        templateUrl: MODULE_DIR + '/views/edit.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    });
}]).run(['clearcode.tm.alert.$alert', function ($alertProvider) {

    $alertProvider.addMessagePattern('integration.close', 'You are trying to close unsaved OAuth client.');
}]).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).service(MODULE_NAME + '.IntegrationResource', _IntegrationResource2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/CreateController.js":13,"./controllers/EditController.js":14,"./controllers/ManageController.js":15,"./resource/IntegrationResource.js":18}],18:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Integration = require('../entity/Integration.js');

var _Integration2 = _interopRequireDefault(_Integration);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var Integration = (0, _Integration2.default)(this);
    var integration = new Integration();

    integration.id = resp.id;
    integration.name = resp.name;
    integration.clientId = resp.client_id;
    integration.clientSecret = resp.client_secret;
    integration.createdAt = resp.created_at;
    integration.updatedAt = resp.updated_at;

    integration.user = {
        id: undefined,
        status: undefined,
        roles: [],
        email: undefined
    };

    if (resp.user) {
        integration.user.id = resp.user.id;
        integration.user.status = resp.user.status;
        integration.user.roles = resp.user.roles;

        if (resp.user.email) {
            integration.user.email = resp.user.email;
        }
    }

    return integration;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name IntegrationResource
 */

var IntegrationResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function IntegrationResource($http, $q) {
        _classCallCheck(this, IntegrationResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Integration}
     */


    _createClass(IntegrationResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Integration = (0, _Integration2.default)(this);

            return new Integration();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/integration/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/integration',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this2, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/integration/' + id
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'post',
        value: function post(entity) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/integration',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this3, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/integration/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this4, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return IntegrationResource;
}();

exports.default = IntegrationResource;

},{"../entity/Integration.js":16}],19:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {OAuthResource}
 */
var $oauthResource;

/**
 * @type {TokenStorage}
 */
var $tokenStorage;

/**
 * @type {$state}
 */
var state;

/**
 * @type {AlertForm}
 */
var $alertForm;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = '7tag Welcome';

/**
 * @name security#LoginController
 * @description Controller for login view
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var LoginController = function () {

  /**
   * @param {OAuthResource} oauthResource
   * @param {TokenStorage} tokenStorage
   * @param {$state} $state
   * @param {AlertForm} alertForm
   * @param {PageInfo} PageInfo
   *
   */

  function LoginController(oauthResource, tokenStorage, $state, alertForm, PageInfo) {
    _classCallCheck(this, LoginController);

    this.auth = {
      identity: '',
      password: ''
    };

    this.loginPromise = undefined;

    $oauthResource = oauthResource;
    $tokenStorage = tokenStorage;
    state = $state;
    $alertForm = alertForm;

    $alertForm.getStorage().clean();

    PageInfo.clear().add(BREADCRUMB_TEXT).broadcast();
  }

  /**
   * @description Submit action for login form
   *
   * @param {LoginController.auth} auth
   */


  _createClass(LoginController, [{
    key: 'submitForm',
    value: function submitForm(auth) {
      var _this = this;

      this.loading = true;

      this.loginPromise = $oauthResource.post(auth.identity, auth.password);

      this.loginPromise.then(function (resp) {

        $alertForm.getStorage().clean();
        $tokenStorage.addItem(resp);
        state.go('container');
      }, function () {

        _this.loading = false;
      });
    }
  }, {
    key: 'displayInvalidLoginMessage',
    value: function displayInvalidLoginMessage() {

      $alertForm.error('login.invalid');
    }
  }]);

  return LoginController;
}();

exports.default = LoginController;

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var deleteRequestInterceptor = function deleteRequestInterceptor($q, $rootScope, $injector) {

    var METHOD_DELETE = 'DELETE';
    var POST_DELETE_URL_SUFIX = '/remove';

    return {
        request: function request(config) {

            if (config.method.toUpperCase() === METHOD_DELETE && $rootScope.deleteHeaderNotSupported === true) {
                config.method = 'POST';
                config.url = config.url + POST_DELETE_URL_SUFIX;
            }

            return config;
        },

        responseError: function responseError(rejection) {

            var $http = $injector.get('$http');

            if (rejection.config.method.toUpperCase() === METHOD_DELETE) {

                if (rejection.status === 405) {

                    $rootScope.deleteHeaderNotSupported = true;

                    return $http.post(rejection.config.url + POST_DELETE_URL_SUFIX);
                }
            }

            return $q.reject(rejection);
        }
    };
};

exports.default = deleteRequestInterceptor;

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var DEFAULT_LANGUAGE = 'en';

var localeInterceptor = function localeInterceptor($window) {

    return {
        request: function request(config) {

            var localStorageLang = $window.localStorage['NG_TRANSLATE_LANG_KEY'];

            if (!/^https?:\/\//i.test(config.url)) {
                config.headers.Locale = localStorageLang ? localStorageLang : DEFAULT_LANGUAGE;
            }

            return config;
        }
    };
};

exports.default = localeInterceptor;

},{}],22:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var securityInterceptor = function securityInterceptor($q, tokenStorage, $injector) {

    return {
        request: function request(config) {

            if (undefined !== tokenStorage.getItem() && /(.js|.html|.css|version.json)$/.test(config.url) === false) {

                if (tokenStorage.getItem() !== undefined) {

                    config.headers.Authorization = tokenStorage.getToken();
                }
            }

            return config;
        },

        responseError: function responseError(rejection) {

            // TODO: change this to state ( problem with dependency injection )
            var $state = $injector.get('$state');

            if (rejection.status === 400) {

                if (rejection.data && (rejection.data.error === 'invalid_request' || rejection.data.error === 'invalid_grant')) {

                    tokenStorage.removeItem();
                    $state.go('signIn');
                }
            }

            if (rejection.status === 403) {

                $state.go('accessDenied');
            }

            return $q.reject(rejection);
        }
    };
};

exports.default = securityInterceptor;

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _AuthToken = require('../token/AuthToken.js');

var _AuthToken2 = _interopRequireDefault(_AuthToken);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var RESOURCE_ROUTE = '/api/oauth/v2/token';
var LOGOUT_ROUTE = '/api/users/me/logout';
var GRANT_TYPE = 'password';

var transformResponse = function transformResponse(resp, header, status) {

    if (status < 400) {

        var authToken = this.getEntity();

        authToken.accessToken = resp.access_token;
        authToken.expiresIn = resp.expires_in;
        authToken.tokenType = resp.token_type;
        authToken.scope = resp.scope;
        authToken.refreshToken = resp.refresh_token;

        return authToken;
    } else {

        return resp;
    }
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name OAuthResource
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var OAuthResource = function () {

    /**
     * @param {$http} $http
     * @param {$q} $q
     * @param {TokenStorage} tokenStorage
     * @param OAuthClientSettings
     */

    function OAuthResource($http, $q, tokenStorage, OAuthClientSettings) {
        _classCallCheck(this, OAuthResource);

        this.http = $http;
        this.q = $q;
        this.tokenStorage = tokenStorage;
        this.OAuthClientSettings = OAuthClientSettings;
    }

    /**
     * @returns {AuthToken}
     */


    _createClass(OAuthResource, [{
        key: 'getEntity',
        value: function getEntity() {
            var _this = this;

            _AuthToken2.default.prototype.save = function () {

                var deferred = _this.q.defer();

                _this.tokenStorage.add(_this);
                deferred.resolve(_this);

                return deferred.promise;
            };

            _AuthToken2.default.prototype.remove = function () {

                var deferred = _this.q.defer();

                _this.tokenStorage.remove();
                deferred.resolve(_this);

                return deferred.promise;
            };

            return new _AuthToken2.default();
        }

        /**
         * @description Send request to get oauth
         *
         * @param {string} identity of user ( email or username )
         * @param {string} password
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'post',
        value: function post(identity, password) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: RESOURCE_ROUTE,
                data: {
                    /* eslint-disable */
                    client_id: this.OAuthClientSettings.clientId,
                    client_secret: this.OAuthClientSettings.clientSecret,
                    grant_type: GRANT_TYPE,
                    username: identity,
                    password: password
                    /* eslint-enable */
                },
                ignoreAuthModule: true,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    return transformResponse.call(_this2, resp, header, status);
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get new tokens
         *
         * @param {string} token
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'refreshToken',
        value: function refreshToken(token) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: RESOURCE_ROUTE,
                data: {
                    /* eslint-disable */
                    client_id: this.OAuthClientSettings.clientId,
                    client_secret: this.OAuthClientSettings.clientSecret,
                    grant_type: 'refresh_token',
                    refresh_token: token
                    /* eslint-enable */
                },
                ignoreAuthModule: true,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (resp.error !== undefined) {

                        return resp;
                    }

                    return transformResponse.call(_this3, resp, header, status);
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to logout
         *
         * @returns {Promise}
         */

    }, {
        key: 'logout',
        value: function logout() {

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: LOGOUT_ROUTE
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return OAuthResource;
}();

exports.default = OAuthResource;

},{"../token/AuthToken.js":28}],24:[function(require,module,exports){
'use strict';

var _LoginController = require('./controller/LoginController.js');

var _LoginController2 = _interopRequireDefault(_LoginController);

var _Authorization = require('./service/Authorization.js');

var _Authorization2 = _interopRequireDefault(_Authorization);

var _Security = require('./service/Security.js');

var _Security2 = _interopRequireDefault(_Security);

var _OAuthResource = require('./resource/OAuthResource.js');

var _OAuthResource2 = _interopRequireDefault(_OAuthResource);

var _TokenStorage = require('./service/TokenStorage.js');

var _TokenStorage2 = _interopRequireDefault(_TokenStorage);

var _SecurityInterceptor = require('./interceptor/SecurityInterceptor.js');

var _SecurityInterceptor2 = _interopRequireDefault(_SecurityInterceptor);

var _LocaleInterceptor = require('./interceptor/LocaleInterceptor.js');

var _LocaleInterceptor2 = _interopRequireDefault(_LocaleInterceptor);

var _DeleteRequestInterceptor = require('./interceptor/DeleteRequestInterceptor.js');

var _DeleteRequestInterceptor2 = _interopRequireDefault(_DeleteRequestInterceptor);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.security';

_Authorization2.default.$inject = [MODULE_NAME + '.security', '$state'];

_Security2.default.$inject = ['$q', '$timeout', MODULE_NAME + '.tokenStorage', 'clearcode.tm.user.userResource', MODULE_NAME + '.oauthResource', '$translate'];

_OAuthResource2.default.$inject = ['$http', '$q', MODULE_NAME + '.tokenStorage', 'OAuthClientSettings'];

_LoginController2.default.$inject = [MODULE_NAME + '.oauthResource', MODULE_NAME + '.tokenStorage', '$state', 'clearcode.tm.form.alertForm', 'clearcode.tm.pageInfo.pageInfo'];

_SecurityInterceptor2.default.$inject = ['$q', MODULE_NAME + '.tokenStorage', '$injector'];

_TokenStorage2.default.$inject = ['$localStorage'];

_LocaleInterceptor2.default.$inject = ['$window'];

_DeleteRequestInterceptor2.default.$inject = ['$q', '$rootScope', '$injector'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'clearcode.tm.alert', 'clearcode.tm.tagContainer', 'clearcode.tm.user', 'clearcode.tm.pageInfo', 'clearcode.tm.form', 'clearcode.tm.update']).config(['$stateProvider', '$httpProvider', function ($stateProvider, $httpProvider) {

    $httpProvider.interceptors.push(MODULE_NAME + '.securityInterceptor');
    $httpProvider.interceptors.push(MODULE_NAME + '.deleteRequestInterceptor');
    $httpProvider.interceptors.push(MODULE_NAME + '.localeInterceptor');

    $stateProvider.state('signIn', {
        url: '/sign-in',
        controller: MODULE_NAME + '.LoginController',
        controllerAs: 'view',
        data: {
            roles: [],
            secure: false
        },
        templateUrl: '/app/modules/security/views/signIn.html'
    }).state('accessDenied', {
        url: '/access-denied',
        data: {
            roles: []
        },
        templateUrl: '/app/modules/security/views/accessDenied.html'
    });
}]).run(['$rootScope', MODULE_NAME + '.authorization', '$state', MODULE_NAME + '.security', 'clearcode.tm.alert.$alert', 'clearcode.tm.tagContainer.currentContainer', function ($rootScope, authorization, $state, security, $alertProvider, currentContainer) {

    $alertProvider.addMessagePattern('login.invalid', 'Login or password is incorrect. Please try again.');

    $alertProvider.addMessagePattern('request.error', 'User with passed email address does not exist.');

    $alertProvider.addMessagePattern('request.success', 'The email with the activation link has been sent successfully.');

    $alertProvider.addMessagePattern('changePassword.success', 'Password has been changed successfully.');

    $alertProvider.addMessagePattern('changePassword.error', 'Sorry, something went wrong. Try to reset your password one more time.');

    $alertProvider.addMessagePattern('changePassword.token', 'Reset password token is required.');

    $alertProvider.addMessagePattern('changePassword.notCurrentPassword', 'This value should be the users current password.');

    $alertProvider.addMessagePattern('invalid.email', 'Your email address is invalid.');

    $alertProvider.addMessagePattern('password.different', 'Repeat password does not match.');

    $alertProvider.addMessagePattern('password.both', 'Both password fields requires at least 8 characters.');

    $alertProvider.addMessagePattern('password.first', 'First password field requires at least 8 characters.');

    $alertProvider.addMessagePattern('password.second', 'Second password field requires at least 8 characters.');

    $rootScope.logout = function () {

        security.logout().then(function () {

            currentContainer.disableDebugMode();
            $state.go('signIn');
        });
    };

    $rootScope.deleteHeaderNotSupported = false;

    $rootScope.security = security;

    $rootScope.$on('$stateChangeStart', function (event, toState) {

        var isDataDefined = toState.data !== undefined,
            isSecure,
            roles;

        if (isDataDefined) {

            isSecure = toState.data.secure !== undefined ? toState.data.secure : true;
            roles = toState.data.roles !== undefined ? toState.data.roles : [];
        }

        authorization.authorize(roles).then(function () {}, function () {

            if (isSecure) {

                $state.go('signIn');
            }
        });
    });
}]).value('$localStorage', localStorage).controller(MODULE_NAME + '.LoginController', _LoginController2.default).service(MODULE_NAME + '.authorization', _Authorization2.default).service(MODULE_NAME + '.security', _Security2.default).service(MODULE_NAME + '.oauthResource', _OAuthResource2.default).service(MODULE_NAME + '.tokenStorage', _TokenStorage2.default).value('OAuthClientSettings', {
    "clientId": typeof OAUTH_CLIENT_ID === "undefined" ? '1_1234567890' : OAUTH_CLIENT_ID,
    "clientSecret": typeof OAUTH_CLIENT_SECRET === "undefined" ? 'sec1234567890' : OAUTH_CLIENT_SECRET
}).factory(MODULE_NAME + '.securityInterceptor', _SecurityInterceptor2.default).factory(MODULE_NAME + '.localeInterceptor', _LocaleInterceptor2.default).factory(MODULE_NAME + '.deleteRequestInterceptor', _DeleteRequestInterceptor2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controller/LoginController.js":19,"./interceptor/DeleteRequestInterceptor.js":20,"./interceptor/LocaleInterceptor.js":21,"./interceptor/SecurityInterceptor.js":22,"./resource/OAuthResource.js":23,"./service/Authorization.js":25,"./service/Security.js":26,"./service/TokenStorage.js":27}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name security#Authorization
 * @description
 * Service for authorization
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var Authorization = function () {

    /**
     * @param {Security} security
     */

    function Authorization(security, $state) {
        _classCallCheck(this, Authorization);

        this.security = security;
        this.state = $state;
    }

    /**
     * @description Check if user have permission
     *
     * @param {Array} roles
     * @returns {User}
     */


    _createClass(Authorization, [{
        key: 'authorize',
        value: function authorize(roles) {

            var self = this;

            return this.security.getUser().then(function () {

                var isAuthenticated = self.security.isAuthenticated();

                if (roles && roles.length > 0 && !self.security.hasAnyRole(roles)) {

                    if (!isAuthenticated) {

                        self.state.go('signIn');
                    }
                }
            });
        }
    }]);

    return Authorization;
}();

exports.default = Authorization;

},{}],26:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name Security
 * @description
 * Store information about current user access token
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var Security = function () {

    /**
     * @param {$q} $q
     * @param {$timeout} $timeout
     * @param {TokenStorage} tokenStorage
     * @param {UserResource} userResource
     * @param {OAuthResource} oauthResource
     * @param {translate} $translate
     */

    function Security($q, $timeout, tokenStorage, userResource, oauthResource, $translate) {
        _classCallCheck(this, Security);

        this.q = $q;
        this.timeout = $timeout;
        this.user = undefined;
        this.authenticated = false;
        this.tokenStorage = tokenStorage;
        this.userResource = userResource;
        this.oauthResource = oauthResource;
        this.translate = $translate;
        this.checked = false;
    }

    /**
     * @returns {boolean}
     */


    _createClass(Security, [{
        key: 'hasUser',
        value: function hasUser() {

            return this.user !== undefined;
        }

        /**
         * @description Determines if user is logged in
         *
         * @return {boolean}
         */

    }, {
        key: 'isAuthenticated',
        value: function isAuthenticated() {

            return this.authenticated;
        }

        /**
         * Returns true when security is checked
         *
         * @returns {Boolean}
         */

    }, {
        key: 'isChecked',
        value: function isChecked() {

            return this.checked;
        }

        /**
         * @description Determines if user has provided role
         *
         * @param {string} roleString
         * @returns {boolean}
         */

    }, {
        key: 'hasRole',
        value: function hasRole(roleString) {

            if (!this.authenticated || roleString === undefined) {

                return false;
            }

            return this.user.roles.indexOf(roleString) !== -1 || this.isSuperAdmin();
        }
    }, {
        key: 'isSuperAdmin',
        value: function isSuperAdmin() {

            return this.user.roles.indexOf('ROLE_SUPER_ADMIN') !== -1;
        }

        /**
         * @description Determines if user has at least one of provide roles
         *
         * @param {array} roles
         */

    }, {
        key: 'hasAnyRole',
        value: function hasAnyRole(roles) {

            if (!this.authenticated || roles === undefined) {

                return false;
            }

            for (var i = 0; i < roles.length; i++) {

                if (this.hasRole(roles[i])) {

                    return true;
                }
            }

            return false;
        }

        /**
         * @description Authenticate user
         *
         * @param {User} user
         */

    }, {
        key: 'authenticate',
        value: function authenticate(user) {

            this.user = user;
            this.authenticated = true;

            this.translate.use(user.language);
        }

        /**
         * Send request to logout from oauth
         * and remove access token
         *
         * @returns {Promise}
         */

    }, {
        key: 'logout',
        value: function logout() {

            var deferred = this.q.defer();

            var self = this;

            this.oauthResource.logout().then(function () {

                self.user = undefined;
                self.authenticated = false;
                self.tokenStorage.removeItem();

                deferred.resolve();
            }, function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Get current user
         *
         * @param {boolean} force
         * @returns {deferred.promise}
         */

    }, {
        key: 'getUser',
        value: function getUser(force) {

            var self = this;

            var deferred = this.q.defer();

            // Force reload identity from server if necessary
            if (force === true) {

                this.reloadUser();
            }

            // Check if we retrieved the user identity from server. If we have return immediately
            if (this.user !== undefined) {

                deferred.resolve(this.user);
                self.authenticate(this.user);

                return deferred.promise;
            }

            if (undefined !== self.tokenStorage.getItem()) {

                if (!this.isAuthenticated()) {

                    this.reloadUser();
                }
            } else {

                self.checked = true;

                deferred.reject();
            }

            return deferred.promise;
        }
    }, {
        key: 'reloadUser',
        value: function reloadUser() {

            var self = this;
            var deferred = this.q.defer();

            this.userResource.getMe().then(function (resp) {

                self.checked = true;

                self.authenticate(resp);
                deferred.resolve(self.user);
            }, function () {

                self.checked = true;
            });
        }
    }]);

    return Security;
}();

exports.default = Security;

},{}],27:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var localStorage = undefined;

/**
 * @name TokenStorage
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var TokenStorage = function () {
    function TokenStorage($localStorage) {
        _classCallCheck(this, TokenStorage);

        localStorage = $localStorage;
    }

    /**
     * @description get token from localStorage
     *
     * @returns {AuthToken|boolean}
     */


    _createClass(TokenStorage, [{
        key: 'getItem',
        value: function getItem() {

            var accessToken = localStorage.accessToken;

            if (undefined !== accessToken) {

                accessToken = JSON.parse(accessToken);
            }

            return accessToken;
        }
    }, {
        key: 'getToken',
        value: function getToken() {

            var authToken = this.getItem();

            return TokenStorage.capitalizeFirstLetter(authToken.tokenType) + ' ' + authToken.accessToken;
        }
    }, {
        key: 'addItem',


        /**
         * @description save token in localStorage
         *
         * @param {AuthToken} authToken
         *
         * @returns {AuthToken}
         */
        value: function addItem(authToken) {

            localStorage.accessToken = JSON.stringify(authToken);

            return authToken;
        }

        /**
         * @description remove token from localStorage
         */

    }, {
        key: 'removeItem',
        value: function removeItem() {

            localStorage.removeItem('accessToken');
        }
    }], [{
        key: 'capitalizeFirstLetter',
        value: function capitalizeFirstLetter(string) {

            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }]);

    return TokenStorage;
}();

exports.default = TokenStorage;

},{}],28:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name AuthToken
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var AuthToken = function AuthToken() {
  _classCallCheck(this, AuthToken);

  /**
   * @type {string}
   */
  this.accessToken = undefined;

  /**
   * @type {number}
   */
  this.expiresIn = undefined;

  /**
   * @type {string}
   */
  this.tokenType = undefined;

  /**
   * @type {string}
   */
  this.scope = undefined;

  /**
   * @type {string}
   */
  this.refreshToken = undefined;
};

exports.default = AuthToken;

},{}],29:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name BaseController
 */

var BaseController = function () {

    /**
     * @param {TagResource} $tagResource
     * @param {TriggerResource} $triggerResource
     * @param {VariableResource} $variableResource
     * @param {$state} $state
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$scope} $scope
     * @param {ngTableParams} $TableParams
     * @param {paramConverter} $paramConverter
     * @param {currentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {Templates} templatesStorage
     * @param {$translate} $translate
     */

    function BaseController($tagResource, $triggerResource, $variableResource, $state, $stateParams, $alert, $scope, $TableParams, $paramConverter, CurrentContainer, PageInfo, templatesStorage, $translate) {
        var _this = this;

        _classCallCheck(this, BaseController);

        var BREADCRUMB_GLOBAL = 'Tags';

        this.alert = $alert;
        this.state = $state;
        this.stateParams = $stateParams;
        this.triggerResource = $triggerResource;
        this.TableParams = $TableParams;
        this.paramConverter = $paramConverter;
        this.currentContainer = CurrentContainer;

        this.tag = $tagResource.getEntityObject();

        this.tagPromise = undefined;

        this.triggers = [];
        this.chosenTriggers = [];
        this.triggerPromise = undefined;
        this.formTouched = false;
        this.templatesStorage = templatesStorage;
        this.templates = templatesStorage.getAll();
        this.template = undefined;
        this.translate = $translate;
        this.variables = [];

        this.textEditorConfig = {};
        this.isCodeEditorFocused = false;

        $variableResource.queryAllAvailable(this.stateParams.containerId).then(function (resp) {

            _this.variables = resp.data;
        });

        this.textEditorConfig.onLoad = function (cm) {

            cm.options.variables = _this.variables;

            $scope.insertVariableIntoCode = function (variable) {

                cm.doc.replaceSelection('{{ ' + variable.name + ' }}');

                cm.focus();
            };

            cm.on('focus', function () {

                _this.isCodeEditorFocused = true;
            });

            cm.on('blur', function () {

                _this.isCodeEditorFocused = false;
            });
        };

        $scope.$on('trigger.add', function (event, resp) {

            _this.tag.addTrigger(resp);
            _this.showTriggerForm = false;
            _this.validateTriggers = false;
        });

        $scope.$on('trigger.edit', function (event, resp) {

            _this.tag.editTrigger(resp);
            _this.editTriggerForm = false;
            _this.validateTriggers = false;
        });

        this.translate(['Place the code']).then(function (translations) {

            _this.textEditorConfig.placeholder = translations['Place the code'];
        });

        this.currentContainer.getContainer().then(function () {

            if (_this.currentContainer.$container.hasPermission('noaccess')) {

                $state.go('container');
            }

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                PageInfo.clear().add(_this.currentContainer.$container.name, 'tags', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[BREADCRUMB_GLOBAL], 'tags', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });

        $scope.$on('pageInfo.reload', function (event, args) {

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                PageInfo.clear().add(args.name, 'tags', {
                    containerId: args.id
                }).add(translations[BREADCRUMB_GLOBAL], 'tags', {
                    containerId: args.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });
    }

    _createClass(BaseController, [{
        key: 'hasVariables',
        value: function hasVariables() {

            return angular.isArray(this.variables) && this.variables.length > 0;
        }

        /**
         *
         * @param {string} id
         */

    }, {
        key: 'setTagTemplate',
        value: function setTagTemplate(id) {

            if (this.tag.template === id) {

                this.template = undefined;
                this.tag.template = null;
                this.formTouched = false;

                return false;
            }

            this.tag.template = id;

            if (this.tag.template) {

                this.template = this.templatesStorage.get(id);
                this.tag.code = undefined;

                if (this.template.types.length === 0) {

                    this.setTemplateType(id);
                } else {

                    this.formTouched = false;
                }
            } else {

                this.formTouched = true;
            }
        }

        /**
         * @param {string} id
         * @param {object} options
         */

    }, {
        key: 'initTagTemplate',
        value: function initTagTemplate(id, options) {

            this.tag.template = id;

            if (this.tag.template) {

                this.template = this.templatesStorage.get(id);
                this.tag.code = undefined;

                if (this.template.types.length === 0) {

                    this.templateFields = this.templatesStorage.getOptions(id);
                    this.template.templateUrl = this.templatesStorage.getTemplateUrl(id);
                    this.tag.templateOptions = options;
                } else {

                    this.templateFields = this.templatesStorage.getOptions(id, options.type);
                    this.tag.templateOptions.type = options.type;
                    this.template.templateUrl = this.templatesStorage.getTemplateUrl(id, options.type);
                }
            }

            this.formTouched = true;
        }
    }, {
        key: 'setTemplateType',
        value: function setTemplateType(id, type) {

            if (this.tag.templateOptions.type !== undefined && this.tag.templateOptions.type === type) {

                return false;
            }

            this.templateFields = this.templatesStorage.getOptions(id, type);

            this.setOptions();
            this.tag.templateOptions.type = type;
            this.template.templateUrl = this.templatesStorage.getTemplateUrl(id, type);

            this.formTouched = true;
        }
    }, {
        key: 'setOptions',
        value: function setOptions() {

            var fieldsLength = this.templateFields.length;

            this.tag.templateOptions = {};

            while (fieldsLength--) {

                this.tag.templateOptions[this.templateFields[fieldsLength].name] = this.templateFields[fieldsLength].hasOwnProperty('value') ? this.templateFields[fieldsLength].value : undefined;
            }
        }

        /**
         * @desc Show existing triggers
         */

    }, {
        key: 'showExistingTriggers',
        value: function showExistingTriggers() {

            this.chosenTriggers = [];
            this.prepareExistingTriggersList();
            this.showListTriggersForm = true;
        }

        /**
         * @desc Prepare existing triggers list
         */

    }, {
        key: 'prepareExistingTriggersList',
        value: function prepareExistingTriggersList() {
            var _this2 = this;

            this.triggers = [];

            this.types = ['Page View', 'Click', 'Event', 'Form submission'];

            this.existingTable = new this.TableParams({
                page: 1,
                count: 10,
                sorting: {
                    name: 'asc'
                }
            }, {
                total: 0,
                getData: function getData($defer, params) {

                    var parameters = _this2.paramConverter.list(params.url());

                    parameters['exclude[]'] = _this2.tag.triggers.map(function (trigger) {
                        return trigger.id;
                    });

                    _this2.triggerResource.query(_this2.currentContainer.$container.id, parameters).then(function (resp) {

                        params.total(resp.total);
                        $defer.resolve(resp.data);
                    });
                }
            });
        }

        /**
         * @desc Function provides adding existing triggers
         */

    }, {
        key: 'addExistingTriggers',
        value: function addExistingTriggers() {

            var chosenLength = this.chosenTriggers.length;

            while (chosenLength--) {

                this.tag.triggers.push(this.chosenTriggers[chosenLength]);
            }
        }
    }, {
        key: 'submitForm',
        value: function submitForm(tag) {
            var _this3 = this;

            this.tagPromise = tag.save(this.stateParams.containerId);

            this.tagPromise.then(function () {

                _this3.alert.success(_this3.getAlertType(_this3.stateParams.tagId));

                _this3.currentContainer.makeChanges();

                _this3.state.go('tags', { containerId: _this3.stateParams.containerId });
            }, function () {

                _this3.alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.validateTag = true;
            this.alert.error('error.invalid');
        }
    }]);

    return BaseController;
}();

exports.default = BaseController;

},{}],30:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name CreateController
 */

var CreateController = function (_BaseController) {
  _inherits(CreateController, _BaseController);

  /**
   * @param {TagResource} $tagResource
   * @param {TriggerResource} $triggerResource
   * @param {VariableResource} $variableResource
   * @param {$state} $state
   * @param {$stateParams} $stateParams
   * @param {Alert} $alert
   * @param {$scope} $scope
   * @param {ngTableParams} $TableParams
   * @param {paramConverter} $paramConverter
   * @param {currentContainer} CurrentContainer
   * @param {PageInfo} PageInfo
   * @param {Templates} TemplatesStorage
   * @param {$translate} $translate
   */

  function CreateController($tagResource, $triggerResource, $variableResource, $state, $stateParams, $alert, $scope, $TableParams, $paramConverter, CurrentContainer, PageInfo, TemplatesStorage, $translate) {
    _classCallCheck(this, CreateController);

    return _possibleConstructorReturn(this, Object.getPrototypeOf(CreateController).call(this, $tagResource, $triggerResource, $variableResource, $state, $stateParams, $alert, $scope, $TableParams, $paramConverter, CurrentContainer, PageInfo, TemplatesStorage, $translate));
  }

  /**
   * Get breadcrumb text
   *
   */


  _createClass(CreateController, [{
    key: 'getBreadcrumbText',
    value: function getBreadcrumbText() {

      return 'Add a new tag';
    }

    /**
     * Get alert type
     *
     */

  }, {
    key: 'getAlertType',
    value: function getAlertType() {

      return 'success.create';
    }
  }]);

  return CreateController;
}(_BaseController3.default);

exports.default = CreateController;

},{"./BaseController.js":29}],31:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {TagResource} $tagResource
     * @param {TriggerResource} $triggerResource
     * @param {VariableResource} $variableResource
     * @param {$state} $state
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$scope} $scope
     * @param {ngTableParams} $TableParams
     * @param {paramConverter} $paramConverter
     * @param {currentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {Templates} templatesStorage
     * @param {$translate} $translate
     */

    function EditController($tagResource, $triggerResource, $variableResource, $state, $stateParams, $alert, $scope, $TableParams, $paramConverter, CurrentContainer, PageInfo, templatesStorage, $translate) {
        _classCallCheck(this, EditController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, $tagResource, $triggerResource, $variableResource, $state, $stateParams, $alert, $scope, $TableParams, $paramConverter, CurrentContainer, PageInfo, templatesStorage, $translate));

        _this.tagPromise = $tagResource.get($stateParams.tagId);

        _this.tagPromise.then(function (resp) {

            _this.tag = resp;

            _this.initTagTemplate(_this.tag.template, _this.tag.templateOptions);
        });

        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(EditController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Configure tag';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.edit';
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":29}],32:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var $alert;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {$scope}
 */
var scope;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Tags';

/**
 * @name ManageController
 */

var ManageController = function () {

    /**
     * @param {ngTableParams} TableParams
     * @param {$stateParams} $stateParams
     * @param {TagResource} tagResource
     * @param {Alert} alert
     * @param {ParamConverter} paramConverter
     * @param {CurrentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {$scope} $scope
     * @param {$state} state
     * @param {$translate} $translate
     */

    function ManageController(TableParams, $stateParams, tagResource, alert, paramConverter, CurrentContainer, PageInfo, $scope, state, $translate) {
        var _this = this;

        _classCallCheck(this, ManageController);

        $alert = alert;
        stateParams = $stateParams;
        scope = $scope;
        this.permissions = [];

        this.containerId = stateParams.containerId;
        this.translate = $translate;
        this.currentContainer = CurrentContainer;

        this.currentContainer.getContainer().then(function (container) {

            if (!_this.currentContainer.$container.hasPermission('view')) {

                state.go('container');
            } else {

                _this.permissions = _this.currentContainer.$container.permissions;
            }

            _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(container.name, 'tags', {
                    containerId: container.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });

        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            $scope: $scope, // important
            getData: function getData($defer, params) {

                tagResource.query(stateParams.containerId, paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });

        this.tableParams.reload();

        scope.params = this.tableParams;

        // behaviour after page change
        $scope.$on('ngTableAfterReloadData', function () {

            _this.showTriggers = -1;
            _this.showMenu = -1;
        }, true);

        $scope.$watch('params.$params', function (newParams, oldParams) {

            if (newParams === oldParams) {
                return;
            }

            $scope.params.settings().$scope = $scope;

            $scope.params.reload();
        }, true);

        scope.$on('pageInfo.reload', function (event, args) {

            _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(args.name, 'tags', {
                    containerId: args.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });
    }

    _createClass(ManageController, [{
        key: 'removeTag',
        value: function removeTag(tag) {
            var _this2 = this;

            tag.remove().then(function () {

                $alert.success('success.remove');
                _this2.currentContainer.makeChanges();

                if (_this2.tableParams.data.length === 1 && _this2.tableParams.page() > 1) {

                    _this2.tableParams.page(_this2.tableParams.page() - 1);
                } else {

                    _this2.tableParams.reload();
                }
            }, function () {

                $alert.error('error.remove');

                _this2.tableParams.reload();
            });
        }
    }, {
        key: 'changeStatus',
        value: function changeStatus(tag) {
            var _this3 = this;

            tag.changeStatus(this.containerId).then(function () {
                _this3.currentContainer.makeChanges();
                $alert.success('status.change');
            }, function () {
                $alert.error('status.failed');
            });
        }
    }, {
        key: 'toggleActionMenu',
        value: function toggleActionMenu($index) {

            if (this.permissions.indexOf('edit') !== -1) {

                this.showMenu = this.showMenu === $index ? -1 : $index;
                this.showTriggers = -1;
            }
        }
    }, {
        key: 'toggleTriggerList',
        value: function toggleTriggerList($index) {

            this.showMenu = -1;
            this.showTriggers = this.isRowExpanded($index) ? -1 : $index;
        }
    }, {
        key: 'isRowExpanded',
        value: function isRowExpanded($index) {

            return this.showTriggers === $index;
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],33:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var tagFactory = function tagFactory(resource) {

    /**
     * @name Variable
     * @description Variable entity
     */

    var Tag = function () {
        function Tag() {
            _classCallCheck(this, Tag);

            /**
             * @type {number}
             */
            this.id = undefined;

            /**
             * @type {string}
             */
            this.name = undefined;

            /**
             * @type {string}
             */
            this.code = undefined;

            /**
             * @type {string}
             */
            this.noScriptUri = undefined;

            /**
             * @type {Array}
             */
            this.triggers = [];

            /**
             * @type {string}
             */
            this.updatedAt = undefined;

            /**
             * @type {number}
             */
            this.priority = 0;

            /**
             * @type {boolean}
             */
            this.documentWrite = false;

            /**
             * @type {boolean}
             */
            this.disableInDebugMode = false;

            /**
             * @type {boolean}
             */
            this.respectVisitorsPrivacy = false;

            /**
             * @type {boolean}
             */
            this.isActive = true;

            /**
             * @type {string}
             */
            this.template = null;

            /**
             * @type {Object}
             */
            this.templateOptions = {};

            /**
             * @type {boolean}
             */
            this.statusChangePending = false;
        }

        /**
         * @param {Trigger} trigger
         */


        _createClass(Tag, [{
            key: 'addTrigger',
            value: function addTrigger(trigger) {

                this.triggers.push(trigger);
            }

            /**
             * @param {Trigger} trigger
             */

        }, {
            key: 'editTrigger',
            value: function editTrigger(trigger) {

                var index = this.triggers.length;

                while (index--) {

                    if (this.triggers[index].id === trigger.id) {

                        this.triggers[index] = trigger;
                        return true;
                    }
                }

                return false;
            }

            /**
             * @param {Trigger} trigger
             */

        }, {
            key: 'removeTrigger',
            value: function removeTrigger(trigger) {

                var index = this.triggers.indexOf(trigger);

                if (index > -1) {

                    this.triggers.splice(index, 1);

                    return true;
                }

                return false;
            }

            /**
             * @param {Number} id
             */

        }, {
            key: 'removeTriggerById',
            value: function removeTriggerById(id) {

                var index = this.triggers.length;

                while (index--) {

                    if (this.triggers[index].id === id) {

                        this.triggers.splice(index, 1);
                        return true;
                    }
                }

                return false;
            }

            /**
             * @param {number} containerId
             *
             * @returns {deferred.promise}
             */

        }, {
            key: 'save',
            value: function save(containerId) {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'updatedAt', 'statusChangePending'];

                if (this.template === undefined) {

                    excludedProperty.push('templateOptions');
                } else {

                    excludedProperty.push('code');
                    excludedProperty.push('noScriptUri');
                    excludedProperty.push('documentWrite');
                }

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        if (property === 'triggers') {

                            var collection = [];

                            for (var index in this[property]) {

                                if (this[property][index] instanceof Object) {

                                    collection.push(this[property][index].id);
                                }
                            }

                            result[property] = collection;
                        } else {

                            result[property] = this[property];
                        }
                    }
                }

                if (this.id === undefined) {

                    promise = resource.post(containerId, result);
                } else {

                    promise = resource.put(this.id, result);
                }

                return promise;
            }

            /**
             * @returns {deferred.promise|Boolean}
             */

        }, {
            key: 'remove',
            value: function remove() {

                if (this.id === undefined) {

                    return false;
                }

                return resource.delete(this.id);
            }

            /**
             * @param containerId
             * @returns {deferred.promise}
             */

        }, {
            key: 'changeStatus',
            value: function changeStatus(containerId) {
                var _this = this;

                var tag = angular.copy(this);

                tag.isActive = !tag.isActive;

                this.statusChangePending = true;

                var promise = tag.save(containerId).then(function () {

                    _this.isActive = !_this.isActive;
                }).finally(function () {

                    _this.statusChangePending = false;
                });

                return promise;
            }
        }]);

        return Tag;
    }();

    return Tag;
};

exports.default = tagFactory;

},{}],34:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _TemplateType = require('../entity/TemplateType.js');

var _TemplateType2 = _interopRequireDefault(_TemplateType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var templateFactory = function templateFactory() {

  var TemplateType = (0, _TemplateType2.default)();

  var Template = function () {

    /**
     * @constructor
     * @param {string} id
     * @param {string} name
     */

    function Template(id, name) {
      _classCallCheck(this, Template);

      /**
       * @type {string}
       */
      this.id = id;

      /**
       * @type {string}
       */
      this.name = name;

      /**
       * @type {string}
       */
      this.brandUrl = undefined;

      /**
       * @type {string}
       */
      this.templateUrl = undefined;

      /**
       * @type {string}
       */
      this.type = undefined;

      /**
       * @type {Array}
       */
      this.types = [];

      /**
       * @type {object}
       */
      this.fields = [];
    }

    /**
     * @param {object} params
     * @returns {Template}
     */


    _createClass(Template, [{
      key: 'addTextField',
      value: function addTextField(params) {

        var field = params ? params : {};

        field.type = 'text';

        this.fields.push(field);

        return this;
      }

      /**
       * @param {object} params
       * @returns {Template}
       */

    }, {
      key: 'addHiddenField',
      value: function addHiddenField(name, value) {

        var field = {};

        field.name = name;
        field.value = value;
        field.type = 'hidden';

        this.fields.push(field);

        return this;
      }

      /**
       * @param {object} params
       * @returns {Template}
       */

    }, {
      key: 'addSelectField',
      value: function addSelectField(params) {

        var field = params ? params : {};

        field.type = 'select';

        this.fields.push(field);

        return this;
      }

      /**
       * @param {string} id
       * @param {string} name
       * @returns {Template}
       */

    }, {
      key: 'addType',
      value: function addType(id, name) {

        var type = new TemplateType(id, name);

        this.types.push(type);

        return type;
      }

      /**
       * @param {string} url
       * @returns {Template}
       */

    }, {
      key: 'addBrand',
      value: function addBrand(url) {

        this.brandUrl = url;

        return this;
      }

      /**
       * @param {string} url
       * @returns {Template}
       */

    }, {
      key: 'addTemplateUrl',
      value: function addTemplateUrl(url) {

        this.templateUrl = url;

        return this;
      }
    }]);

    return Template;
  }();

  return Template;
};

exports.default = templateFactory;

},{"../entity/TemplateType.js":35}],35:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */
var templateTypeFactory = function templateTypeFactory() {
  var TemplateType = function () {

    /**
     * @constructor
     * @param {string} id
     * @param {string} name
     */

    function TemplateType(id, name) {
      _classCallCheck(this, TemplateType);

      /**
       * @type {string}
       */
      this.id = id;

      /**
       * @type {string}
       */
      this.name = name;

      /**
       * @type {string}
       */
      this.templateUrl = undefined;

      /**
       * @type {object}
       */
      this.fields = [];
    }

    /**
     * @param {object} params
     * @returns {TemplateType}
     */


    _createClass(TemplateType, [{
      key: 'addTextField',
      value: function addTextField(params) {

        var field = params ? params : {};

        field.type = 'text';

        this.fields.push(field);

        return this;
      }

      /**
       * @param {string} name
       * @param {string} value
       * @returns {TemplateType}
       */

    }, {
      key: 'addHiddenField',
      value: function addHiddenField(name, value) {

        var field = {};

        field.name = name;
        field.value = value;
        field.type = 'hidden';

        this.fields.push(field);

        return this;
      }

      /**
       * @param {string} url
       * @returns {TemplateType}
       */

    }, {
      key: 'addTemplateUrl',
      value: function addTemplateUrl(url) {

        this.templateUrl = url;

        return this;
      }
    }]);

    return TemplateType;
  }();

  return TemplateType;
};

exports.default = templateTypeFactory;

},{}],36:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Template = require('../entity/Template.js');

var _Template2 = _interopRequireDefault(_Template);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Template = (0, _Template2.default)();

/**
 * @name TemplatesProvider
 */

var TemplatesProvider = function () {
    function TemplatesProvider() {
        _classCallCheck(this, TemplatesProvider);

        /**
         * @type {Array} collection
         */
        this.collection = [];
    }

    /**
     * @param {string} id
     * @param {string} name
     * @returns {Template}
     */


    _createClass(TemplatesProvider, [{
        key: 'add',
        value: function add(id, name) {

            var template = new Template(id, name);

            this.collection.push(template);

            return template;
        }

        /**
         * @param {string} id
         * @returns {Template|boolean}
         */

    }, {
        key: 'get',
        value: function get(id) {

            var templateLength = this.collection.length;

            while (templateLength--) {

                if (this.collection[templateLength].id === id) {

                    return this.collection[templateLength];
                }
            }

            return false;
        }

        /**
         * @returns {array}
         */

    }, {
        key: 'getAll',
        value: function getAll() {

            return this.collection;
        }
    }, {
        key: '$get',
        value: function $get() {

            return this;
        }
    }]);

    return TemplatesProvider;
}();

exports.default = TemplatesProvider;

},{"../entity/Template.js":34}],37:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Tag = require('../entity/Tag.js');

var _Tag2 = _interopRequireDefault(_Tag);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var Tag = (0, _Tag2.default)(this);

    var tag = new Tag();

    tag.id = resp.id;
    tag.name = resp.name;
    tag.code = resp.code;
    tag.noScriptUri = resp.no_script_uri;
    tag.priority = resp.priority;
    tag.updatedAt = resp.updated_at;
    tag.documentWrite = resp.document_write;
    tag.disableInDebugMode = resp.disable_in_debug_mode;
    tag.isActive = resp.is_active;
    tag.respectVisitorsPrivacy = resp.respect_visitors_privacy;

    if (resp.template !== null) {

        tag.template = resp.template;
        tag.templateOptions = resp.template_options;
    } else {

        tag.template = undefined;
    }

    for (var key in resp.triggers) {

        if (resp.triggers[key] instanceof Object) {

            tag.addTrigger(resp.triggers[key]);
        }
    }

    return tag;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name TagResource
 */

var TagResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function TagResource($http, $q) {
        _classCallCheck(this, TagResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Tag}
     */


    _createClass(TagResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Tag = (0, _Tag2.default)(this);

            return new Tag();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/tags/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entities
         *
         * @param {number} containerId
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(containerId, params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + containerId + '/tags',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this2, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/tags/' + id
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {number} containerId
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'post',
        value: function post(containerId, entity) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers/' + containerId + '/tags',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this3, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/tags/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this4, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return TagResource;
}();

exports.default = TagResource;

},{"../entity/Tag.js":33}],38:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var findByKey = function findByKey(collection, key, value) {

    var collectionLength = collection.length;

    while (collectionLength--) {

        if (collection[collectionLength][key] === value) {

            return collection[collectionLength];
        }
    }

    return false;
};

/**
 * @name Templates
 */

var Templates = function () {

    /**
     * @constructor
     * @param {TemplateProvider} $templates
     */

    function Templates($templates) {
        _classCallCheck(this, Templates);

        this.collection = $templates.getAll();
    }

    _createClass(Templates, [{
        key: 'getAll',
        value: function getAll() {

            return this.collection;
        }
    }, {
        key: 'get',
        value: function get(id) {

            var collectionLength = this.collection.length;

            while (collectionLength--) {

                if (this.collection[collectionLength].id === id) {

                    return this.collection[collectionLength];
                }
            }

            return false;
        }
    }, {
        key: 'getOptions',
        value: function getOptions(id, typeId) {

            var template = findByKey(this.collection, 'id', id);

            if (template) {

                if (typeId) {

                    var type = findByKey(template.types, 'id', typeId);

                    return type && type.hasOwnProperty('fields') ? type.fields : false;
                }

                return template.hasOwnProperty('fields') ? template.fields : false;
            }

            return false;
        }
    }, {
        key: 'getTemplateUrl',
        value: function getTemplateUrl(id, typeId) {

            var template = findByKey(this.collection, 'id', id);

            if (typeId) {

                var type = findByKey(template.types, 'id', typeId);

                return type ? type.templateUrl : false;
            }

            return template.templateUrl;
        }
    }]);

    return Templates;
}();

exports.default = Templates;

},{}],39:[function(require,module,exports){
'use strict';

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _TagResource = require('./resource/TagResource.js');

var _TagResource2 = _interopRequireDefault(_TagResource);

var _Templates = require('./services/Templates.js');

var _Templates2 = _interopRequireDefault(_Templates);

var _TemplatesProvider = require('./providers/TemplatesProvider.js');

var _TemplatesProvider2 = _interopRequireDefault(_TemplatesProvider);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.tag';
var MODULE_TRIGGER = 'clearcode.tm.trigger';
var MODULE_VARIABLE = 'clearcode.tm.variable';
var MODULE_DIR = 'app/modules/tag';

_Templates2.default.$inject = [MODULE_NAME + '.$template'];

_TagResource2.default.$inject = ['$http', '$q'];

_ManageController2.default.$inject = ['ngTableParams', '$stateParams', MODULE_NAME + '.tagResource', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$state', '$translate'];

_CreateController2.default.$inject = [MODULE_NAME + '.tagResource', MODULE_TRIGGER + '.triggerResource', MODULE_VARIABLE + '.variableResource', '$state', '$stateParams', 'clearcode.tm.alert.alert', '$scope', 'ngTableParams', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', MODULE_NAME + '.templatesStorage', '$translate'];

_EditController2.default.$inject = [MODULE_NAME + '.tagResource', MODULE_TRIGGER + '.triggerResource', MODULE_VARIABLE + '.variableResource', '$state', '$stateParams', 'clearcode.tm.alert.alert', '$scope', 'ngTableParams', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', MODULE_NAME + '.templatesStorage', '$translate'];

module.exports = { MODULE_NAME: MODULE_NAME };
angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.tagContainer', 'clearcode.tm.trigger', 'ui.codemirror']).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('tags', {
        url: '/containers/{containerId}/tags',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('tagCreate', {
        url: '/containers/{containerId}/tag-create',
        controller: MODULE_NAME + '.CreateController as view',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('tagEdit', {
        url: '/containers/{containerId}/tag/edit/{tagId}',
        controller: MODULE_NAME + '.EditController as view',
        templateUrl: MODULE_DIR + '/views/edit.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    });
}]).run(['clearcode.tm.alert.$alert', function ($alertProvider) {

    $alertProvider.addMessagePattern('trigger.close', 'You are trying to close unsaved trigger.');

    $alertProvider.addMessagePattern('status.change', 'Tag status was changed successfully');

    $alertProvider.addMessagePattern('status.failed', 'Sorry, something went wrong.');
}]).provider(MODULE_NAME + '.$template', _TemplatesProvider2.default).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).service(MODULE_NAME + '.tagResource', _TagResource2.default).service(MODULE_NAME + '.templatesStorage', _Templates2.default);

},{"./controllers/CreateController.js":30,"./controllers/EditController.js":31,"./controllers/ManageController.js":32,"./providers/TemplatesProvider.js":36,"./resource/TagResource.js":37,"./services/Templates.js":38}],40:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$state}
 */
var state;

/**
 * @type {Alert}
 */
var $alert;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Add a container';

/**
 * @name CreateController
 */

var CreateController = function () {

    /**
     * @param {ContainerResource} containerResource
     * @param {$state} $state
     * @param {Alert} alert
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function CreateController(containerResource, $state, alert, PageInfo, $translate) {
        _classCallCheck(this, CreateController);

        this.container = containerResource.getEntityObject();

        state = $state;

        $alert = alert;

        this.translate = $translate;

        this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    }

    _createClass(CreateController, [{
        key: 'submitForm',
        value: function submitForm(container) {

            this.containerPromise = container.save();

            this.containerPromise.then(function (resp) {

                $alert.success('container.create');

                state.go('containerExplanation', { containerId: resp.id });
            }, function () {

                $alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.validateContainer = true;
            $alert.error('error.invalid');
        }
    }]);

    return CreateController;
}();

exports.default = CreateController;

},{}],41:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$state}
 */
var state;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {CurrentContainer}
 */
var currentContainer;

/**
 * @type {$scope}
 */
var scope;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Options';

/**
 * @name EditController
 */

var EditController = function () {

    /**
     * @param {ContainerResource} containerResource
     * @param {$state} $state
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {currentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {$scope} $scope
     * @param {$translate} $translate
     */

    function EditController(containerResource, $state, $stateParams, $alert, CurrentContainer, PageInfo, $scope, $translate) {
        var _this = this;

        _classCallCheck(this, EditController);

        var self = this;

        state = $state;
        stateParams = $stateParams;
        alert = $alert;
        currentContainer = CurrentContainer;
        scope = $scope;

        this.pageInfo = PageInfo;

        this.container = containerResource.getEntityObject();

        this.containerPromise = containerResource.get(stateParams.containerId);

        this.containerPromise.then(function (resp) {

            self.container = resp;
        });

        this.translate = $translate;

        currentContainer.getContainer().then(function () {

            if (currentContainer.$container.hasPermission('noaccess')) {

                state.go('container');
            }

            _this.refreshBreadcrumb(BREADCRUMB_TEXT, currentContainer.$container);
        });

        scope.$on('pageInfo.reload', function (event, container) {

            _this.refreshBreadcrumb(BREADCRUMB_TEXT, container);
        });
    }

    _createClass(EditController, [{
        key: 'removeContainer',
        value: function removeContainer() {

            this.container.remove().then(function () {

                alert.success('success.remove');

                state.go('container');
            }, function () {

                alert.error('error.remove');
            });
        }
    }, {
        key: 'submitForm',
        value: function submitForm(container) {
            var _this2 = this;

            this.containerPromise = container.save();

            this.containerPromise.then(function (container) {

                _this2.refreshBreadcrumb(BREADCRUMB_TEXT, container);

                currentContainer.get(stateParams.containerId);
                alert.success('success.edit');

                state.go('containerEdit', { containerId: stateParams.containerId });
            }, function () {

                alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.validateContainer = true;
            alert.error('error.invalid');
        }
    }, {
        key: 'refreshBreadcrumb',
        value: function refreshBreadcrumb(breadcrumbText, container) {

            var pageInfo = this.pageInfo;

            this.translate([breadcrumbText]).then(function (translations) {

                pageInfo.clear().add(container.name, 'tags', {
                    containerId: container.id
                }).add(translations[breadcrumbText]).broadcast();
            });
        }
    }]);

    return EditController;
}();

exports.default = EditController;

},{}],42:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {String}
 * @readOnly
 */
var PAGE_TITLE = 'Container created successfully';

/**
 * @name ExplanationController
 */

var ExplanationController =

/**
 * @param {ContainerResource} containerResource
 * @param {$stateParams} $stateParams
 * @param {PageInfo} PageInfo
 * @param {CurrentContainer} CurrentContainer
 * @param {$translate} $translate
 */
function ExplanationController(containerResource, $stateParams, PageInfo, CurrentContainer, $translate) {
    var _this = this;

    _classCallCheck(this, ExplanationController);

    CurrentContainer.makeChanges();

    this.translate = $translate;

    this.translate([PAGE_TITLE]).then(function (translations) {

        PageInfo.clear().add(translations[PAGE_TITLE]).broadcast();
    });

    stateParams = $stateParams;
    this.container = containerResource.getEntityObject();

    this.containerPromise = containerResource.get(stateParams.containerId);

    this.containerPromise.then(function (resp) {

        _this.container = resp;
    });
};

exports.default = ExplanationController;

},{}],43:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var $alert;

/**
 * @name ManageController
 */

var ManageController = function () {

    /**
     * @param {ngTableParams} TableParams
     * @param {ContainerResource} containerResource
     * @param {Alert} alert
     * @param paramConverter
     * @param currentContainer
     */

    function ManageController(TableParams, containerResource, alert, paramConverter, currentContainer) {
        _classCallCheck(this, ManageController);

        $alert = alert;

        currentContainer.disableDebugMode();

        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                containerResource.query(paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });
    }

    _createClass(ManageController, [{
        key: 'removeContainer',
        value: function removeContainer(container) {

            var self = this;

            container.remove().then(function () {

                $alert.success('success.remove');

                if (self.tableParams.data.length === 1) {

                    self.tableParams.page(self.tableParams.page() - 1);
                }

                self.tableParams.reload();
            }, function () {

                $alert.error('error.remove');

                self.tableParams.reload();
            });
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],44:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var permissionsResource;

var currentContainer;

var alert;

/**
 * @name PermissionsController
 */

var PermissionsController = function () {

    /**
     * @param TableParams
     * @param paramConverter
     * @param $currentContainer
     * @param $alert
     * @param $permissionsResource
     */

    function PermissionsController(TableParams, paramConverter, $currentContainer, $alert, $permissionsResource) {
        _classCallCheck(this, PermissionsController);

        this.permissionsPromise = undefined;
        permissionsResource = $permissionsResource;
        currentContainer = $currentContainer;
        alert = $alert;

        this.usersTable = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                permissionsResource.query(currentContainer.$container.id, paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);
                    $defer.resolve(resp.data);
                });
            }
        });
    }

    _createClass(PermissionsController, [{
        key: 'setPermission',
        value: function setPermission(permission, value) {

            var tempRequest = angular.copy(permission);

            tempRequest.permissions = value;

            this.permissionsPromise = tempRequest.save(currentContainer.$container.id);

            this.permissionsPromise.then(function (resp) {

                permission.permissions = resp.permissions;
            }, function () {

                alert.error('permissions.error');
            });
        }
    }, {
        key: 'isValid',
        value: function isValid(permission, value) {

            if (permission.permissions === value || permission.user.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {

                return false;
            }

            return true;
        }
    }]);

    return PermissionsController;
}();

exports.default = PermissionsController;

},{}],45:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name PrivacyController
 */

var PrivacyController = function PrivacyController(stateParams, containerResource) {
    var _this = this;

    _classCallCheck(this, PrivacyController);

    this.privacyPromise = containerResource.getPrivacyOptOut(stateParams.containerId).then(function (resp) {
        _this.code = resp.code;
    });
};

exports.default = PrivacyController;

},{}],46:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var containerFactory = function containerFactory(resource) {

  /**
   * @name Container
   * @description Container entity
   */

  var Container = function () {
    function Container() {
      _classCallCheck(this, Container);

      /**
       * @type {number}
       */
      this.id = undefined;

      /**
       * @type {string}
       */
      this.name = undefined;

      /**
       * @type {string}
       */
      this.code = undefined;

      /**
       * @type {Object}
       */
      this.version = undefined;

      /**
       * @type {boolean}
       */
      this.hasUnpublishedChanges = undefined;

      /**
       * @type {string}
       */
      this.publishedAt = undefined;

      /**
       * @type {Array}
       */
      this.permissions = undefined;

      /**
       * @type {number}
       */
      this.delay = undefined;

      /**
       * @type {Array}
       */
      this.websites = undefined;
    }

    /**
     * @returns {deferred.promise}
     */


    _createClass(Container, [{
      key: 'save',
      value: function save() {

        var result = {},
            promise;

        var excludedProperty = ['id', 'code', 'version', 'hasUnpublishedChanges', 'publishedAt', 'permissions', 'websites'];

        for (var property in this) {

          if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

            result[property] = this[property];
          }
        }

        if (this.id === undefined) {

          delete result.delay;
          promise = resource.post(result);
        } else {

          promise = resource.put(this.id, result);
        }

        return promise;
      }

      /**
       * @returns {deferred.promise|Boolean}
       */

    }, {
      key: 'remove',
      value: function remove() {

        if (this.id === undefined) {

          return false;
        }

        return resource.delete(this.id);
      }

      /**
       * @param {string}
       * @returns {Boolean}
       */

    }, {
      key: 'hasPermission',
      value: function hasPermission(permission) {

        return this.permissions.indexOf(permission) !== -1;
      }

      /**
       * Get permission
       */

    }, {
      key: 'getPermission',
      value: function getPermission() {

        var permissionsPrioritiedArray = [{
          value: 'operator',
          text: 'Owner'
        }, {
          value: 'publish',
          text: 'Publish'
        }, {
          value: 'edit',
          text: 'Edit'
        }, {
          value: 'view',
          text: 'View'
        }];

        for (var permissionIndex in permissionsPrioritiedArray) {

          if (this.permissions.indexOf(permissionsPrioritiedArray[permissionIndex].value) !== -1) {

            return permissionsPrioritiedArray[permissionIndex].text;
          }
        }
      }
    }]);

    return Container;
  }();

  return Container;
};

exports.default = containerFactory;

},{}],47:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var permissionFactory = function permissionFactory(resource) {

  /**
   * @name Permission
   * @description Permission entity
   */

  var Permission = function () {
    function Permission() {
      _classCallCheck(this, Permission);

      /**
       * @type {Object}
       */
      this.user = undefined;

      /**
       * @type {Array}
       */
      this.permissions = undefined;
    }

    /**
     * @returns {deferred.promise}
     */


    _createClass(Permission, [{
      key: "save",
      value: function save(containerId) {

        var result = {},
            promise;

        result = angular.copy(this);

        result.user = result.user.id;

        promise = resource.put(containerId, result);

        return promise;
      }
    }]);

    return Permission;
  }();

  return Permission;
};

exports.default = permissionFactory;

},{}],48:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Container = require('../entity/Container.js');

var _Container2 = _interopRequireDefault(_Container);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var Container = (0, _Container2.default)(this);

    var container = new Container();

    if (resp !== undefined) {

        container.id = resp.id;
        container.name = resp.name;
        container.description = resp.description;
        container.code = resp.code;
        container.version = resp.version;
        container.hasUnpublishedChanges = resp.has_unpublished_changes;
        container.publishedAt = resp.published_at;
        container.permissions = resp.permissions;
        container.delay = resp.delay;
        container.websites = [];

        for (var i = 0; i < resp.websites.length; i++) {

            var website = this.WebsiteResource.getEntityObject();

            website.url = resp.websites[i].url;
            website.parameterType = resp.websites[i].parameter_type;

            container.websites.push(website);
        }
    }

    return container;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name ContainerResource
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var ContainerResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function ContainerResource($http, $q, WebsiteResource) {
        _classCallCheck(this, ContainerResource);

        this.http = $http;
        this.q = $q;
        this.WebsiteResource = WebsiteResource;
    }

    /**
     * @returns {Container}
     */


    _createClass(ContainerResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Container = (0, _Container2.default)(this);

            return new Container();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    return transformResponse.call(_this, resp.data);
                })
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function () {

                deferred.reject();
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this2, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/containers/' + id
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {object} entity
         */

    }, {
        key: 'post',
        value: function post(entity) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    return transformResponse.call(_this3, resp.data);
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/containers/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    return transformResponse.call(_this4, resp.data);
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }, {
        key: 'publishVersion',
        value: function publishVersion(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers/' + id + '/version-publish'
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }, {
        key: 'restoreVersion',
        value: function restoreVersion(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers/' + id + '/version-restore'
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }, {
        key: 'getPrivacyOptOut',
        value: function getPrivacyOptOut(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: 'containers/' + id + '/privacy'
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return ContainerResource;
}();

exports.default = ContainerResource;

},{"../entity/Container.js":46}],49:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Permission = require('../entity/Permission.js');

var _Permission2 = _interopRequireDefault(_Permission);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var permissionList = ['operator', 'publish', 'edit', 'view', 'noaccess'];

    var Permission = (0, _Permission2.default)(this);

    var permission = new Permission();

    permission.user = resp.user;

    if (resp.permissions instanceof Object) {

        for (var permissionIndex in permissionList) {

            if (resp.permissions.indexOf(permissionList[permissionIndex]) !== -1) {

                permission.permissions = permissionList[permissionIndex];
                break;
            }
        }
    } else {

        permission.permissions = resp.permissions;
    }

    return permission;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name PermissionResource
 *
 */

var PermissionResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function PermissionResource($http, $q) {
        _classCallCheck(this, PermissionResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Permission}
     */


    _createClass(PermissionResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Permission = (0, _Permission2.default)(this);

            return new Permission();
        }
    }, {
        key: 'query',
        value: function query(id, params) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + id + '/permissions',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                data: entity,
                url: 'api/containers/' + id + '/permissions',
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this2, resp.data);
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return PermissionResource;
}();

exports.default = PermissionResource;

},{"../entity/Permission.js":47}],50:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {ContainerResource}
 */
var $containerResource;

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {$state}
 */
var state;

/**
 * @type {$rootScope}
 */
var rootScope;

/**
 * @type {$cookiesProvider}
 */
var cookies;

/**
 * @readonly
 * @type {String}
 */
var PREVIEW_KEY = 'preview';

/**
 * @readonly
 * @type {String}
 */
var DEBUG_KEY = 'debug';

/**
 * @type {ConditionResource}
 */
var conditionResource;

/**
 * Convert params for request
 *
 * @name CurrentContainer
 */

var CurrentContainer = function () {
    /**
     * @param {$stateParams} $stateParams
     * @param {ContainerResource} containerResource
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$rootScope} $rootScope
     * @param {$cookiesProvider} $cookies
     * @param {ConditionResource} $conditionResource
     * @param {$translate} $translate
     */

    function CurrentContainer($stateParams, containerResource, $alert, $state, $rootScope, $cookies, $conditionResource, $translate) {
        _classCallCheck(this, CurrentContainer);

        stateParams = $stateParams;
        $containerResource = containerResource;
        alert = $alert;
        state = $state;
        rootScope = $rootScope;
        this.$container = undefined;
        cookies = $cookies;

        this.types = undefined;
        this.debugMode = false;
        this.previewedContainer = undefined;
        this.translate = $translate;
        conditionResource = $conditionResource;
    }

    /**
     * @param {number} containerId
     *
     * @returns {deferred.promise} promise of get current container
     */


    _createClass(CurrentContainer, [{
        key: 'get',
        value: function get(containerId) {
            var _this = this;

            containerId = this.getId(containerId);
            rootScope.currentContainerLoading = true;

            this.containerPromise = $containerResource.get(containerId).then(function (resp) {

                _this.$container = resp;
                rootScope.currentContainerLoading = false;

                _this.verifyDebugMode();

                if (_this.types === undefined) {

                    _this.types = _this.setTypes();
                }

                return _this.$container;
            });

            return this.containerPromise;
        }

        /**
         * Get id of current container
         *
         * @param {number} containerId
         * @returns {number}
         */

    }, {
        key: 'getId',
        value: function getId(containerId) {

            return containerId !== undefined ? containerId : stateParams.containerId;
        }

        /**
         * Get container inside promise
         */

    }, {
        key: 'getContainer',
        value: function getContainer() {

            if (this.containerPromise === undefined) {

                return this.get();
            } else {

                return this.containerPromise;
            }
        }

        /**
         * Publish current container
         */

    }, {
        key: 'publish',
        value: function publish() {

            var self = this,
                publishPromise = $containerResource.publishVersion(this.getId());

            rootScope.currentContainerLoading = true;

            return publishPromise.then(function () {

                alert.success('success.publish');
                self.get();
                rootScope.currentContainerLoading = false;
            }, function () {

                alert.error('container.error');
                rootScope.currentContainerLoading = false;
            });
        }

        /**
         * Check restore ability
         *
         * @returns {boolean}
         */

    }, {
        key: 'canRestore',
        value: function canRestore() {

            var container = this.$container;

            if (container) {

                return container.publishedAt && container.hasUnpublishedChanges === true;
            }

            return false;
        }

        /**
         * Check if the container has unpublished changes
         *
         * @returns {boolean}
         */

    }, {
        key: 'isDirty',
        value: function isDirty() {

            var container = this.$container;

            return container && container.hasUnpublishedChanges;
        }

        /**
         * Check publish status
         *
         * @returns {boolean}
         */

    }, {
        key: 'isPublished',
        value: function isPublished() {

            var container = this.$container;

            if (container) {

                return container.publishedAt && container.hasUnpublishedChanges === false;
            }

            return false;
        }

        /**
         * Set Container unpublished changes to true
         *
         * @returns {boolean}
         */

    }, {
        key: 'makeChanges',
        value: function makeChanges() {

            var container = this.$container;

            if (container) {

                container.hasUnpublishedChanges = true;
                return true;
            }
        }

        /**
         * Dischard draft changes to latest published version
         */

    }, {
        key: 'restore',
        value: function restore() {

            var self = this;

            var restorePromise = $containerResource.restoreVersion(this.getId());

            rootScope.currentContainerLoading = true;

            restorePromise.then(function () {

                alert.success('success.restore');

                self.get();

                if (state.includes('tags') || state.includes('tagEdit') || state.includes('tagCreate')) {

                    state.go('tags', { containerId: self.getId() }, { reload: true });
                } else if (state.includes('triggers') || state.includes('triggerCreate') || state.includes('triggerEdit')) {

                    state.go('triggers', { containerId: self.getId() }, { reload: true });
                } else {

                    state.go('containerEdit', { containerId: self.getId() }, { reload: true });
                }

                rootScope.currentContainerLoading = false;
            }, function () {

                alert.error('container.error');
            });
        }
    }, {
        key: 'verifyDebugMode',
        value: function verifyDebugMode() {

            if (this.isDebugModeEnabled()) {

                var cookiesDebug = cookies.get(DEBUG_KEY);

                if (cookiesDebug === this.$container.id) {

                    this.debugMode = true;
                    this.previewedContainer = this.$container;
                    var self = this;

                    this.translate(['Now Previewing']).then(function (translations) {

                        self.previewedContainerText = translations['Now Previewing'];
                    });
                } else {

                    this.disableDebugMode();
                }
            }
        }
    }, {
        key: 'isDebugModeEnabled',
        value: function isDebugModeEnabled() {

            var cookiesDebug = cookies.get(DEBUG_KEY);
            var cookiesPreview = cookies.get(PREVIEW_KEY);

            return cookiesDebug !== undefined && cookiesPreview !== undefined && cookiesDebug === cookiesPreview;
        }

        /**
         * Set debug mode
         */

    }, {
        key: 'enableDebugMode',
        value: function enableDebugMode() {

            this.debugMode = true;
            this.previewedContainer = this.$container;
            var self = this;

            this.translate(['Now Previewing']).then(function (translations) {

                self.previewedContainerText = translations['Now Previewing'];
            });

            cookies.put(PREVIEW_KEY, this.$container.id);
            cookies.put(DEBUG_KEY, this.$container.id);
        }

        /**
         * Leave debug mode
         */

    }, {
        key: 'disableDebugMode',
        value: function disableDebugMode() {

            this.debugMode = false;
            this.previewedContainer = undefined;

            cookies.remove(PREVIEW_KEY);
            cookies.remove(DEBUG_KEY);
        }
    }, {
        key: 'setTypes',
        value: function setTypes() {

            return conditionResource.query(this.getId()).then(function (resp) {

                var respIndex = resp.length,
                    mapped = [];

                while (respIndex--) {

                    mapped[respIndex] = {
                        name: resp[respIndex].name,
                        type: resp[respIndex].type
                    };
                }

                return mapped;
            });
        }
    }, {
        key: 'hasPermission',
        value: function hasPermission(permission) {

            return this.getContainer().then(function (container) {

                return container.hasPermission(permission);
            });
        }
    }]);

    return CurrentContainer;
}();

exports.default = CurrentContainer;

},{}],51:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * Convert params for request
 *
 * @name ParamConverter
 */

var ParamConverter = function () {
  function ParamConverter() {
    _classCallCheck(this, ParamConverter);
  }

  _createClass(ParamConverter, [{
    key: "list",

    /**
     * Convert list resource parameters
     *
     * @param {Object} params
     * @returns {{limit: (number), offset: number}}
     */
    value: function list(params) {

      return {
        limit: params.count,
        offset: (params.page - 1) * params.count
      };
    }
  }]);

  return ParamConverter;
}();

exports.default = ParamConverter;

},{}],52:[function(require,module,exports){
'use strict';

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _ExplanationController = require('./controllers/ExplanationController.js');

var _ExplanationController2 = _interopRequireDefault(_ExplanationController);

var _PermissionsController = require('./controllers/PermissionsController.js');

var _PermissionsController2 = _interopRequireDefault(_PermissionsController);

var _PrivacyController = require('./controllers/PrivacyController.js');

var _PrivacyController2 = _interopRequireDefault(_PrivacyController);

var _ContainerResource = require('./resource/ContainerResource.js');

var _ContainerResource2 = _interopRequireDefault(_ContainerResource);

var _PermissionResource = require('./resource/PermissionResource.js');

var _PermissionResource2 = _interopRequireDefault(_PermissionResource);

var _ParamConverter = require('./service/ParamConverter.js');

var _ParamConverter2 = _interopRequireDefault(_ParamConverter);

var _CurrentContainer = require('./service/CurrentContainer.js');

var _CurrentContainer2 = _interopRequireDefault(_CurrentContainer);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.tagContainer';
var DEBUG_MODULE_NAME = 'clearcode.tm.debug';
var MODULE_DIR = 'app/modules/tagContainer';

_ContainerResource2.default.$inject = ['$http', '$q', DEBUG_MODULE_NAME + '.websiteResource'];

_PermissionResource2.default.$inject = ['$http', '$q'];

_CurrentContainer2.default.$inject = ['$stateParams', MODULE_NAME + '.containerResource', 'clearcode.tm.alert.alert', '$state', '$rootScope', '$cookieStore', 'clearcode.tm.condition.conditionResource', '$translate'];

_ManageController2.default.$inject = ['ngTableParams', MODULE_NAME + '.containerResource', 'clearcode.tm.alert.alert', MODULE_NAME + '.paramConverter', 'clearcode.tm.tagContainer.currentContainer'];

_CreateController2.default.$inject = [MODULE_NAME + '.containerResource', '$state', 'clearcode.tm.alert.alert', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_EditController2.default.$inject = [MODULE_NAME + '.containerResource', '$state', '$stateParams', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$translate'];

_ExplanationController2.default.$inject = [MODULE_NAME + '.containerResource', '$stateParams', 'clearcode.tm.pageInfo.pageInfo', 'clearcode.tm.tagContainer.currentContainer', '$translate'];

_PermissionsController2.default.$inject = ['ngTableParams', MODULE_NAME + '.paramConverter', MODULE_NAME + '.currentContainer', 'clearcode.tm.alert.alert', MODULE_NAME + '.permissionResource'];

_PrivacyController2.default.$inject = ['$stateParams', MODULE_NAME + '.containerResource'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'ngCookies', 'clearcode.tm.condition', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.user', DEBUG_MODULE_NAME]).run(['$rootScope', MODULE_NAME + '.currentContainer', '$stateParams', 'clearcode.tm.alert.$alert', function ($rootScope, currentContainer, $stateParams, $alertProvider) {

    $rootScope.currentContainerLoading = true;
    $rootScope.currentContainer = currentContainer;

    var loadContainer = function loadContainer(containerId) {

        if (containerId !== $stateParams.containerId) {

            var currentContainerPromise = currentContainer.get(containerId);

            currentContainerPromise.then(function (container) {

                $rootScope.$broadcast('pageInfo.reload', container);
            });
        }
    };

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

        loadContainer(toParams.containerId);
    });

    $alertProvider.addMessagePattern('success.restore', 'Well done! Action completed successfully.');

    $alertProvider.addMessagePattern('container.create', 'Container has been created successfully.');

    $alertProvider.addMessagePattern('container.error', 'Sorry, something went wrong.');

    $alertProvider.addMessagePattern('success.publish', 'Container has been published! Changes are now live!');

    $alertProvider.addMessagePattern('permissions.error', 'Permissions can not be changed.');
}]).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('container', {
        url: '/containers',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false,
            permission: ['view']
        }
    }).state('containerCreate', {
        url: '/containers-create',
        controller: MODULE_NAME + '.CreateController as view',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false,
            permission: ['edit']
        }
    }).state('containerExplanation', {
        url: '/containers-explanation/{containerId}',
        controller: MODULE_NAME + '.ExplanationController as view',
        templateUrl: MODULE_DIR + '/views/explanation.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true,
            permission: ['owner']
        }
    }).state('containerEdit', {
        url: '/containers-edit/{containerId}',
        controller: MODULE_NAME + '.EditController as view',
        templateUrl: MODULE_DIR + '/views/options.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true,
            permission: ['edit']
        }
    });
}]).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).controller(MODULE_NAME + '.ExplanationController', _ExplanationController2.default).controller(MODULE_NAME + '.PermissionsController', _PermissionsController2.default).controller(MODULE_NAME + '.PrivacyController', _PrivacyController2.default).service(MODULE_NAME + '.containerResource', _ContainerResource2.default).service(MODULE_NAME + '.permissionResource', _PermissionResource2.default).service(MODULE_NAME + '.paramConverter', _ParamConverter2.default).service(MODULE_NAME + '.currentContainer', _CurrentContainer2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/CreateController.js":40,"./controllers/EditController.js":41,"./controllers/ExplanationController.js":42,"./controllers/ManageController.js":43,"./controllers/PermissionsController.js":44,"./controllers/PrivacyController.js":45,"./resource/ContainerResource.js":48,"./resource/PermissionResource.js":49,"./service/CurrentContainer.js":50,"./service/ParamConverter.js":51}],53:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name BaseController
 */

var BaseController = function () {
    /**
     * @param {$scope} $scope
     * @param $triggerResource
     * @param $conditionResource
     * @param {$stateParams} $stateParams
     * @param {$state} $state
     * @param {Alert} $alert
     * @param {CurrentContainer} CurrentContainer
     * @param {Condition} $condition
     * @param {PageInfo} $PageInfo
     * @param {$translate} $translate
     */

    function BaseController($scope, $triggerResource, $conditionResource, $stateParams, $state, $alert, CurrentContainer, $condition, $PageInfo, $translate) {
        var _this = this;

        _classCallCheck(this, BaseController);

        var BREADCRUMB_GLOBAL = 'Triggers';

        this.required = undefined;
        this.variables = undefined;
        this.actions = undefined;

        this.triggerPromise = undefined;

        this.triggerResource = $triggerResource;
        this.conditionResource = $conditionResource;

        this.scope = $scope;
        this.stateParams = $stateParams;
        this.state = $state;
        this.alert = $alert;
        this.currentContainer = CurrentContainer;
        this.Condition = $condition;
        this.PageInfo = $PageInfo;

        this.typesArrayPromise = $condition.getArrayOfTypes();

        this.trigger = $triggerResource.getEntityObject();
        this.condition = $conditionResource.getEntityObject();
        this.translate = $translate;

        $scope.$on('pageInfo.reload', function (event, args) {

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                $PageInfo.clear().add(args.name, 'tags', {
                    containerId: args.id
                }).add(translations[BREADCRUMB_GLOBAL], 'triggers', {
                    containerId: args.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });
    }

    /**
     * Set type of trigger
     *
     * @param {Number} type
     */


    _createClass(BaseController, [{
        key: 'setType',
        value: function setType(type) {
            var _this2 = this;

            if (this.typesArray[type] !== undefined) {

                this.trigger.type = type;
                this.trigger.conditions = [];

                this.Condition.getVariables(type).then(function (resp) {

                    _this2.variables = resp;
                });
                this.Condition.getActions(type).then(function (resp) {

                    _this2.actions = resp;
                });
                this.Condition.getRequired(type).then(function (resp) {

                    _this2.required = resp;
                    var requiredIndex = _this2.required.length;

                    while (requiredIndex--) {

                        _this2.trigger.conditions.push({
                            variable: _this2.required[requiredIndex].name,
                            condition: _this2.actions[0].type,
                            value: ''
                        });
                    }
                });
            } else {

                throw new Error('Invalid type');
            }
        }

        /**
         * Method to create new trigger condition
         */

    }, {
        key: 'addCondition',
        value: function addCondition() {

            this.condition = this.conditionResource.getEntityObject();

            this.condition.variable = this.variables[0].name;
            this.condition.condition = this.actions[0].type;

            this.trigger.addCondition(this.condition);
        }

        /**
         * Method to validate trigger
         *
         * @param {Object} form
         * @returns {Boolean}
         */

    }, {
        key: 'validateTrigger',
        value: function validateTrigger(form) {

            this.scope[form.$name].$setPristine();

            var conditionLength = this.trigger.conditions.length;

            if (this.scope[form.$name].name.$valid) {

                if (conditionLength !== 0) {

                    while (conditionLength--) {

                        if (this.scope[form.$name]['conditionValue[' + conditionLength + ']'].$invalid) {

                            return false;
                        }
                    }
                }

                return true;
            } else {

                return false;
            }
        }

        /**
         * Check if variable is required
         * @param {Object} variable
         */

    }, {
        key: 'isTypeRequired',
        value: function isTypeRequired(variable) {

            return this.required !== undefined && this.required.map(function (element) {
                return element.name;
            }).indexOf(variable) !== -1;
        }

        /**
         * @param  {object} variable
         * @return {string}
         */

    }, {
        key: 'getVariableName',
        value: function getVariableName(variable) {

            var variablesIndex = this.variables.map(function (element) {
                return element.name;
            }).indexOf(variable);

            if (variablesIndex === -1) {

                variablesIndex = this.required.map(function (element) {
                    return element.name;
                }).indexOf(variable);

                return variablesIndex !== -1 ? this.required[variablesIndex].name : false;
            } else {

                return this.variables[variablesIndex].name;
            }
        }

        /**
         * @param  {string} conditionName
         * @return {string}
         */

    }, {
        key: 'getConditionName',
        value: function getConditionName(conditionName) {

            var conditionsIndex = this.actions.map(function (element) {
                return element.name;
            }).indexOf(conditionName);

            return conditionsIndex !== -1 ? this.actions[conditionsIndex].name : false;
        }

        /**
         * Function for refreshing breadcrumb and closing add trigger panel
         * @return {Boolean}
         */

    }, {
        key: 'leaveFormInsideTag',
        value: function leaveFormInsideTag() {
            var _this3 = this;

            this.translate([this.getBreadcrumbText()]).then(function (translations) {

                _this3.PageInfo.clear().add(_this3.currentContainer.$container.name, 'tags', {
                    containerId: _this3.currentContainer.$container.id
                }).add(translations[_this3.getBreadcrumbText()]).broadcast();
            });

            return false;
        }

        /**
         * Handle form submit action
         *
         * @param {Trigger} trigger
         */

    }, {
        key: 'submitForm',
        value: function submitForm(trigger) {
            var _this4 = this;

            this.triggerPromise = trigger.save(this.stateParams.containerId);

            this.triggerPromise.then(function () {

                _this4.currentContainer.makeChanges();
                _this4.alert.success(_this4.getAlertType());

                _this4.state.go('triggers', {
                    containerId: _this4.stateParams.containerId
                });
            }, function () {

                _this4.alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage(view) {

            view.validateTriggers = true;
            this.alert.error('error.invalid');
        }
    }]);

    return BaseController;
}();

exports.default = BaseController;

},{}],54:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

var BREADCRUMB_GLOBAL = 'Triggers';

/**
 * @name CreateController
 */

var CreateController = function (_BaseController) {
    _inherits(CreateController, _BaseController);

    /**
     * @param {$scope} $scope
     * @param $triggerResource
     * @param $conditionResource
     * @param {$stateParams} $stateParams
     * @param {$state} $state
     * @param {Alert} $alert
     * @param {CurrentContainer} CurrentContainer
     * @param {Condition} $condition
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function CreateController($scope, $triggerResource, $conditionResource, $stateParams, $state, $alert, CurrentContainer, $condition, PageInfo, $translate) {
        _classCallCheck(this, CreateController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(CreateController).call(this, $scope, $triggerResource, $conditionResource, $stateParams, $state, $alert, CurrentContainer, $condition, PageInfo, $translate));

        _this.typesArrayPromise.then(function (resp) {

            _this.typesArray = resp;
            _this.setType(0);
        });

        CurrentContainer.getContainer().then(function () {

            if (!CurrentContainer.$container.hasPermission('edit')) {

                $state.go('triggers', { containerId: $stateParams.containerId });
            }

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                PageInfo.clear().add(CurrentContainer.$container.name, 'tags', {
                    containerId: CurrentContainer.$container.id
                }).add(translations[BREADCRUMB_GLOBAL], 'triggers', {
                    containerId: CurrentContainer.$container.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });

        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(CreateController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Add a new trigger';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.create';
        }

        /**
         * Add trigger into specific tag
         *
         * @param {Object} form
         * @param {Trigger} trigger
         */

    }, {
        key: 'addTrigger',
        value: function addTrigger(form, trigger) {
            var _this2 = this;

            this.triggerPromise = trigger.save(this.stateParams.containerId);

            this.triggerPromise.then(function (resp) {

                _this2.scope[form.$name].$setPristine();
                _this2.trigger = _this2.triggerResource.getEntityObject();
                _this2.scope.$emit('trigger.add', resp);
            }, function () {
                // TODO: handle wrong response
            });
        }
    }]);

    return CreateController;
}(_BaseController3.default);

exports.default = CreateController;

},{"./BaseController.js":53}],55:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

var BREADCRUMB_GLOBAL = 'Triggers';

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {$scope} $scope
     * @param $triggerResource
     * @param $conditionResource
     * @param {$stateParams} $stateParams
     * @param {$state} $state
     * @param $alert
     * @param {CurrentContainer} CurrentContainer
     * @param {$condition} $condition
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function EditController($scope, $triggerResource, $conditionResource, $stateParams, $state, $alert, CurrentContainer, $condition, PageInfo, $translate) {
        _classCallCheck(this, EditController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, $scope, $triggerResource, $conditionResource, $stateParams, $state, $alert, CurrentContainer, $condition, PageInfo, $translate));

        _this.triggerPromise = $triggerResource.get($stateParams.triggerId);

        _this.triggerPromise.then(function (triggerResp) {

            _this.trigger = triggerResp;

            _this.typesArrayPromise.then(function (typesResp) {

                _this.typesArray = typesResp;

                _this.Condition.getVariables(_this.trigger.type).then(function (resp) {

                    _this.variables = resp;
                });
                _this.Condition.getActions(_this.trigger.type).then(function (resp) {

                    _this.actions = resp;
                });
                _this.Condition.getRequired(_this.trigger.type).then(function (resp) {

                    _this.required = resp;
                });
            });
        });

        _this.currentContainer.getContainer().then(function () {

            if (_this.currentContainer.$container.hasPermission('noaccess')) {

                _this.state.go('container');
            }

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                PageInfo.clear().add(_this.currentContainer.$container.name, 'tags', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[BREADCRUMB_GLOBAL], 'triggers', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });

        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(EditController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Configure trigger';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.edit';
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":53}],56:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {$scope} $scope
     * @param {TriggerResource} $triggerResource
     * @param {ConditionResource} $conditionResource
     * @param {$stateParams} $stateParams
     * @param {}$alert} Alert
     * @param {CurrentContainer} CurrentContainer
     * @param {$condition} $condition
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function EditController($scope, $triggerResource, $conditionResource, $stateParams, $alert, CurrentContainer, $condition, PageInfo, $translate) {
        _classCallCheck(this, EditController);

        return _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, $scope, $triggerResource, $conditionResource, $stateParams, null, $alert, CurrentContainer, $condition, PageInfo, $translate));
    }

    /**
     * @desc Init trigger
     * @param {number} triggerId
     */


    _createClass(EditController, [{
        key: 'initTrigger',
        value: function initTrigger(triggerId) {
            var _this2 = this;

            this.triggerPromise = this.triggerResource.get(triggerId);

            this.triggerPromise.then(function (triggerResp) {

                _this2.trigger = triggerResp;

                _this2.typesArrayPromise.then(function (typesResp) {

                    _this2.typesArray = typesResp;

                    _this2.Condition.getVariables(_this2.trigger.type).then(function (resp) {

                        _this2.variables = resp;
                    });

                    _this2.Condition.getActions(_this2.trigger.type).then(function (resp) {

                        _this2.actions = resp;
                    });

                    _this2.Condition.getRequired(_this2.trigger.type).then(function (resp) {

                        _this2.required = resp;
                    });
                });
            });
        }

        /**
         * Get breadcrumb text
         *
         */

    }, {
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Configure trigger';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.edit';
        }

        /**
         * Handle form submit action
         * @param {Trigger} trigger
         */

    }, {
        key: 'submitForm',
        value: function submitForm(trigger) {
            var _this3 = this;

            this.triggerPromise = trigger.save(this.stateParams.containerId);

            this.triggerPromise.then(function (resp) {

                _this3.currentContainer.makeChanges();
                _this3.alert.success('trigger.edit');
                _this3.scope.$emit('trigger.edit', resp);
            }, function () {

                _this3.alert.error('error.invalid');
            });
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":53}],57:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {TriggerResource}
 */
var triggerResource;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {CurrentContainer}
 */
var currentContainer;

/**
 * @type {$scope}
 */
var scope;

/**
 * @type {Condition}
 */
var condition;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Triggers';

/**
 * @name ManageController
 */

var ManageController = function () {
    /**
     * @param {ngTableParams} TableParams
     * @param {$stateParams} $stateParams
     * @param {TriggerResource} $triggerResource
     * @param {Alert} $alert
     * @param {ParamConverter} $paramConverter
     * @param {CurrentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {$scope} $scope
     * @param {$condition} $condition
     * @param {state} state
     * @param {$translate} $translate
     */

    function ManageController(TableParams, $stateParams, $triggerResource, $alert, $paramConverter, CurrentContainer, PageInfo, $scope, $condition, state, $translate) {
        var _this = this;

        _classCallCheck(this, ManageController);

        alert = $alert;
        stateParams = $stateParams;
        triggerResource = $triggerResource;
        currentContainer = CurrentContainer;
        scope = $scope;
        condition = $condition;

        this.typesArrayPromise = condition.getArrayOfTypes();

        this.typesArrayPromise.then(function (resp) {

            _this.typesArray = resp;
        });

        this.translate = $translate;

        this.containerId = stateParams.containerId;
        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                triggerResource.query(stateParams.containerId, $paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });

        currentContainer.getContainer().then(function () {

            if (!currentContainer.$container.hasPermission('view')) {

                state.go('container');
            }

            _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(currentContainer.$container.name, 'tags', {
                    containerId: currentContainer.$container.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });

        scope.$on('pageInfo.reload', function (event, args) {

            _this.translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(args.name, 'tags', {
                    containerId: args.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });
    }

    _createClass(ManageController, [{
        key: 'removeTrigger',
        value: function removeTrigger(trigger) {

            var self = this;

            trigger.remove().then(function () {

                alert.success('success.remove');
                currentContainer.makeChanges();

                if (self.tableParams.data.length === 1 && self.tableParams.page() > 1) {

                    self.tableParams.page(self.tableParams.page() - 1);
                } else {

                    self.tableParams.reload();
                }
            }, function () {

                alert.error('error.remove');

                self.tableParams.reload();
            });
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],58:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var triggerFactory = function triggerFactory(resource) {

    /**
     * @name Trigger
     * @description Trigger entity
     */

    var Trigger = function () {
        function Trigger() {
            _classCallCheck(this, Trigger);

            /**
             * @type {number}
             */
            this.id = undefined;

            /**
             * @type {string}
             */
            this.name = undefined;

            /**
             * @type {Number}
             */
            this.type = 0;

            /**
            * @type {Array}
            */
            this.conditions = [];

            /**
             * @type {number}
             */
            this.tagsCount = undefined;

            /**
             * @type {string}
             */
            this.updatedAt = undefined;
        }

        /**
         * @param {Condition} condition
         */


        _createClass(Trigger, [{
            key: 'addCondition',
            value: function addCondition(condition) {

                this.conditions.push(condition);
            }

            /**
             * @param {number} index
             */

        }, {
            key: 'removeCondition',
            value: function removeCondition(index) {

                this.conditions.splice(index, 1);
            }

            /**
             * @param {number} containerId
             *
             * @returns {deferred.promise}
             */

        }, {
            key: 'save',
            value: function save(containerId) {
                var result = {},
                    promise;

                var excludedProperty = ['id', 'updatedAt', 'createdAt', 'tagsCount'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        if (property === 'conditions') {

                            var collection = [];

                            for (var index in this[property]) {

                                if (this[property][index] instanceof Object) {

                                    collection.push({
                                        variable: this[property][index].variable,
                                        condition: this[property][index].condition,
                                        value: this[property][index].value
                                    });
                                }
                            }

                            result[property] = collection;
                        } else {

                            result[property] = this[property];
                        }
                    }
                }

                if (this.id === undefined) {

                    promise = resource.post(containerId, result);
                } else {

                    promise = resource.put(this.id, result);
                }

                return promise;
            }

            /**
             * @returns {deferred.promise|Boolean}
             */

        }, {
            key: 'remove',
            value: function remove() {

                if (this.id === undefined) {

                    return false;
                }

                return resource.delete(this.id);
            }
        }]);

        return Trigger;
    }();

    return Trigger;
};

exports.default = triggerFactory;

},{}],59:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Trigger = require('../entity/Trigger.js');

var _Trigger2 = _interopRequireDefault(_Trigger);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var Trigger = (0, _Trigger2.default)(this);

    var trigger = new Trigger();

    if (resp !== undefined) {

        trigger.id = resp.id;
        trigger.name = resp.name;
        trigger.type = resp.type;
        trigger.conditions = resp.conditions;
        trigger.tagsCount = resp.tags_count;
        trigger.updatedAt = resp.updated_at;
    }

    return trigger;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name TriggerResource
 */

var TriggerResource = function () {
    /**
     * @param {$q} $q
     * @param {$http} $http
     */

    function TriggerResource($http, $q) {
        _classCallCheck(this, TriggerResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Trigger}
     */


    _createClass(TriggerResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Trigger = (0, _Trigger2.default)(this);

            return new Trigger();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/triggers/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {number} containerId
         * @param {Array} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(containerId, params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + containerId + '/triggers',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this2, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/triggers/' + id
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {number} containerId
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'post',
        value: function post(containerId, entity) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers/' + containerId + '/triggers',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this3, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/triggers/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this4, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return TriggerResource;
}();

exports.default = TriggerResource;

},{"../entity/Trigger.js":58}],60:[function(require,module,exports){
'use strict';

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _EditInsideTagController = require('./controllers/EditInsideTagController.js');

var _EditInsideTagController2 = _interopRequireDefault(_EditInsideTagController);

var _TriggerResource = require('./resource/TriggerResource.js');

var _TriggerResource2 = _interopRequireDefault(_TriggerResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.trigger'; /**
                                           * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                           *
                                           * This program is free software: you can redistribute it and/or modify it under
                                           * the terms of the GNU Affero General Public License as published by the Free
                                           * Software Foundation, either version 3 of the License, or (at your option) any
                                           * later version.
                                           *
                                           * This program is distrubuted in the hope that it will be useful,
                                           * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                           * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                           * GNU Affero General Public License for more details.
                                           *
                                           * You should have received a copy of the GNU Affero General Public License
                                           * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                           */

var CONDITION_MODULE_NAME = 'clearcode.tm.condition';
var MODULE_DIR = '/app/modules/trigger';

_ManageController2.default.$inject = ['ngTableParams', '$stateParams', MODULE_NAME + '.triggerResource', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$scope', 'clearcode.tm.condition.condition', '$state', '$translate'];

_CreateController2.default.$inject = ['$scope', MODULE_NAME + '.triggerResource', CONDITION_MODULE_NAME + '.conditionResource', '$stateParams', '$state', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.condition.condition', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_EditController2.default.$inject = ['$scope', MODULE_NAME + '.triggerResource', CONDITION_MODULE_NAME + '.conditionResource', '$stateParams', '$state', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.condition.condition', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_EditInsideTagController2.default.$inject = ['$scope', MODULE_NAME + '.triggerResource', CONDITION_MODULE_NAME + '.conditionResource', '$stateParams', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.condition.condition', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_TriggerResource2.default.$inject = ['$http', '$q'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.tagContainer', 'clearcode.tm.condition']).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('triggers', {
        url: '/containers/{containerId}/triggers',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('triggerCreate', {
        url: '/containers/{containerId}/trigger-create',
        controller: MODULE_NAME + '.CreateController as triggerForm',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('triggerEdit', {
        url: '/containers/{containerId}/trigger/edit/{triggerId}',
        controller: MODULE_NAME + '.EditController as triggerForm',
        templateUrl: MODULE_DIR + '/views/edit.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    });
}]).run(['clearcode.tm.alert.$alert', function ($alertProvider) {

    $alertProvider.addMessagePattern('trigger.create', 'Trigger has been created successfully.');
    $alertProvider.addMessagePattern('trigger.edit', 'Trigger has been updated successfully.');
}]).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.EditInsideTagController', _EditInsideTagController2.default).service(MODULE_NAME + '.triggerResource', _TriggerResource2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/CreateController.js":54,"./controllers/EditController.js":55,"./controllers/EditInsideTagController.js":56,"./controllers/ManageController.js":57,"./resource/TriggerResource.js":59}],61:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {UpdateResource}
 */
var updateResource = undefined;

/**
 * @type {$window}
 */
var window = undefined;

/**
 * @name update#VersionController
 */

var VersionController = function () {

  /**
   * @param {UpdateResource} $updateResource
   * @param {$window} $window
   * @param {Version} version
   */

  function VersionController($updateResource, $window, version) {
    _classCallCheck(this, VersionController);

    updateResource = $updateResource;
    window = $window;
    this.version = version;
  }

  _createClass(VersionController, [{
    key: "updateAvailable",
    value: function updateAvailable() {

      return this.version.latest !== undefined && !this.version.isUpToDate();
    }
  }, {
    key: "startUpdate",
    value: function startUpdate() {

      updateResource.setSession().then(function (resp) {

        window.location.href = resp.url;
      });
    }
  }]);

  return VersionController;
}();

exports.default = VersionController;

},{}],62:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name SetVersionDirective
 */

var setVersionDirective = function setVersionDirective(version, updateResource) {

    return {
        restrict: 'A',
        scope: {
            stgVersion: '@'
        },
        link: function link(scope) {

            version.current = scope.stgVersion;

            if (version.latest === undefined) {

                updateResource.getLatest(version.current).then(function (resp) {

                    version.latest = resp.version;
                });
            }
        }
    };
};

exports.default = setVersionDirective;

},{}],63:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name UpdateResource
 */

var UpdateResource = function () {
    /**
     * @param {$q} $q
     * @param {$http} $http
     * @param {$location} $location
     */

    function UpdateResource($http, $q, $location) {
        _classCallCheck(this, UpdateResource);

        this.http = $http;
        this.q = $q;
        this.location = $location;
    }

    /**
     * @description Send request to set session for current user
     *
     * @returns {deferred.promise}
     */


    _createClass(UpdateResource, [{
        key: 'setSession',
        value: function setSession() {

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: 'admin-tools/update'
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get latest version of application
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'getLatest',
        value: function getLatest(version) {

            var deferred = this.q.defer();
            var domain = this.location.host();

            this.http({
                method: 'GET',
                url: '//download.7tag.org/version.json',
                params: {
                    version: version,
                    domain: domain
                },
                headers: {
                    'Access-Control-Request-Headers': undefined
                }
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return UpdateResource;
}();

exports.default = UpdateResource;

},{}],64:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name update#Version
 */

var Version = function () {
    function Version() {
        _classCallCheck(this, Version);

        this.current = undefined;
        this.latest = undefined;
    }

    _createClass(Version, [{
        key: "isUpToDate",
        value: function isUpToDate() {

            return this.current !== undefined && this.current === this.latest;
        }
    }]);

    return Version;
}();

exports.default = Version;

},{}],65:[function(require,module,exports){
'use strict';

var _VersionController = require('./controllers/VersionController.js');

var _VersionController2 = _interopRequireDefault(_VersionController);

var _UpdateResource = require('./resources/UpdateResource.js');

var _UpdateResource2 = _interopRequireDefault(_UpdateResource);

var _Version = require('./services/Version.js');

var _Version2 = _interopRequireDefault(_Version);

var _setVersionDirective = require('./directives/setVersionDirective.js');

var _setVersionDirective2 = _interopRequireDefault(_setVersionDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.update';

_VersionController2.default.$inject = [MODULE_NAME + '.updateResource', '$window', MODULE_NAME + '.version'];

_UpdateResource2.default.$inject = ['$http', '$q', '$location'];

_setVersionDirective2.default.$inject = [MODULE_NAME + '.version', 'clearcode.tm.update.updateResource'];

_Version2.default.$inject = ['$rootScope'];

angular.module(MODULE_NAME, []).controller(MODULE_NAME + '.VersionController', _VersionController2.default).service(MODULE_NAME + '.updateResource', _UpdateResource2.default).service(MODULE_NAME + '.version', _Version2.default).directive('stgSetVersion', _setVersionDirective2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/VersionController.js":61,"./directives/setVersionDirective.js":62,"./resources/UpdateResource.js":63,"./services/Version.js":64}],66:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name BaseController
 */

var BaseController = function () {

    /**
     * @param {UserResource} userResource
     * @param {Alert} alert
     * @param {PageInfo} PageInfo
     * @param {$state} state
     * @param {$translate} $translate
     * @param {RefreshAlert} refreshAlert
     */

    function BaseController(userResource, alert, PageInfo, state, $translate, refreshAlert) {
        _classCallCheck(this, BaseController);

        /**
         * @type {String}
         */
        var BREADCRUMB_SECTION = 'My account';

        this.ACTIVATION_SET_PASSWORD = 'ACTIVATION_SET_PASSWORD';

        this.ROLE_API = 'ROLE_API';
        this.ROLE_USER = 'ROLE_USER';
        this.ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
        this.ROLE_CONTAINERS_CREATE = 'ROLE_CONTAINERS_CREATE';

        this.alert = alert;
        this.state = state;
        this.userResource = userResource;

        this.userPromise = undefined;

        this.user = userResource.getEntity();

        $translate([BREADCRUMB_SECTION]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_SECTION]).broadcast();
        });

        refreshAlert.success('LANGUAGE_CHANGE_MESSAGE', 'user.change_language');
    }

    _createClass(BaseController, [{
        key: 'displayRoleSectionForUser',
        value: function displayRoleSectionForUser() {
            return !this.state.includes('editProfile');
        }
    }, {
        key: 'displayPasswordSection',
        value: function displayPasswordSection() {
            if (!this.activation) {
                return false;
            }

            return this.activation === this.ACTIVATION_SET_PASSWORD;
        }
    }, {
        key: 'setUser',
        value: function setUser(id) {
            var _this = this;

            if (this.state.includes('editProfile')) {

                this.userPromise = this.userResource.getMe();
            } else {

                this.role = undefined;
                this.createContainerRole = undefined;
                this.userPromise = this.userResource.get(id);
            }

            this.userPromise.then(function (resp) {

                _this.user = resp;
                _this.role = _this.getUserRole();

                _this.createContainerRole = _this.user.roles.indexOf(_this.ROLE_CONTAINERS_CREATE) !== -1;
            });
        }
    }, {
        key: 'getUserRole',
        value: function getUserRole() {
            if (this.user.hasRole(this.ROLE_SUPER_ADMIN)) {
                return this.ROLE_SUPER_ADMIN;
            }

            if (this.user.hasRole(this.ROLE_API)) {
                return this.ROLE_API;
            }

            return this.ROLE_USER;
        }
    }, {
        key: 'setRole',
        value: function setRole(role) {

            var roleIndex = this.user.roles.indexOf(role);

            if (roleIndex !== -1) {

                this.user.roles.splice(roleIndex, 1);
                return false;
            } else {

                this.user.roles.push(role);
                return true;
            }
        }
    }, {
        key: 'submitForm',
        value: function submitForm(user) {
            var _this2 = this;

            if (this.state.includes('editProfile')) {

                this.userPromise = user.saveMe();
            } else {

                user.roles = [this.role];

                if (this.role === this.ROLE_USER && this.createContainerRole) {

                    user.roles.push(this.ROLE_CONTAINERS_CREATE);
                }

                this.userPromise = user.save();
            }

            this.userPromise.then(function () {

                _this2.alert.success('user.save');

                if (!_this2.state.includes('editProfile')) {

                    _this2.state.go('users');
                }
            }, function () {

                _this2.alert.error('user.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.alert.error('error.invalid');
        }
    }]);

    return BaseController;
}();

exports.default = BaseController;

},{}],67:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var BREADCRUMB_SECTION = 'My account';

var userResource = undefined;
var alert = undefined;
var state = undefined;
var scope = undefined;
var translate = undefined;

var ChangePasswordController = function () {
    function ChangePasswordController($userResource, $alert, $state, PageInfo, $scope, $translate) {
        _classCallCheck(this, ChangePasswordController);

        userResource = $userResource;
        alert = $alert;
        state = $state;
        scope = $scope;
        translate = $translate;
        this.validationForce = false;
        this.changePasswordPromise = undefined;
        this.translate = $translate;
        this.currentPassword = '';
        this.plainPassword = {
            first: '',
            second: ''
        };

        translate([BREADCRUMB_SECTION]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_SECTION]).broadcast();
        });
    }

    /**
     * @param {String} currentPassword
     * @param {Object} newPassword
     */


    _createClass(ChangePasswordController, [{
        key: 'submitForm',
        value: function submitForm(currentPassword, newPassword) {

            var data = {
                /* eslint-disable */
                current_password: currentPassword,
                /* eslint-enable */
                'plainPassword': newPassword
            };

            this.changePasswordPromise = userResource.changePassword(data);

            this.changePasswordPromise.then(function () {

                alert.success('password.change');
                state.go('container');
            }, function () {

                alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage(newPassword) {

            this.validationForce = true;

            if (newPassword.first !== newPassword.second) {

                scope.changePasswordForm.first.$invalid = true;
                scope.changePasswordForm.second.$invalid = true;

                translate(['Passwords have to be identical']).then(function (translations) {

                    scope.changePasswordForm.first.$error.first = [translations['Passwords have to be identical']];
                });

                scope.changePasswordForm.second.$error.second = [];

                alert.error('error.invalid');
            } else if (newPassword.first === undefined || newPassword.second === undefined) {

                alert.error('password.both');
            } else {

                alert.error('error.invalid');
            }
        }
    }]);

    return ChangePasswordController;
}();

exports.default = ChangePasswordController;

},{}],68:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Add a user';

/**
 * @type {String}
 */
var BREADCRUMB_GLOBAL = 'Users';
/**
 * @type {String}
 */
var ACTIVATION_EMAIL = 'ACTIVATION_EMAIL';

/**
 * @type {String}
 */
var ACTIVATION_SET_PASSWORD = 'ACTIVATION_SET_PASSWORD';

/**
 * @type {String}
 */
var ROLE_USER = 'ROLE_USER';

var scope = undefined;
var translate = undefined;

/**
 * @name CreateController
 */

var CreateController = function (_BaseController) {
    _inherits(CreateController, _BaseController);

    /**
     * @param {UserResource} userResource
     * @param {Alert} alert
     * @param {PageInfo} PageInfo
     * @param {$state} state
     * @param {$scope} $scope
     * @param {$translate} $translate
     * @param {RefreshAlert} RefreshAlert
     */

    function CreateController(userResource, alert, PageInfo, state, $scope, $translate, RefreshAlert) {
        _classCallCheck(this, CreateController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(CreateController).call(this, userResource, alert, PageInfo, state, $translate, RefreshAlert));

        scope = $scope;
        translate = $translate;

        _this.activation = ACTIVATION_EMAIL;
        _this.role = ROLE_USER;

        _this.password = {
            first: '',
            second: ''
        };

        translate([BREADCRUMB_TEXT, BREADCRUMB_GLOBAL]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_GLOBAL], 'users').add(translations[BREADCRUMB_TEXT]).broadcast();
        });

        return _this;
    }

    /**
     * Display error if form is invalid
     */


    _createClass(CreateController, [{
        key: 'createUserFormValidation',
        value: function createUserFormValidation() {

            var isValid = false;

            scope.createUserForm.first.$dirty = true;
            scope.createUserForm.second.$dirty = true;

            if (this.password.first !== this.password.second) {

                scope.createUserForm.first.$invalid = true;
                scope.createUserForm.second.$invalid = true;

                translate(['Passwords have to be identical']).then(function (translations) {

                    scope.createUserForm.first.$error.first = [translations['Passwords have to be identical']];
                });

                scope.createUserForm.second.$error.second = [];

                isValid = false;
            } else if (this.password.first.length < 8 || this.password.second.length < 8) {

                scope.createUserForm.first.$invalid = true;
                scope.createUserForm.second.$invalid = true;

                translate(['Password needs to have minimum 8 characters']).then(function (translations) {

                    scope.createUserForm.first.$error.first = [translations['Password needs to have minimum 8 characters']];
                });

                scope.createUserForm.second.$error.second = [];

                isValid = false;
            } else if (this.activation === ACTIVATION_SET_PASSWORD) {
                isValid = true;
            }

            if (!scope.createUserForm.$valid) {
                isValid = false;
            }

            if (scope.createUserForm.$valid && this.activation === ACTIVATION_EMAIL) {
                isValid = true;
            }

            if (!isValid) {
                this.alert.error('user.invalid');
            }

            return isValid;
        }
    }, {
        key: 'submitForm',
        value: function submitForm(user) {
            var _this2 = this;

            user.roles = [this.role];

            if (this.role === this.ROLE_USER && this.createContainerRole) {

                user.roles.push(this.ROLE_CONTAINERS_CREATE);
            }

            if (this.activation === ACTIVATION_SET_PASSWORD) {

                user.plainPassword = this.password;
            }

            this.userPromise = user.save();

            this.userPromise.then(function () {

                _this2.alert.success('user.save');
                _this2.state.go('users');
            }, function () {

                _this2.alert.error('user.invalid');
            });
        }
    }]);

    return CreateController;
}(_BaseController3.default);

exports.default = CreateController;

},{"./BaseController.js":66}],69:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Edit user';

/**
 * @type {String}
 */
var BREADCRUMB_GLOBAL = 'Users';

var scope = undefined;
var state = undefined;
var translate = undefined;

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {UserResource} userResource
     * @param {stateParams} stateParams
     * @param {Alert} alert
     * @param {PageInfo} PageInfo
     * @param {$state} $state
     * @param {$scope} $scope
     * @param {$translate} $translate
     * @param {refreshAlert} refreshAlert
     * @param {security} security
     */

    function EditController(userResource, stateParams, alert, PageInfo, $state, $scope, $translate, refreshAlert, security) {
        _classCallCheck(this, EditController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, userResource, alert, PageInfo, $state, $translate, refreshAlert));

        scope = $scope;
        state = $state;
        translate = $translate;
        _this.security = security;

        if (state.includes('editProfile')) {

            _this.setUser();
        } else {

            _this.activation = _this.ACTIVATION_SET_PASSWORD;

            _this.password = {
                first: '',
                second: ''
            };

            _this.setUser(stateParams.userId);

            translate([BREADCRUMB_TEXT, BREADCRUMB_GLOBAL]).then(function (translations) {

                PageInfo.clear().add(translations[BREADCRUMB_GLOBAL], 'users').add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        }

        return _this;
    }

    /**
     * Display error if form is invalid
     */


    _createClass(EditController, [{
        key: 'editUserFormValidation',
        value: function editUserFormValidation() {

            scope.editUserForm.first.$dirty = true;
            scope.editUserForm.second.$dirty = true;

            if (this.password.first === '' && this.password.second === '') {

                return true;
            }

            if (this.password.first !== this.password.second) {

                scope.editUserForm.first.$invalid = true;
                scope.editUserForm.second.$invalid = true;

                translate(['Passwords have to be identical']).then(function (translations) {

                    scope.editUserForm.first.$error.first = [translations['Passwords have to be identical']];
                });

                scope.editUserForm.second.$error.second = [];

                return false;
            } else if (this.password.first.length < 8 || this.password.second.length < 8) {

                scope.editUserForm.first.$invalid = true;
                scope.editUserForm.second.$invalid = true;

                translate(['Password needs to have minimum 8 characters']).then(function (translations) {

                    scope.editUserForm.first.$error.first = [translations['Password needs to have minimum 8 characters']];
                });

                scope.editUserForm.second.$error.second = [];

                return false;
            }

            if (scope.editUserForm.$valid) {

                return true;
            } else {

                return false;
            }
        }
    }, {
        key: 'submitForm',
        value: function submitForm(user) {
            var _this2 = this;

            user.roles = [this.role];

            if (this.role === this.ROLE_USER && this.createContainerRole) {

                user.roles.push(this.ROLE_CONTAINERS_CREATE);
            }

            if (this.hasOwnProperty('password')) {

                if (this.password.first !== '') {

                    user.plainPassword = this.password;
                }
            }

            if (state.includes('editProfile')) {

                this.userPromise = user.saveMe();
            } else {

                this.userPromise = user.save();
            }

            this.userPromise.then(function () {
                _this2.security.getUser(true);
                _this2.alert.success('user.save');
                _this2.state.go('container');
            }, function () {

                _this2.alert.error('user.invalid');
            });
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":66}],70:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var $alert;

/**
 * @type {UserResource}
 */
var $userResource;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Users';

/**
 * @name ManageController
 */

var ManageController = function () {

    /**
     * @param {ngTableParams} TableParams
     * @param {UserResource} userResource
     * @param {Alert} alert
     * @param paramConverter
     * @param currentContainer
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function ManageController(TableParams, userResource, alert, paramConverter, currentContainer, PageInfo, $translate) {
        _classCallCheck(this, ManageController);

        this.translate = $translate;
        $alert = alert;
        $userResource = userResource;

        currentContainer.disableDebugMode();

        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                userResource.query(paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });

        this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    }

    _createClass(ManageController, [{
        key: 'removeUser',
        value: function removeUser(user) {

            var self = this;

            user.remove().then(function () {

                $alert.success('success.remove');

                if (self.tableParams.data.length === 1) {

                    self.tableParams.page(self.tableParams.page() - 1);
                }

                self.tableParams.reload();
            }, function () {

                $alert.error('error.remove');

                self.tableParams.reload();
            });
        }
    }, {
        key: 'resetPassword',
        value: function resetPassword(user) {
            var _this = this;

            var data = {
                username: user.email
            };

            this.resetPromise = $userResource.resetPassword(data);

            this.resetPromise.then(function () {

                $alert.success('user.reset');
                _this.tableParams.reload();
            }, function () {});
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],71:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {string}
 */
var BREADCRUMB_SECTION = 'My account';

/**
 * @type {Translation}
 */
var translation;

var OthersSettingsController = function () {
    function OthersSettingsController($alert, $scope, $translate, $translation, $window, PageInfo) {
        var _this = this;

        _classCallCheck(this, OthersSettingsController);

        alert = $alert;
        this.scope = $scope;
        translation = $translation;
        this.validationForce = false;
        this.translate = $translate;
        this.window = $window;
        this.languages = translation.getLanguages();

        if (!this.languages.length) {

            this.loadTranslationsPromise = translation.loadAvailableTranslations();

            this.loadTranslationsPromise.then(function () {

                _this.languages = translation.languages;
            });
        }

        $translate([BREADCRUMB_SECTION]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_SECTION]).broadcast();
        });
    }

    /**
     * @param {Object} user
     */


    _createClass(OthersSettingsController, [{
        key: 'submitForm',
        value: function submitForm(user) {
            var _this2 = this;

            var localStorageLang = this.window.localStorage['NG_TRANSLATE_LANG_KEY'];
            var storedLanguage = localStorageLang ? localStorageLang : 'en';
            this.othersSettingsPromise = user.saveOthersSettings();

            this.othersSettingsPromise.then(function (User) {

                _this2.translate.use(User.language).then(function () {

                    if (User.language !== storedLanguage) {

                        _this2.window.localStorage['LANGUAGE_CHANGE_MESSAGE'] = true;
                        _this2.window.location.reload();
                    } else {

                        alert.success('user.save');
                    }
                });
            }, function () {

                alert.error('error.invalid');
            });
        }
    }]);

    return OthersSettingsController;
}();

exports.default = OthersSettingsController;

},{}],72:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {UserResource}
 */
var userResource;

/**
 * @type {AlertForm}
 */
var alertForm;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Forgot your password?';

/**
 * @name security#ResetRequestController
 * @description Controller for sending request for new password
 */

var ResetRequestController = function () {

    /**
     * @param {UserResource} $userResource
     * @param {AlertForm} $alertForm
     * @param {Alert} $alert
     * @param {PageInfo} PageInfo
     * @param {$translate} $translate
     */

    function ResetRequestController($userResource, $alertForm, $alert, PageInfo, $translate) {
        _classCallCheck(this, ResetRequestController);

        this.email = '';
        this.translate = $translate;
        this.alert = $alert;
        userResource = $userResource;

        alertForm = $alertForm;
        alertForm.getStorage().clean();

        this.validationForce = false;

        this.resetPromise = undefined;

        this.success = false;

        this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    }

    /**
     *
     * @param {String} email
     */


    _createClass(ResetRequestController, [{
        key: 'submitForm',
        value: function submitForm(email) {
            var _this = this;

            var data = {
                username: email
            };

            this.resetPromise = userResource.resetPassword(data);

            this.resetPromise.then(function () {

                alertForm.getStorage().clean();
                _this.success = true;

                _this.translate(['The email with the activation link has been sent successfully.']).then(function (translations) {

                    _this.formTitle = translations['The email with the activation link has been sent successfully.'];
                });
            }, function () {

                alertForm.getStorage().clean();
                alertForm.error('request.error');
                _this.alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.validationForce = true;
            this.alert.error('error.invalid');
            alertForm.getStorage().clean();
            alertForm.error('invalid.email');
        }
    }]);

    return ResetRequestController;
}();

exports.default = ResetRequestController;

},{}],73:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {UserResource}
 */
var userResource;

/**
 * @type {$state}
 */
var state;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {Alert}
 */
var alert;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Change your password';

/**
 * @type {$scope}
 */
var scope;

/**
 * @name security#ChangePasswordController
 * @description Controller for change password view
 */

var ChangePasswordController = function () {

    /**
     * @param {UserResource} $userResource
     * @param {$state} $state
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {PageInfo} PageInfo
     * @param {$scope} $scope
     * @param {$translate} $translate
     */

    function ChangePasswordController($userResource, $state, $stateParams, $alert, PageInfo, $scope, $translate) {
        _classCallCheck(this, ChangePasswordController);

        this.new = {
            first: undefined,
            second: undefined
        };

        this.validationForce = false;
        this.changePasswordPromise = undefined;
        this.translate = $translate;

        userResource = $userResource;
        state = $state;
        stateParams = $stateParams;
        alert = $alert;
        scope = $scope;

        if (stateParams.token === '') {

            state.go('signIn');
            alert.error('changePassword.token');
        }

        this.translate([BREADCRUMB_TEXT]).then(function (translations) {

            PageInfo.clear().add(translations[BREADCRUMB_TEXT]).broadcast();
        });
    }

    /**
     * @param {Object} newPassword
     */


    _createClass(ChangePasswordController, [{
        key: 'submitForm',
        value: function submitForm(newPassword) {

            var password = {
                'plainPassword': newPassword
            };

            this.changePasswordPromise = userResource.setPassword(password, stateParams.token);

            this.changePasswordPromise.then(function () {

                state.go('signIn');
                alert.success('changePassword.success');
            }, function () {

                alert.error('changePassword.error');
            });
        }

        /**
         * Display error if form is invalid
         * @param {Object} newPassword
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage(newPassword) {

            this.validationForce = true;

            if (newPassword.first !== newPassword.second) {

                scope.resetPasswordForm.first.$invalid = true;
                scope.resetPasswordForm.second.$invalid = true;

                this.translate(['Passwords have to be identical']).then(function (translations) {

                    scope.resetPasswordForm.first.$error.first = [translations['Passwords have to be identical']];
                });

                scope.resetPasswordForm.second.$error.second = [];
            } else if (newPassword.first === undefined || newPassword.second === undefined) {

                alert.error('password.both');
            }
        }
    }]);

    return ChangePasswordController;
}();

exports.default = ChangePasswordController;

},{}],74:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var userFactory = function userFactory(resource) {

    /**
     * @name User
     * @description User entity
     */

    var User = function () {
        function User() {
            _classCallCheck(this, User);

            /**
             * @type {number}
             */
            this.id = undefined;

            /**
             * @type {string}
             */
            this.email = undefined;

            /**
             * @type {string}
             */
            this.firstName = undefined;

            /**
             * @type {string}
             */
            this.lastName = undefined;

            /**
             * @type {string}
             */
            this.displayName = undefined;

            /**
             * @type {Array}
             */
            this.roles = [];

            /**
             * @type {Boolean}
             */
            this.status = false;

            /**
             * @type {Date}
             */
            this.createdAt = undefined;

            /**
             * @type {string}
             */
            this.language = undefined;
        }

        /**
         * @param role
         * @returns {boolean}
         */


        _createClass(User, [{
            key: 'hasRole',
            value: function hasRole(role) {

                return this.roles.indexOf(role) !== -1;
            }

            /**
             * @returns {deferred.promise}
             */

        }, {
            key: 'save',
            value: function save() {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'displayName', 'status', 'createdAt', 'language'];

                var excludedInEdit = ['email'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        if (this.id === undefined || excludedInEdit.indexOf(property) === -1) {

                            result[property] = this[property];
                        }
                    }
                }

                if (this.id === undefined) {

                    promise = resource.post(result);
                } else {

                    promise = resource.put(this.id, result);
                }

                return promise;
            }

            /**
             * @returns {deferred.promise}
             */

        }, {
            key: 'saveMe',
            value: function saveMe() {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'displayName', 'roles', 'status', 'createdAt', 'email', 'language'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        result[property] = this[property];
                    }
                }

                promise = resource.putMe(result);

                return promise;
            }

            /**
             * @returns {deferred.promise}
             */

        }, {
            key: 'saveOthersSettings',
            value: function saveOthersSettings() {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'displayName', 'roles', 'status', 'createdAt', 'email', 'firstName', 'lastName'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        result[property] = this[property];
                    }
                }

                promise = resource.othersSettingsMe(result);

                return promise;
            }

            /**
             * @returns {deferred.promise|Boolean}
             */

        }, {
            key: 'remove',
            value: function remove() {

                if (this.id === undefined) {

                    return false;
                }

                return resource.delete(this.id);
            }
        }]);

        return User;
    }();

    return User;
};

exports.default = userFactory;

},{}],75:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _User = require('../entities/User.js');

var _User2 = _interopRequireDefault(_User);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CHANGE_PASSWORD_ROUTE = '/api/users/me/change-password';
var RESET_REQUEST_ROUTE = '/api/reset-password/request';
var RESET_PASSWORD_ROUTE = '/api/reset-password/token/';
var DEFAULT_LANGUAGE = 'en';

var transformResponse = function transformResponse(resp) {

    var user = this.getEntity();

    if (resp !== undefined) {

        user.id = resp.id;
        user.email = resp.email;
        user.firstName = resp.first_name;
        user.lastName = resp.last_name;
        user.displayName = resp.display_name;
        user.roles = resp.roles;
        user.status = resp.status;
        user.createdAt = resp.created_at;

        if (resp.language === null) {

            user.language = DEFAULT_LANGUAGE;
        } else {

            user.language = resp.language;
        }
    }

    return user;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name UserResource
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */

var UserResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function UserResource($http, $q) {
        _classCallCheck(this, UserResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {User}
     */


    _createClass(UserResource, [{
        key: 'getEntity',
        value: function getEntity() {

            var User = (0, _User2.default)(this);

            return new User();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/users/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function () {

                deferred.reject();
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get specific entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'getMe',
        value: function getMe() {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/users/me',
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this2, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(params) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/users',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    if (status < 400) {

                        for (var element in resp.data) {

                            if (resp.data[element] instanceof Object) {

                                resp.data[element] = transformResponse.call(_this3, resp.data[element]);
                            }
                        }
                    }

                    return resp;
                })
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/users/' + id
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {object} entity
         */

    }, {
        key: 'post',
        value: function post(entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/users',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this4, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this5 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/users/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this5, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'putMe',
        value: function putMe(entity) {
            var _this6 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/users/me',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this6, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'othersSettingsMe',
        value: function othersSettingsMe(entity) {
            var _this7 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/users/me/others-settings',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this7, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to change password
         *
         * @param {Object} data
         * @returns {deferred.promise|*}
         */

    }, {
        key: 'changePassword',
        value: function changePassword(data) {

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                data: data,
                url: CHANGE_PASSWORD_ROUTE
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to reset password
         *
         * @param {Object} data
         * @returns {deferred.promise|*}
         */

    }, {
        key: 'resetPassword',
        value: function resetPassword(data) {

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                data: data,
                url: RESET_REQUEST_ROUTE
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to confirm password with the new one
         *
         * @param {Object} data
         * @param {String} token
         * @returns {deferred.promise|*}
         */

    }, {
        key: 'setPassword',
        value: function setPassword(data, token) {

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                data: data,
                url: RESET_PASSWORD_ROUTE + token
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return UserResource;
}();

exports.default = UserResource;

},{"../entities/User.js":74}],76:[function(require,module,exports){
'use strict';

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _OthersSettingsController = require('./controllers/OthersSettingsController.js');

var _OthersSettingsController2 = _interopRequireDefault(_OthersSettingsController);

var _ChangePasswordController = require('./controllers/ChangePasswordController.js');

var _ChangePasswordController2 = _interopRequireDefault(_ChangePasswordController);

var _ResetRequestController = require('./controllers/ResetRequestController.js');

var _ResetRequestController2 = _interopRequireDefault(_ResetRequestController);

var _SetPasswordController = require('./controllers/SetPasswordController.js');

var _SetPasswordController2 = _interopRequireDefault(_SetPasswordController);

var _UserResource = require('./resources/UserResource.js');

var _UserResource2 = _interopRequireDefault(_UserResource);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.user';
var MODULE_DIR = 'app/modules/user';

_CreateController2.default.$inject = [MODULE_NAME + '.userResource', 'clearcode.tm.alert.alert', 'clearcode.tm.pageInfo.pageInfo', '$state', '$scope', '$translate', 'clearcode.tm.alert.refreshAlert'];

_EditController2.default.$inject = [MODULE_NAME + '.userResource', '$stateParams', 'clearcode.tm.alert.alert', 'clearcode.tm.pageInfo.pageInfo', '$state', '$scope', '$translate', 'clearcode.tm.alert.refreshAlert', 'clearcode.tm.security.security'];

_ManageController2.default.$inject = ['ngTableParams', MODULE_NAME + '.userResource', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_ChangePasswordController2.default.$inject = ['clearcode.tm.user.userResource', 'clearcode.tm.alert.alert', '$state', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$translate'];

_OthersSettingsController2.default.$inject = ['clearcode.tm.alert.alert', '$scope', '$translate', 'clearcode.tm.translation.translation', '$window', 'clearcode.tm.pageInfo.pageInfo'];

_ResetRequestController2.default.$inject = [MODULE_NAME + '.userResource', 'clearcode.tm.form.alertForm', 'clearcode.tm.alert.alert', 'clearcode.tm.pageInfo.pageInfo', '$translate'];

_SetPasswordController2.default.$inject = [MODULE_NAME + '.userResource', '$state', '$stateParams', 'clearcode.tm.alert.alert', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$translate'];

_UserResource2.default.$inject = ['$http', '$q'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.form', 'clearcode.tm.tagContainer', 'clearcode.tm.security']).run(['clearcode.tm.alert.$alert', function ($alertProvider) {

    $alertProvider.addMessagePattern('user.save', 'User account has been saved successfully.');

    $alertProvider.addMessagePattern('user.change_language', 'The language has been changed');

    $alertProvider.addMessagePattern('password.change', 'Your password has been changed successfully.');

    $alertProvider.addMessagePattern('user.invalid', 'Sorry, something went wrong.');

    $alertProvider.addMessagePattern('user.reset', 'Email with activation link has been sent.');

    $alertProvider.addMessagePattern('user.others', 'Other settings has been saved successfully.');
}]).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('users', {
        url: '/users',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    }).state('userCreate', {
        url: '/users-create',
        controller: MODULE_NAME + '.CreateController as view',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    }).state('userEdit', {
        url: '/users-edit/{userId}',
        controller: MODULE_NAME + '.EditController as view',
        templateUrl: MODULE_DIR + '/views/edit.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: false
        }
    }).state('editProfile', {
        url: '/profile',
        controller: MODULE_NAME + '.EditController as view',
        controllerAs: 'view',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            secure: true
        },
        templateUrl: '/app/modules/user/views/profile.html'
    }).state('resetPassword', {
        url: '/reset-password',
        controller: MODULE_NAME + '.ResetRequestController',
        controllerAs: 'view',
        data: {
            roles: [],
            secure: false
        },
        templateUrl: '/app/modules/user/views/resetPassword.html'
    }).state('resetConfirm', {
        url: '/reset-password/token/{token}',
        controller: MODULE_NAME + '.SetPasswordController',
        controllerAs: 'view',
        data: {
            roles: [],
            secure: false
        },
        templateUrl: '/app/modules/user/views/setPassword.html'
    });
}]).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.OthersSettingsController', _OthersSettingsController2.default).controller(MODULE_NAME + '.ChangePasswordController', _ChangePasswordController2.default).controller(MODULE_NAME + '.ResetRequestController', _ResetRequestController2.default).controller(MODULE_NAME + '.SetPasswordController', _SetPasswordController2.default).service(MODULE_NAME + '.userResource', _UserResource2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/ChangePasswordController.js":67,"./controllers/CreateController.js":68,"./controllers/EditController.js":69,"./controllers/ManageController.js":70,"./controllers/OthersSettingsController.js":71,"./controllers/ResetRequestController.js":72,"./controllers/SetPasswordController.js":73,"./resources/UserResource.js":75}],77:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name BaseController
 */

var BaseController = function () {

    /**
     * @param {VariableResource} $variableResource
     * @param {VariableTypeResource} $typeResource
     * @param {PageInfo} PageInfo
     * @param $scope
     * @param {VariableFormProvider} $variableFormProvider
     * @param {CurrentContainer} currentContainer
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$translate} $translate
     */

    function BaseController($variableResource, $typeResource, $PageInfo, $scope, $variableFormProvider, currentContainer, $stateParams, $alert, $state, $translate, $condition) {
        var _this = this;

        _classCallCheck(this, BaseController);

        this.variable = $variableResource.getEntityObject();

        this.typeResource = $typeResource;

        $scope.$on('pageInfo.reload', function (event, args) {

            $translate([_this.getBreadcrumbText()]).then(function (translations) {

                $PageInfo.clear().add(args.name, 'tags', {
                    containerId: args.id
                }).add(translations[_this.getBreadcrumbText()], 'variables', {
                    containerId: args.id
                }).broadcast();
            });
        });

        this.variableFormProvider = $variableFormProvider;

        this.currentContainer = currentContainer;
        this.condition = $condition;
        this.stateParams = $stateParams;
        this.alert = $alert;
        this.state = $state;
        this.translate = $translate;

        this.formTouched = false;
    }

    _createClass(BaseController, [{
        key: 'getVariableTypes',
        value: function getVariableTypes() {
            var _this2 = this;

            this.typesPromise = this.typeResource.query();

            this.typesPromise.then(function (resp) {

                _this2.types = resp.data;

                if (_this2.variable.type && _this2.variable.type.id === undefined) {

                    for (var i = 0; i < _this2.types.length; i++) {
                        if (_this2.variable.type === _this2.types[i].id) {
                            _this2.variable.type = _this2.types[i];
                            _this2.formTouched = true;
                            break;
                        }
                    }
                }
            });
        }

        /**
         * @param {VariableType} type
         */

    }, {
        key: 'setVariableType',
        value: function setVariableType(type) {

            this.variable.type = type;
            this.variable.value = '';

            this.formTouched = true;
        }

        /**
         * @returns {String} url for active type
         */

    }, {
        key: 'getConfigurationTemplate',
        value: function getConfigurationTemplate() {

            var typeId = this.variable.type.id !== undefined ? this.variable.type.id : this.variable.type;

            return this.variableFormProvider.getType(typeId).templateUrl;
        }

        /**
         * Handle form submit action
         *
         * @param {Variable} variable
         */

    }, {
        key: 'submitForm',
        value: function submitForm(variable) {
            var _this3 = this;

            this.variablePromise = variable.save(this.stateParams.containerId);

            this.variablePromise.then(function () {

                _this3.currentContainer.makeChanges();
                _this3.condition.refresh();
                _this3.alert.success(_this3.getAlertType());

                _this3.state.go('variables', {
                    containerId: _this3.stateParams.containerId
                });
            }, function () {

                _this3.alert.error('error.invalid');
            });
        }

        /**
         * Display error if form is invalid
         */

    }, {
        key: 'displayInvalidFormMessage',
        value: function displayInvalidFormMessage() {

            this.validateVariable = true;
            this.alert.error('error.invalid');
        }
    }]);

    return BaseController;
}();

exports.default = BaseController;

},{}],78:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

var BREADCRUMB_GLOBAL = 'Variables';

/**
 * @name CreateController
 */

var CreateController = function (_BaseController) {
    _inherits(CreateController, _BaseController);

    /**
     * @param {VariableResource} $variableResource
     * @param {VariableTypeResource} $typeResource
     * @param {PageInfo} $PageInfo
     * @param $scope
     * @param {VariableFormProvider} $variableFormProvider
     * @param {CurrentContainer} currentContainer
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$translate} $translate
     * @param {$condition} $condition
     */

    function CreateController($variableResource, $typeResource, $PageInfo, $scope, $variableFormProvider, currentContainer, $stateParams, $alert, $state, $translate, $condition) {
        _classCallCheck(this, CreateController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(CreateController).call(this, $variableResource, $typeResource, $PageInfo, $scope, $variableFormProvider, currentContainer, $stateParams, $alert, $state, $translate, $condition));

        _this.variable = $variableResource.getEntityObject();

        _this.getVariableTypes();

        currentContainer.getContainer().then(function () {

            if (!currentContainer.$container.hasPermission('edit')) {

                $state.go('triggers', { containerId: $stateParams.containerId });
            }

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                $PageInfo.clear().add(currentContainer.$container.name, 'tags', {
                    containerId: currentContainer.$container.id
                }).add(translations[BREADCRUMB_GLOBAL], 'variables', {
                    containerId: currentContainer.$container.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });

        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(CreateController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Add a new variable';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.create';
        }
    }]);

    return CreateController;
}(_BaseController3.default);

exports.default = CreateController;

},{"./BaseController.js":77}],79:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _BaseController2 = require('./BaseController.js');

var _BaseController3 = _interopRequireDefault(_BaseController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

var BREADCRUMB_GLOBAL = 'Variables';

/**
 * @name EditController
 */

var EditController = function (_BaseController) {
    _inherits(EditController, _BaseController);

    /**
     * @param {VariableResource} $variableResource
     * @param {VariableTypeResource} $typeResource
     * @param {PageInfo} PageInfo
     * @param $scope
     * @param {VariableFormProvider} $variableFormProvider
     * @param {CurrentContainer} currentContainer
     * @param {$stateParams} $stateParams
     * @param {Alert} $alert
     * @param {$state} $state
     * @param {$translate} $translate
     * @param {$condition} $condition
     */

    function EditController($variableResource, $typeResource, $PageInfo, $scope, $variableFormProvider, currentContainer, $stateParams, $alert, $state, $translate, $condition) {
        _classCallCheck(this, EditController);

        var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(EditController).call(this, $variableResource, $typeResource, $PageInfo, $scope, $variableFormProvider, currentContainer, $stateParams, $alert, $state, $translate, $condition));

        _this.variablePromise = $variableResource.get($stateParams.variableId);

        _this.variablePromise.then(function (resp) {
            _this.variable = resp;

            _this.getVariableTypes();
        });

        _this.currentContainer.getContainer().then(function () {

            if (_this.currentContainer.$container.hasPermission('noaccess')) {

                _this.state.go('container');
            }

            _this.translate([_this.getBreadcrumbText(), BREADCRUMB_GLOBAL]).then(function (translations) {

                $PageInfo.clear().add(_this.currentContainer.$container.name, 'tags', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[BREADCRUMB_GLOBAL], 'variables', {
                    containerId: _this.currentContainer.$container.id
                }).add(translations[_this.getBreadcrumbText()]).broadcast();
            });
        });

        _this.formTouched = true;
        return _this;
    }

    /**
     * Get breadcrumb text
     *
     */


    _createClass(EditController, [{
        key: 'getBreadcrumbText',
        value: function getBreadcrumbText() {

            return 'Configure variable';
        }

        /**
         * Get alert type
         *
         */

    }, {
        key: 'getAlertType',
        value: function getAlertType() {

            return 'success.edit';
        }
    }]);

    return EditController;
}(_BaseController3.default);

exports.default = EditController;

},{"./BaseController.js":77}],80:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {Alert}
 */
var $alert;

/**
 * @type {$stateParams}
 */
var stateParams;

/**
 * @type {CurrentContainer}
 */
var currentContainer;

/**
 * @type {$scope}
 */
var scope;

/**
 * @type {String}
 */
var BREADCRUMB_TEXT = 'Variables';

/**
 * @name ManageController
 */

var ManageController = function () {

    /**
     * @param {ngTableParams} TableParams
     * @param {$stateParams} $stateParams
     * @param {VariableResource} variableResource
     * @param {Alert} alert
     * @param {ParamConverter} paramConverter
     * @param {CurrentContainer} CurrentContainer
     * @param {PageInfo} PageInfo
     * @param {$scope} $scope
     * @param {$translate} $translate
     */

    function ManageController(TableParams, $stateParams, variableResource, alert, paramConverter, CurrentContainer, PageInfo, $scope, state, $translate) {
        var _this = this;

        _classCallCheck(this, ManageController);

        $alert = alert;
        stateParams = $stateParams;
        currentContainer = CurrentContainer;
        scope = $scope;

        this.permissions = [];
        this.containerId = stateParams.containerId;

        currentContainer.getContainer().then(function (container) {

            if (!currentContainer.$container.hasPermission('view')) {

                state.go('container');
            } else {

                _this.permissions = currentContainer.$container.permissions;
            }

            $translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(container.name, 'variable', {
                    containerId: container.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });

        this.tableParams = new TableParams({
            page: 1,
            count: 10,
            sorting: {
                name: 'asc'
            }
        }, {
            total: 0,
            getData: function getData($defer, params) {

                variableResource.query(stateParams.containerId, paramConverter.list(params.url())).then(function (resp) {

                    params.total(resp.total);

                    $defer.resolve(resp.data);
                });
            }
        });

        scope.$on('pageInfo.reload', function (event, args) {

            $translate([BREADCRUMB_TEXT]).then(function (translations) {

                PageInfo.clear().add(args.name, 'variables', {
                    containerId: args.id
                }).add(translations[BREADCRUMB_TEXT]).broadcast();
            });
        });
    }

    _createClass(ManageController, [{
        key: 'removeVariable',
        value: function removeVariable(variable) {
            var _this2 = this;

            variable.remove().then(function () {

                $alert.success('success.remove');
                currentContainer.makeChanges();

                if (_this2.tableParams.data.length === 1 && _this2.tableParams.page() > 1) {

                    _this2.tableParams.page(_this2.tableParams.page() - 1);
                } else {

                    _this2.tableParams.reload();
                }
            }, function () {

                $alert.error('error.remove');

                _this2.tableParams.reload();
            });
        }
    }]);

    return ManageController;
}();

exports.default = ManageController;

},{}],81:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var variableSelector = undefined,
    scope = undefined,
    compile = undefined;

var SelectorController = function () {
    function SelectorController($compile, $stateParams, $scope, $variableSelector, condition) {
        var _this = this;

        _classCallCheck(this, SelectorController);

        compile = $compile;
        scope = $scope;
        variableSelector = $variableSelector;

        condition.allVariables().then(function (resp) {
            _this.variables = resp;
        });
    }

    _createClass(SelectorController, [{
        key: 'init',
        value: function init($element, $ngModel) {
            this.element = $element;
            this.ngModel = $ngModel;

            var listElement = compileListView();
            this.ulElement = listElement.children();
            createWrapper(this.element, listElement);
        }
    }, {
        key: 'toggleVariablesList',
        value: function toggleVariablesList(event) {
            var elementValue = this.element.val().trim();

            this.isVariablesListVisible() ? this.element.val(variableSelector.removeVariablePrompt(elementValue)) : this.element.val(variableSelector.addVariablePrompt(elementValue));

            this.ulElement.toggleClass('show');
            stopBubblingEvent(event);
        }
    }, {
        key: 'addVariable',
        value: function addVariable(variable) {
            var elementValue = this.element.val().trim();
            this.ulElement.removeClass('show');

            this.ngModel.$setViewValue(variableSelector.selectVariable(elementValue, variable));
            this.ngModel.$render();
            this.element[0].focus();
        }
    }, {
        key: 'closeVariablePrompt',
        value: function closeVariablePrompt(event) {
            var elementValue = this.element.val().trim();

            if (!this.isVariablesListVisible()) {
                return;
            }

            this.ulElement.removeClass('show');
            this.element.val(variableSelector.removeVariablePrompt(elementValue));
            stopBubblingEvent(event);
        }
    }, {
        key: 'isVariablesListVisible',
        value: function isVariablesListVisible() {
            return this.ulElement.hasClass('show');
        }
    }]);

    return SelectorController;
}();

function stopBubblingEvent(event) {
    event.stopPropagation();
    event.cancelBubble = true;
}

function compileListView() {
    var listTemplate = angular.element(document.getElementById('variables-list.html')).html();

    return compile(listTemplate)(scope);
}

function createWrapper(element, listElement) {
    var btnElement = compile(variableSelector.getTemplate())(scope),
        inputGroupWrapper = undefined;

    element.wrap(variableSelector.getElementWrapper());

    inputGroupWrapper = element.parent();
    inputGroupWrapper.append(btnElement);
    inputGroupWrapper.append(listElement);
}

exports.default = SelectorController;

},{}],82:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var variableClickOutside = function variableClickOutside($document) {

    return {
        restrict: 'A',
        require: 'variableSelector',
        link: link
    };

    function link(scope, element, attrs, selectorCtrl) {
        $document.on('click', documentOnClick);

        scope.$on('$destroy', function () {
            $document.off('click', documentOnClick);
        });

        function documentOnClick(event) {
            var outside = !element[0].contains(event.target),
                listVisible = selectorCtrl.isVariablesListVisible();

            if (outside && listVisible) {
                element.val(selectorCtrl.closeVariablePrompt(event));
            }
        }
    }
};

exports.default = variableClickOutside;

},{}],83:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _SelectorController = require('../controllers/SelectorController.js');

var _SelectorController2 = _interopRequireDefault(_SelectorController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var variableSelectorDirective = function variableSelectorDirective() {

    return {
        restrict: 'A',
        require: ['variableSelector', 'ngModel'],
        scope: {},
        link: link,
        controller: _SelectorController2.default,
        controllerAs: 'selectorCtrl',
        bindToController: true
    };

    function link(scope, element, attrs, controllers) {
        var selectorController = controllers[0];
        var ngModel = controllers[1];

        selectorController.init(element, ngModel);
    }
}; /**
    * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
    *
    * This program is free software: you can redistribute it and/or modify it under
    * the terms of the GNU Affero General Public License as published by the Free
    * Software Foundation, either version 3 of the License, or (at your option) any
    * later version.
    *
    * This program is distrubuted in the hope that it will be useful,
    * but WITHOUT ANY WARRANTY; without even the implied warranty of
    * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
    * GNU Affero General Public License for more details.
    *
    * You should have received a copy of the GNU Affero General Public License
    * along with this program. If not, see <http://www.gnu.org/licenses/>.
    */

exports.default = variableSelectorDirective;

},{"../controllers/SelectorController.js":81}],84:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var variableFactory = function variableFactory(resource) {

    /**
     * @name Variable
     * @description Variable entity
     */

    var Variable = function () {
        function Variable() {
            _classCallCheck(this, Variable);

            /**
             * @type {number}
             */
            this.id = undefined;

            /**
             * @type {string}
             */
            this.name = undefined;

            /**
             * @type {string}
             */
            this.description = undefined;

            /**
             * @type {string}
             */
            this.type = undefined;

            /**
             * @type {string}
             */
            this.value = undefined;

            /**
             * @type {Object}
             */
            this.options = [];

            /**
             * @type {string}
             */
            this.updatedAt = undefined;
        }

        /**
         * @param {number} containerId
         *
         * @returns {deferred.promise}
         */


        _createClass(Variable, [{
            key: 'save',
            value: function save(containerId) {

                var result = {},
                    promise;

                var excludedProperty = ['id', 'updatedAt'];

                for (var property in this) {

                    if (!angular.isFunction(this[property]) && this.hasOwnProperty(property) && excludedProperty.indexOf(property) === -1) {

                        if (property === 'type') {

                            result[property] = this[property].id;
                        } else {

                            result[property] = this[property];
                        }
                    }
                }

                if (this.id === undefined) {

                    promise = resource.post(containerId, result);
                } else {

                    promise = resource.put(this.id, result);
                }

                return promise;
            }

            /**
             * @returns {deferred.promise|Boolean}
             */

        }, {
            key: 'remove',
            value: function remove() {

                if (this.id === undefined) {

                    return false;
                }

                return resource.delete(this.id);
            }
        }]);

        return Variable;
    }();

    return Variable;
};

exports.default = variableFactory;

},{}],85:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var variableTypeFactory = function variableTypeFactory(resource) {

  /**
   * @name Variable
   * @description Variable entity
   */

  var VariableType = function VariableType() {
    _classCallCheck(this, VariableType);

    /**
     * @type {number}
     */
    this.id = undefined;

    /**
     * @type {string}
     */
    this.name = undefined;

    /**
     * @type {string}
     */
    this.helper = undefined;
  };

  return VariableType;
};

exports.default = variableTypeFactory;

},{}],86:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name variable#VariableFormProvider
 * @namespace clearcode.tm.variable
 */
/* eslint-disable */

var VariableFormProvider = function () {
    function VariableFormProvider() {
        _classCallCheck(this, VariableFormProvider);

        this.collection = {};
    }

    /**
     * @description Add variable form template for specific type
     *
     * @param {String} name
     * @param {Object} settings
     * @returns {VariableFormProvider}
     */


    _createClass(VariableFormProvider, [{
        key: 'addType',
        value: function addType(name, settings) {

            var self = this;

            if (self.collection[name] !== undefined) {

                throw new Error('Form type with provided name already exist');
            }

            self.collection[name] = settings;

            return self;
        }

        /**
         * @description Get specific form template by name of variable type
         *
         * @param {String} name
         * @returns {String|Boolean}
         */

    }, {
        key: 'getType',
        value: function getType(name) {

            var self = this;

            if (self.collection[name] !== undefined) {

                return self.collection[name];
            }

            return false;
        }
    }, {
        key: '$get',
        value: function $get() {

            return this;
        }
    }]);

    return VariableFormProvider;
}();
/* eslint-enable */


exports.default = VariableFormProvider;

},{}],87:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _Variable = require('../entity/Variable.js');

var _Variable2 = _interopRequireDefault(_Variable);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var Variable = (0, _Variable2.default)(this);

    var variable = new Variable();

    variable.id = resp.id;
    variable.name = resp.name;
    variable.description = resp.description;
    variable.type = resp.type;
    variable.value = resp.value;
    variable.options = resp.options;
    variable.updatedAt = resp.updated_at;

    return variable;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name VariableResource
 */

var VariableResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function VariableResource($http, $q) {
        _classCallCheck(this, VariableResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Variable}
     */


    _createClass(VariableResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var Variable = (0, _Variable2.default)(this);

            return new Variable();
        }

        /**
         * @description Send request to get specific entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'get',
        value: function get(id) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/variables/' + id,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {number} containerId
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(containerId, params) {
            var _this2 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + containerId + '/variables',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this2, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {number} containerId
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'queryAllAvailable',
        value: function queryAllAvailable(containerId, params) {
            var _this3 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/containers/' + containerId + '/available-variables',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this3, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to delete entity
         *
         * @param {number} id
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'delete',
        value: function _delete(id) {

            var deferred = this.q.defer();

            this.http({
                method: 'DELETE',
                url: '/api/variables/' + id
            }).success(function (data) {

                deferred.resolve(data);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * @description Send request to create entity
         *
         * @param {number} containerId
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'post',
        value: function post(containerId, entity) {
            var _this4 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'POST',
                url: '/api/containers/' + containerId + '/variables',
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this4, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }

        /**
         * Send request to update entity
         *
         * @param {number} id
         * @param {object} entity
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'put',
        value: function put(id, entity) {
            var _this5 = this;

            var deferred = this.q.defer();

            this.http({
                method: 'PUT',
                url: '/api/variables/' + id,
                data: entity,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp, header, status) {

                    if (status < 400) {

                        return transformResponse.call(_this5, resp.data);
                    } else {

                        return resp;
                    }
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return VariableResource;
}();

exports.default = VariableResource;

},{"../entity/Variable.js":84}],88:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      */

var _VariableType = require('../entity/VariableType.js');

var _VariableType2 = _interopRequireDefault(_VariableType);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var transformResponse = function transformResponse(resp) {

    var VariableType = (0, _VariableType2.default)(this);

    var type = new VariableType();

    type.id = resp.id;
    type.name = resp.name;
    type.helper = resp.helper;

    return type;
};

var appendTransform = function appendTransform(defaults, transform) {

    // We can't guarantee that the default transformation is an array
    defaults = angular.isArray(defaults) ? defaults : [defaults];

    // Append the new transformation to the defaults
    return defaults.concat(transform);
};

/**
 * @name VariableResource
 */

var VariableTypeResource = function () {
    /**
     * @param {$http} $http
     * @param {$q} $q
     */

    function VariableTypeResource($http, $q) {
        _classCallCheck(this, VariableTypeResource);

        this.http = $http;
        this.q = $q;
    }

    /**
     * @returns {Variable}
     */


    _createClass(VariableTypeResource, [{
        key: 'getEntityObject',
        value: function getEntityObject() {

            var VariableType = (0, _VariableType2.default)(this);

            return new VariableType();
        }

        /**
         * @description Send request to get list of entity
         *
         * @param {Object} params
         *
         * @returns {deferred.promise}
         */

    }, {
        key: 'query',
        value: function query(params) {
            var _this = this;

            var deferred = this.q.defer();

            this.http({
                method: 'GET',
                url: '/api/variable-types',
                params: params,
                transformResponse: appendTransform(this.http.defaults.transformResponse, function (resp) {

                    for (var element in resp.data) {

                        if (resp.data[element] instanceof Object) {

                            resp.data[element] = transformResponse.call(_this, resp.data[element]);
                        }
                    }

                    return resp;
                })
            }).success(function (resp) {

                deferred.resolve(resp);
            }).error(function (err) {

                deferred.reject(err);
            });

            return deferred.promise;
        }
    }]);

    return VariableTypeResource;
}();

exports.default = VariableTypeResource;

},{"../entity/VariableType.js":85}],89:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var scope = undefined,
    compile = undefined;

var template = '\n    <span class="input-group-addon" ng-click="selectorCtrl.toggleVariablesList($event)" style="cursor: pointer;">\n        <i class="icon-variable-insert"></i>\n    </span>';

var elementWrapper = '<div class="input-group cc-select"></div>';

/**
 * @name VariableSelector
 */

var VariableSelector = function () {
    function VariableSelector() {
        _classCallCheck(this, VariableSelector);
    }

    _createClass(VariableSelector, [{
        key: 'getTemplate',
        value: function getTemplate() {
            return template;
        }
    }, {
        key: 'getElementWrapper',
        value: function getElementWrapper() {
            return elementWrapper;
        }
    }, {
        key: 'addVariablePrompt',
        value: function addVariablePrompt(elementValue) {
            var text = !isVariablePromptOpen(elementValue) ? openVariablePrompt(elementValue) : elementValue;

            return text;
        }
    }, {
        key: 'selectVariable',
        value: function selectVariable(elementValue, variable) {
            return (elementValue + ' ' + variable.name + ' }}').trim();
        }
    }, {
        key: 'removeVariablePrompt',
        value: function removeVariablePrompt(elementValue) {
            return isVariablePromptOpen(elementValue) ? _removeVariablePrompt(elementValue) : elementValue;
        }
    }], [{
        key: 'selectorFactory',
        value: function selectorFactory() {
            return new VariableSelector();
        }
    }]);

    return VariableSelector;
}();

function isVariablePromptOpen(elementValue) {
    return elementValue.slice(-2) === '{{';
}

function openVariablePrompt(elementValue) {
    return (elementValue + ' {{').trim();
}

function _removeVariablePrompt(elementValue) {
    return elementValue.substring(0, elementValue.length - 2).trim();
}

exports.default = VariableSelector;

},{}],90:[function(require,module,exports){
'use strict';

var _ManageController = require('./controllers/ManageController.js');

var _ManageController2 = _interopRequireDefault(_ManageController);

var _CreateController = require('./controllers/CreateController.js');

var _CreateController2 = _interopRequireDefault(_CreateController);

var _EditController = require('./controllers/EditController.js');

var _EditController2 = _interopRequireDefault(_EditController);

var _SelectorController = require('./controllers/SelectorController');

var _SelectorController2 = _interopRequireDefault(_SelectorController);

var _VariableResource = require('./resource/VariableResource.js');

var _VariableResource2 = _interopRequireDefault(_VariableResource);

var _VariableTypeResource = require('./resource/VariableTypeResource.js');

var _VariableTypeResource2 = _interopRequireDefault(_VariableTypeResource);

var _VariableFormProvider = require('./providers/VariableFormProvider.js');

var _VariableFormProvider2 = _interopRequireDefault(_VariableFormProvider);

var _VariableSelector = require('./services/VariableSelector.js');

var _VariableSelector2 = _interopRequireDefault(_VariableSelector);

var _variableSelector = require('./directives/variableSelector.js');

var _variableSelector2 = _interopRequireDefault(_variableSelector);

var _variableClickOutside = require('./directives/variableClickOutside.js');

var _variableClickOutside2 = _interopRequireDefault(_variableClickOutside);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.variable';
var MODULE_DIR = 'app/modules/variable';

var CONDITION_MODULE_NAME = 'clearcode.tm.condition';

_ManageController2.default.$inject = ['ngTableParams', '$stateParams', MODULE_NAME + '.variableResource', 'clearcode.tm.alert.alert', 'clearcode.tm.tagContainer.paramConverter', 'clearcode.tm.tagContainer.currentContainer', 'clearcode.tm.pageInfo.pageInfo', '$scope', '$state', '$translate'];

_SelectorController2.default.$inject = ['$compile', '$stateParams', '$scope', MODULE_NAME + '.variableSelector', CONDITION_MODULE_NAME + '.condition'];

_CreateController2.default.$inject = [MODULE_NAME + '.variableResource', MODULE_NAME + '.variableTypeResource', 'clearcode.tm.pageInfo.pageInfo', '$scope', MODULE_NAME + '.$variableForm', 'clearcode.tm.tagContainer.currentContainer', '$stateParams', 'clearcode.tm.alert.alert', '$state', '$translate', 'clearcode.tm.condition.condition'];

_EditController2.default.$inject = [MODULE_NAME + '.variableResource', MODULE_NAME + '.variableTypeResource', 'clearcode.tm.pageInfo.pageInfo', '$scope', MODULE_NAME + '.$variableForm', 'clearcode.tm.tagContainer.currentContainer', '$stateParams', 'clearcode.tm.alert.alert', '$state', '$translate', 'clearcode.tm.condition.condition'];

_VariableResource2.default.$inject = ['$http', '$q'];

_variableClickOutside2.default.$inject = ['$document'];

_VariableTypeResource2.default.$inject = ['$http', '$q'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ui.router', 'ngTable', 'clearcode.tm.alert', 'clearcode.tm.pageInfo', 'clearcode.tm.tagContainer']).config(['$stateProvider', function ($stateProvider) {

    $stateProvider.state('variables', {
        url: '/containers/{containerId}/variables',
        controller: MODULE_NAME + '.ManageController as view',
        templateUrl: MODULE_DIR + '/views/manage.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('variableCreate', {
        url: '/containers/{containerId}/variable-create',
        controller: MODULE_NAME + '.CreateController as view',
        templateUrl: MODULE_DIR + '/views/create.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    }).state('variableEdit', {
        url: '/containers/{containerId}/variable-edit/{variableId}',
        controller: MODULE_NAME + '.EditController as view',
        templateUrl: MODULE_DIR + '/views/edit.html',
        data: {
            roles: ['ROLE_SUPER_ADMIN'],
            hasContainerList: true
        }
    });
}]).run(['clearcode.tm.alert.$alert', function ($alertProvider) {

    $alertProvider.addMessagePattern('variables.close', 'You are trying to close unsaved variable.');
}]).controller(MODULE_NAME + '.ManageController', _ManageController2.default).controller(MODULE_NAME + '.CreateController', _CreateController2.default).controller(MODULE_NAME + '.EditController', _EditController2.default).controller(MODULE_NAME + '.SelectorController', _SelectorController2.default).service(MODULE_NAME + '.variableResource', _VariableResource2.default).service(MODULE_NAME + '.variableTypeResource', _VariableTypeResource2.default).provider(MODULE_NAME + '.$variableForm', _VariableFormProvider2.default).service(MODULE_NAME + '.variableSelector', _VariableSelector2.default).directive('variableSelector', _variableSelector2.default).directive('variableClickOutside', _variableClickOutside2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/CreateController.js":78,"./controllers/EditController.js":79,"./controllers/ManageController.js":80,"./controllers/SelectorController":81,"./directives/variableClickOutside.js":82,"./directives/variableSelector.js":83,"./providers/VariableFormProvider.js":86,"./resource/VariableResource.js":87,"./resource/VariableTypeResource.js":88,"./services/VariableSelector.js":89}],91:[function(require,module,exports){
'use strict';

var _AlertProvider = require('./providers/AlertProvider.js');

var _AlertProvider2 = _interopRequireDefault(_AlertProvider);

var _AlertController = require('./controllers/AlertController.js');

var _AlertController2 = _interopRequireDefault(_AlertController);

var _AlertDirective = require('./directives/AlertDirective.js');

var _AlertDirective2 = _interopRequireDefault(_AlertDirective);

var _Alert = require('./services/Alert.js');

var _Alert2 = _interopRequireDefault(_Alert);

var _RefreshAlert = require('./services/RefreshAlert.js');

var _RefreshAlert2 = _interopRequireDefault(_RefreshAlert);

var _AlertStorage = require('./services/AlertStorage.js');

var _AlertStorage2 = _interopRequireDefault(_AlertStorage);

var _UniqueStorage = require('./services/UniqueStorage.js');

var _UniqueStorage2 = _interopRequireDefault(_UniqueStorage);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.alert'; /**
                                         * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                         *
                                         * This program is free software: you can redistribute it and/or modify it under
                                         * the terms of the GNU Affero General Public License as published by the Free
                                         * Software Foundation, either version 3 of the License, or (at your option) any
                                         * later version.
                                         *
                                         * This program is distrubuted in the hope that it will be useful,
                                         * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                         * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                         * GNU Affero General Public License for more details.
                                         *
                                         * You should have received a copy of the GNU Affero General Public License
                                         * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                         */

_Alert2.default.$inject = ['$timeout', MODULE_NAME + '.alertStorage', MODULE_NAME + '.$alert', '$translate'];

_RefreshAlert2.default.$inject = ['$timeout', MODULE_NAME + '.alertStorage', MODULE_NAME + '.$alert', '$translate', '$window'];

_AlertDirective2.default.$inject = [MODULE_NAME + '.alert', MODULE_NAME + '.$alert', '$window'];

_AlertController2.default.$inject = [MODULE_NAME + '.alertStorage'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'ngSanitize', 'ngAnimate']).provider(MODULE_NAME + '.$alert', _AlertProvider2.default).factory(MODULE_NAME + '.alertStorage', function () {

    return new _AlertStorage2.default();
}).factory(MODULE_NAME + '.alertUniqueStorage', function () {

    return new _UniqueStorage2.default();
}).service(MODULE_NAME + '.alert', _Alert2.default).service(MODULE_NAME + '.refreshAlert', _RefreshAlert2.default).directive('ccNotification', _AlertDirective2.default).controller(MODULE_NAME + '.AlertController', _AlertController2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/AlertController.js":92,"./directives/AlertDirective.js":93,"./providers/AlertProvider.js":94,"./services/Alert.js":95,"./services/AlertStorage.js":96,"./services/RefreshAlert.js":97,"./services/UniqueStorage.js":98}],92:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name alert#AlertController
 * @namespace clearcode.tm.form
 */

var AlertController = function () {

  /**
   * @param {AlertStorage} storage
   */

  function AlertController(AlertStorage) {
    _classCallCheck(this, AlertController);

    this.storage = AlertStorage;
  }

  /**
   * @description Get all alerts
   * @returns {Object}
   */


  _createClass(AlertController, [{
    key: "getAll",
    value: function getAll() {

      return this.storage.getAlerts();
    }

    /**
     * @description Delete alert
     * @param id
     */

  }, {
    key: "remove",
    value: function remove(id) {

      this.storage.remove(id);
    }

    /**
     * @description Clear alert
     * @param id
     */

  }, {
    key: "clear",
    value: function clear() {

      this.storage.clean();
    }
  }]);

  return AlertController;
}();

exports.default = AlertController;

},{}],93:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name alert#AlertDirective
 * @namespace clearcode.tm.alert
 */

var alertDirective = function alertDirective(Alert, alertProvider) {

    return {
        restrict: 'E',
        templateUrl: 'modules/alert/views/alert.html',
        scope: {
            interval: '='
        },
        controller: 'clearcode.tm.alert.AlertController',
        controllerAs: 'alertCtrl',
        link: function link(scope) {

            Alert.settings.interval = angular.isDefined(scope.interval) ? scope.interval : alertProvider.interval;
        }
    };
};

exports.default = alertDirective;

},{}],94:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name alert#AlertProvider
 * @namespace clearcode.tm.alert
 */
/* eslint-disable */

var AlertProvider = function () {
    function AlertProvider() {
        _classCallCheck(this, AlertProvider);

        this.interval = 6000;
        this.messagePatterns = {};
    }

    /**
     * @description Add message pattern
     *
     * @param {String} id
     * @param {String} pattern
     * @returns {AlertProvider}
     */


    _createClass(AlertProvider, [{
        key: 'addMessagePattern',
        value: function addMessagePattern(id, pattern) {

            var self = this;

            if (self.messagePatterns[id] !== undefined) {

                throw new Error('Pattern with provided id already exist');
            }

            self.messagePatterns[id] = pattern;

            return self;
        }

        /**
         * @description Add message patterns from array of objects
         *
         * @param {Array} patterns
         * @returns {AlertProvider}
         */

    }, {
        key: 'addMessagesPattern',
        value: function addMessagesPattern(patterns) {

            var self = this;

            if (!angular.isArray(patterns)) {

                throw new Error('AlertProvider.addMessagesPattern expects\n                 first argument to be Array but received ' + (typeof patterns === 'undefined' ? 'undefined' : _typeof(patterns)));
            }

            angular.forEach(patterns, function (obj) {

                if (angular.isObject(obj)) {

                    angular.forEach(obj, function (elem, key) {

                        self.addMessagePattern(key, elem);
                    });
                }
            });

            return self;
        }

        /**
         * @description Get specific message pattern by id
         *
         * @param {String} id
         * @returns {String|Boolean}
         */

    }, {
        key: 'getMessagePattern',
        value: function getMessagePattern(id) {

            var self = this;

            if (self.messagePatterns[id] !== undefined) {

                return self.messagePatterns[id];
            }

            return false;
        }
    }, {
        key: '$get',
        value: function $get() {

            return this;
        }
    }]);

    return AlertProvider;
}();
/* eslint-enable */


exports.default = AlertProvider;

},{}],95:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name alert#Alert
 * @namespace clearcode.tm.alert
 */

/**
* @description Remove Alert after Timeout
*
* @param {Integer} id from storage
*/
var removeAfterTimeout = function removeAfterTimeout(id) {

  var self = this;

  if (self.settings.interval > 0) {

    return self.$timeout(function () {

      self.storage.remove(id);
    }, self.settings.interval);
  }
};

/**
* @description Add alert to storage
*
* @param {String} type
* @param {String} pattern
* @param {Array} params
* @returns {Number}
*/
var addAlert = function addAlert(type, pattern, params) {

  var message,
      id,
      self = this;
  message = self.convertToMessage(pattern, params);

  self.translate([message]).then(function (translations) {

    id = self.storage.add(type, translations[message]);
    removeAfterTimeout.call(self, id);

    return id;
  });
};

var Alert = function () {
  function Alert($timeout, storage, provider, $translate) {
    _classCallCheck(this, Alert);

    /**
     * @type {AlertStorage}
     */
    this.storage = storage;
    /**
     * @type {Object}
     */
    this.$timeout = $timeout;
    /**
     * @type {TranslationProvider}
     */
    this.settings = this.settings === undefined ? provider : angular.extend(this.settings, provider);

    /**
     * @readonly
     * @type {string}
     */
    this.INFO_TYPE = 'info';

    /**
     * @readonly
     * @type {string}
     */
    this.ERROR_TYPE = 'danger';

    /**
     * @readonly
     * @type {string}
     */
    this.SUCCESS_TYPE = 'success';

    /**
     * @readonly
     * @type {$translate}
     */
    this.translate = $translate;
  }

  /**
   * @description Add success type alert
   *
   * @param {String} id
   * @param {Array} params
   */


  _createClass(Alert, [{
    key: 'success',
    value: function success(id) {
      var params = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];


      addAlert.call(this, this.SUCCESS_TYPE, id, params);
    }

    /**
     * @description Add error type alert
     *
     * @param {String} id
     * @param {Array} params
     */

  }, {
    key: 'error',
    value: function error(id) {
      var params = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];


      addAlert.call(this, this.ERROR_TYPE, id, params);
    }

    /**
     * @description Add info type alert
     *
     * @param {String} id
     * @param {Array} params
     */

  }, {
    key: 'info',
    value: function info(id) {
      var params = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];


      addAlert.call(this, this.INFO_TYPE, id, params);
    }

    /**
     * @returns {AlertStorage}
     */

  }, {
    key: 'getStorage',
    value: function getStorage() {

      return this.storage;
    }

    /**
     * @description Convert message if is a pattern
     *
     * @param {Object|String} pattern
     * @param {Array} params
     * @returns {String}
     */

  }, {
    key: 'convertToMessage',
    value: function convertToMessage(pattern, params) {

      var self, message;

      self = this;

      if (angular.isDefined(pattern)) {

        pattern = self.settings.getMessagePattern(pattern);
      }

      message = vsprintf(pattern, params);

      return message;
    }
  }]);

  return Alert;
}();

exports.default = Alert;

},{}],96:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name alert#AlertStorage
 * @namespace clearcode.tm.alert
 */

var indicator;

var AlertStorage = function () {
    function AlertStorage() {
        _classCallCheck(this, AlertStorage);

        indicator = 0;
        this.collection = {};
    }

    /**
     * @description Returns all alerts objects
     *
     * @returns {Object}
     */


    _createClass(AlertStorage, [{
        key: "getAlerts",
        value: function getAlerts() {

            return this.collection;
        }

        /**
         * @description Returns specific alert object
         *
         * @param {String} id
         * @returns {Object}
         */

    }, {
        key: "getAlert",
        value: function getAlert(id) {

            return this.collection[id];
        }

        /**
         * @description Add alert to collection
         *
         * @param {String} type
         * @param {String} message
         * @returns {Number}
         */

    }, {
        key: "add",
        value: function add(type, message) {

            this.collection[indicator] = {
                type: type,
                message: message
            };

            return indicator++;
        }

        /**
         * @description Check whether collection contains alert
         *
         * @param {String} type
         * @param {String} message
         * @returns {Boolean}
         */

    }, {
        key: "has",
        value: function has(type, message) {

            for (var alert in this.collection) {

                if (type === this.collection[alert].type && message === this.collection[alert].message) {

                    return true;
                }
            }

            return false;
        }

        /**
         * @description Remove alert from collection
         *
         * @param {Number} id
         * @returns {Boolean}
         */

    }, {
        key: "remove",
        value: function remove(id) {

            var self = this;

            if (self.collection[id] !== undefined) {

                delete self.collection[id];

                return true;
            }

            return false;
        }

        /**
         * @description Remove all alerts from collection
         */

    }, {
        key: "clean",
        value: function clean() {

            this.collection = {};
        }
    }]);

    return AlertStorage;
}();

exports.default = AlertStorage;

},{}],97:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _Alert2 = require('./Alert.js');

var _Alert3 = _interopRequireDefault(_Alert2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name alert#RefreshAlert
 * @namespace clearcode.tm.alert
 */

var RefreshAlert = function (_Alert) {
  _inherits(RefreshAlert, _Alert);

  /**
   * @param {$timeout} $timeout
   * @param {storage} storage
   * @param {provider} provider
   * @param {$translate} $translate
   * @param {$window} $window
   */

  function RefreshAlert($timeout, storage, provider, $translate, $window) {
    _classCallCheck(this, RefreshAlert);

    var _this = _possibleConstructorReturn(this, Object.getPrototypeOf(RefreshAlert).call(this, $timeout, storage, provider, $translate));

    _this.window = $window;

    return _this;
  }

  /**
   * Add success message after refresh page
   *
   * @param localStorageKey
   * @param message
   */


  _createClass(RefreshAlert, [{
    key: 'success',
    value: function success(localStorageKey, message) {

      if (this.window.localStorage[localStorageKey]) {

        _get(Object.getPrototypeOf(RefreshAlert.prototype), 'success', this).call(this, message);

        this.window.localStorage.removeItem(localStorageKey);
      }
    }
  }]);

  return RefreshAlert;
}(_Alert3.default);

exports.default = RefreshAlert;

},{"./Alert.js":95}],98:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _AlertStorage2 = require('./AlertStorage.js');

var _AlertStorage3 = _interopRequireDefault(_AlertStorage2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name alert#UniqueStorage
 * @namespace clearcode.tm.alert
 */

var UniqueStorage = function (_AlertStorage) {
  _inherits(UniqueStorage, _AlertStorage);

  function UniqueStorage() {
    _classCallCheck(this, UniqueStorage);

    return _possibleConstructorReturn(this, Object.getPrototypeOf(UniqueStorage).apply(this, arguments));
  }

  _createClass(UniqueStorage, [{
    key: 'add',


    /**
     * @description Add alert to collection
     *
     * @param {String} type
     * @param {String} message
     * @returns {Number}
     */
    value: function add(type, message) {

      if (this.has(type, message)) {

        return -1;
      }

      return _get(Object.getPrototypeOf(UniqueStorage.prototype), 'add', this).call(this, type, message);
    }
  }]);

  return UniqueStorage;
}(_AlertStorage3.default);

exports.default = UniqueStorage;

},{"./AlertStorage.js":96}],99:[function(require,module,exports){
'use strict';

var _clipboardDirective = require('./directives/clipboardDirective.js');

var _clipboardDirective2 = _interopRequireDefault(_clipboardDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.clipboard'; /**
                                             * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                             *
                                             * This program is free software: you can redistribute it and/or modify it under
                                             * the terms of the GNU Affero General Public License as published by the Free
                                             * Software Foundation, either version 3 of the License, or (at your option) any
                                             * later version.
                                             *
                                             * This program is distrubuted in the hope that it will be useful,
                                             * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                             * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                             * GNU Affero General Public License for more details.
                                             *
                                             * You should have received a copy of the GNU Affero General Public License
                                             * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                             */

_clipboardDirective2.default.$inject = ['$compile'];

angular.module(MODULE_NAME, ['ngClipboard']).directive('ccClipboard', _clipboardDirective2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./directives/clipboardDirective.js":100}],100:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var clipboardDirective = function clipboardDirective($compile) {

    return {
        restrict: 'A',
        scope: {
            ngModel: '='
        },
        link: function link(scope, element) {

            element.wrap('<div class="cc-clipboard"></div>');

            var template = angular.element(document.getElementById('clipboard.html')).html();
            var tplElement = $compile(template)(scope);
            var clipboard = element.parent();

            clipboard.append(tplElement);

            var button = clipboard.find('button');

            button.addClass('hidden');

            clipboard.on('mouseenter', function () {

                button.removeClass('hidden');
            }).on('mouseleave', function () {

                button.addClass('hidden');
            });

            scope.copyToClipboard = function () {

                return scope.ngModel;
            };
        }
    };
};

exports.default = clipboardDirective;

},{}],101:[function(require,module,exports){
'use strict';

var _ConfirmDirective = require('./directives/ConfirmDirective.js');

var _ConfirmDirective2 = _interopRequireDefault(_ConfirmDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.confirm'; /**
                                           * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                           *
                                           * This program is free software: you can redistribute it and/or modify it under
                                           * the terms of the GNU Affero General Public License as published by the Free
                                           * Software Foundation, either version 3 of the License, or (at your option) any
                                           * later version.
                                           *
                                           * This program is distrubuted in the hope that it will be useful,
                                           * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                           * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                           * GNU Affero General Public License for more details.
                                           *
                                           * You should have received a copy of the GNU Affero General Public License
                                           * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                           */

angular.module(MODULE_NAME, []).directive('ccConfirm', _ConfirmDirective2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./directives/ConfirmDirective.js":102}],102:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var confirmDirective = function confirmDirective() {

    return {
        restrict: 'A',
        templateUrl: 'modules/confirm/views/confirm.html',
        transclude: true,
        replace: true,
        scope: {
            ccConfirm: '&'
        },
        link: function link(scope) {

            scope.turnOn = false;

            scope.approve = function () {

                scope.ccConfirm();
            };

            scope.cancel = function () {

                scope.turnOn = false;
            };

            scope.confirm = function () {

                scope.turnOn = true;
            };
        }
    };
};

exports.default = confirmDirective;

},{}],103:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _AlertController2 = require('../../alert/controllers/AlertController.js');

var _AlertController3 = _interopRequireDefault(_AlertController2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name alert#AlertFormController
 * @namespace clearcode.tm.alert
 */

var AlertFormController = function (_AlertController) {
  _inherits(AlertFormController, _AlertController);

  function AlertFormController() {
    _classCallCheck(this, AlertFormController);

    return _possibleConstructorReturn(this, Object.getPrototypeOf(AlertFormController).apply(this, arguments));
  }

  return AlertFormController;
}(_AlertController3.default);

exports.default = AlertFormController;

},{"../../alert/controllers/AlertController.js":92}],104:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name form#AlertFormDirective
 * @namespace clearcode.tm.form
 */

var alertFormDirective = function alertFormDirective(Alert, alertProvider) {

    return {
        restrict: 'E',
        templateUrl: 'modules/form/views/alertForm.html',
        scope: {
            interval: '='
        },
        controller: 'clearcode.tm.form.AlertFormController',
        controllerAs: 'alertFormCtrl',
        link: function link(scope) {

            Alert.settings.interval = angular.isDefined(scope.interval) ? scope.interval : alertProvider.interval;
        }
    };
};

exports.default = alertFormDirective;

},{}],105:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name form#CheckboxDirective
 * @namespace clearcode.tm.form
 */
/* eslint-disable */
var CheckboxDirective = function CheckboxDirective() {

  return {
    restrict: 'A',
    require: '?ngModel',
    scope: {
      ngModel: '='
    },
    link: function link(scope, element, attrs) {

      var checkedClass = 'cc-checkbox-checked';

      element.wrap('<div class="cc-checkbox"></div>');

      if (scope.ngModel !== undefined) {

        if (scope.ngModel || attrs.ngTrueValue !== undefined && scope.ngModel === attrs.ngTrueValue) {

          element.parent().addClass(checkedClass);
        } else {

          element.parent().removeClass(checkedClass);
        }
      }

      scope.$watch('ngModel', function (value) {

        if (value === true || attrs.ngTrueValue !== undefined && value === attrs.ngTrueValue) {

          element.parent().addClass(checkedClass);
        } else {

          element.parent().removeClass(checkedClass);
        }
      });
    }
  };
};
/* eslint-enable */

exports.default = CheckboxDirective;

},{}],106:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name form#CheckboxDirective
 * @namespace clearcode.tm.form
 */
/* eslint-disable */
var RadioButtonDirective = function RadioButtonDirective() {

    return {
        restrict: 'A',
        require: '?ccRadioModel',
        scope: {
            ccRadioModel: '=',
            ccDisabled: '='
        },
        link: function link(scope, element, attrs) {

            element.wrap('<div class="cc-radio"></div>');

            if (scope.ccRadioModel !== undefined) {

                if (scope.ccDisabled) {

                    element.parent().addClass('radioDisabled');
                }

                if (scope.ccRadioModel === element[0].defaultValue) {

                    element.parent().addClass('radioSelected');
                } else {

                    element.parent().removeClass('radioSelected');
                }
            }

            scope.$watch('ccRadioModel', function (value) {

                if (value === element[0].defaultValue) {

                    element.parent().addClass('radioSelected');
                } else {

                    element.parent().removeClass('radioSelected');
                }
            });
        }
    };
};
/* eslint-enable */

exports.default = RadioButtonDirective;

},{}],107:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
	value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name form#SelectDirective
 * @namespace clearcode.tm.form
 */

var SelectDirective = function SelectDirective($compile) {

	/* eslint-disable */
	return {
		restrict: 'A',
		priority: 1,
		scope: {
			ngModel: '=',
			ccSelectOptions: '=',
			ccSelectValue: '@',
			ccSelectLabel: '@'
		},
		link: function link(scope, element, attrs) {

			element.wrap('<div class="cc-select"></div>');
			element.addClass('ng-hide');

			var template = angular.element(document.getElementById('select-template.html')).html();
			var tmpElement = $compile(template)(scope);

			element.parent().append(tmpElement);

			scope.id = attrs.id;

			var searchByValue = function searchByValue(value) {

				for (var index in scope.ccSelectOptions) {

					if (scope.ccSelectOptions[index][scope.ccSelectValue] === value) {

						return index;
					}
				}

				return false;
			};

			scope.getActiveOptionLabel = function () {

				var index = searchByValue(scope.ngModel);

				if (index !== false) {

					return scope.ccSelectOptions[index][scope.ccSelectLabel];
				} else {

					return '';
				}
			};

			scope.changeSelection = function (element) {

				scope.ngModel = element[scope.ccSelectValue];
			};
		}
	};
	/* eslint-enable */
};

exports.default = SelectDirective;

},{}],108:[function(require,module,exports){
'use strict';

var _CheckboxDirective = require('./directives/CheckboxDirective.js');

var _CheckboxDirective2 = _interopRequireDefault(_CheckboxDirective);

var _RadioButtonDirective = require('./directives/RadioButtonDirective.js');

var _RadioButtonDirective2 = _interopRequireDefault(_RadioButtonDirective);

var _SelectDirective = require('./directives/SelectDirective.js');

var _SelectDirective2 = _interopRequireDefault(_SelectDirective);

var _AlertForm = require('./services/AlertForm.js');

var _AlertForm2 = _interopRequireDefault(_AlertForm);

var _AlertFormDirective = require('./directives/AlertFormDirective.js');

var _AlertFormDirective2 = _interopRequireDefault(_AlertFormDirective);

var _AlertFormController = require('./controller/AlertFormController.js');

var _AlertFormController2 = _interopRequireDefault(_AlertFormController);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.form';

_AlertForm2.default.$inject = ['$timeout', 'clearcode.tm.alert.alertUniqueStorage', 'clearcode.tm.alert.$alert', '$translate'];

_AlertFormDirective2.default.$inject = [MODULE_NAME + '.alertForm', 'clearcode.tm.alert.$alert'];

_AlertFormController2.default.$inject = ['clearcode.tm.alert.alertUniqueStorage'];

_SelectDirective2.default.$inject = ['$compile'];

angular.module(MODULE_NAME, ['pascalprecht.translate', 'clearcode.tm.alert']).service(MODULE_NAME + '.alertForm', _AlertForm2.default).directive('ccSelect', _SelectDirective2.default).directive('ccCheckbox', _CheckboxDirective2.default).directive('ccRadio', _RadioButtonDirective2.default).directive('ccNotificationForm', _AlertFormDirective2.default).controller(MODULE_NAME + '.AlertFormController', _AlertFormController2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controller/AlertFormController.js":103,"./directives/AlertFormDirective.js":104,"./directives/CheckboxDirective.js":105,"./directives/RadioButtonDirective.js":106,"./directives/SelectDirective.js":107,"./services/AlertForm.js":109}],109:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _Alert2 = require('../../alert/services/Alert.js');

var _Alert3 = _interopRequireDefault(_Alert2);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; } /**
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is free software: you can redistribute it and/or modify it under
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * the terms of the GNU Affero General Public License as published by the Free
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * Software Foundation, either version 3 of the License, or (at your option) any
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * later version.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * This program is distrubuted in the hope that it will be useful,
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * GNU Affero General Public License for more details.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                *
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * You should have received a copy of the GNU Affero General Public License
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                */

/**
 * @name alert#AlertForm
 * @namespace clearcode.tm.form
 */

var AlertForm = function (_Alert) {
  _inherits(AlertForm, _Alert);

  function AlertForm() {
    _classCallCheck(this, AlertForm);

    return _possibleConstructorReturn(this, Object.getPrototypeOf(AlertForm).apply(this, arguments));
  }

  return AlertForm;
}(_Alert3.default);

exports.default = AlertForm;

},{"../../alert/services/Alert.js":95}],110:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name helpSystem#HelpSystemDirective
 * @namespace clearcode.tm.helpSystem
 */

var helpSystemDirective = function helpSystemDirective() {

  return {
    restrict: 'EA',
    replace: true,
    scope: { content: '@', placement: '@' },
    templateUrl: 'modules/helpSystem/views/helpSystem.html'
  };
};

exports.default = helpSystemDirective;

},{}],111:[function(require,module,exports){
'use strict';

var _HelpSystemDirective = require('./directives/HelpSystemDirective.js');

var _HelpSystemDirective2 = _interopRequireDefault(_HelpSystemDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.helpSystem'; /**
                                              * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                              *
                                              * This program is free software: you can redistribute it and/or modify it under
                                              * the terms of the GNU Affero General Public License as published by the Free
                                              * Software Foundation, either version 3 of the License, or (at your option) any
                                              * later version.
                                              *
                                              * This program is distrubuted in the hope that it will be useful,
                                              * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                              * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                              * GNU Affero General Public License for more details.
                                              *
                                              * You should have received a copy of the GNU Affero General Public License
                                              * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                              */

angular.module(MODULE_NAME, []).directive('ccHelp', _HelpSystemDirective2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./directives/HelpSystemDirective.js":110}],112:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {$state}
 */
var state;

var BreadcrumbController = function () {

  /**
   * @param PageInfo
   */

  function BreadcrumbController(scope, $state, PageInfo) {
    var _this = this;

    _classCallCheck(this, BreadcrumbController);

    this.breadcrumb = PageInfo.get();
    state = $state;

    scope.$on('page.info', function () {

      _this.breadcrumb = PageInfo.get();
    });
  }

  /**
   * @param breadcrumb
   */


  _createClass(BreadcrumbController, [{
    key: 'stateGo',
    value: function stateGo(breadcrumb) {

      state.go(breadcrumb.state, breadcrumb.params);
    }
  }]);

  return BreadcrumbController;
}();

exports.default = BreadcrumbController;

},{}],113:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {String}
 */
var DEFAULT_TITLE = '7tag Welcome';

var TitleController = function () {

    /**
     * @param {Object} scope
     * @param PageInfo
     */

    function TitleController(scope, PageInfo) {
        var _this = this;

        _classCallCheck(this, TitleController);

        this.title = DEFAULT_TITLE;
        this.default = DEFAULT_TITLE;

        scope.$on('page.info', function () {

            _this.info = PageInfo.get();
            _this.setTitle();
        });
    }

    _createClass(TitleController, [{
        key: 'setTitle',
        value: function setTitle() {

            this.title = this.info.map(function (element) {
                return element.name;
            }).reduce(function (previous, current) {
                return previous += ' - ' + current;
            });
        }
    }]);

    return TitleController;
}();

exports.default = TitleController;

},{}],114:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name pageInfo#BreadcrumbDirective
 * @namespace clearcode.tm.pageInfo
 */

var breadcrumbDirective = function breadcrumbDirective() {

  return {
    restrict: 'E',
    templateUrl: 'modules/pageInfo/views/breadcrumb.html',
    controller: 'clearcode.tm.pageInfo.BreadcrumbController',
    controllerAs: 'breadcrumbCtrl'
  };
};

exports.default = breadcrumbDirective;

},{}],115:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name pageInfo#TitleDirective
 * @namespace clearcode.tm.pageInfo
 */
var titleDirective = function titleDirective() {

  return {
    restrict: 'A',
    controller: 'clearcode.tm.pageInfo.TitleController',
    controllerAs: 'titleCtrl'
  };
};

exports.default = titleDirective;

},{}],116:[function(require,module,exports){
'use strict';

var _BreadcrumbController = require('./controllers/BreadcrumbController.js');

var _BreadcrumbController2 = _interopRequireDefault(_BreadcrumbController);

var _TitleController = require('./controllers/TitleController.js');

var _TitleController2 = _interopRequireDefault(_TitleController);

var _BreadcrumbDirective = require('./directives/BreadcrumbDirective.js');

var _BreadcrumbDirective2 = _interopRequireDefault(_BreadcrumbDirective);

var _PageInfo = require('./services/PageInfo.js');

var _PageInfo2 = _interopRequireDefault(_PageInfo);

var _TitleDirective = require('./directives/TitleDirective.js');

var _TitleDirective2 = _interopRequireDefault(_TitleDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.pageInfo'; /**
                                            * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                            *
                                            * This program is free software: you can redistribute it and/or modify it under
                                            * the terms of the GNU Affero General Public License as published by the Free
                                            * Software Foundation, either version 3 of the License, or (at your option) any
                                            * later version.
                                            *
                                            * This program is distrubuted in the hope that it will be useful,
                                            * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                            * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                            * GNU Affero General Public License for more details.
                                            *
                                            * You should have received a copy of the GNU Affero General Public License
                                            * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                            */

_BreadcrumbController2.default.$inject = ['$scope', '$state', MODULE_NAME + '.pageInfo'];

_TitleController2.default.$inject = ['$scope', MODULE_NAME + '.pageInfo'];

_PageInfo2.default.$inject = ['$rootScope'];

angular.module(MODULE_NAME, []).controller(MODULE_NAME + '.BreadcrumbController', _BreadcrumbController2.default).controller(MODULE_NAME + '.TitleController', _TitleController2.default).directive('ccBreadcrumb', _BreadcrumbDirective2.default).directive('ccTitle', _TitleDirective2.default).service(MODULE_NAME + '.pageInfo', _PageInfo2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./controllers/BreadcrumbController.js":112,"./controllers/TitleController.js":113,"./directives/BreadcrumbDirective.js":114,"./directives/TitleDirective.js":115,"./services/PageInfo.js":117}],117:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @type {rootScope}
 */
var rootScope;

var PageInfo = function () {

  /**
   * @param $rootScope
   */

  function PageInfo($rootScope) {
    _classCallCheck(this, PageInfo);

    this.navigation = [];

    rootScope = $rootScope;
  }

  /**
   * @param name
   * @param state
   * @param params
   *
   * @return {PageInfo}
   */


  _createClass(PageInfo, [{
    key: 'add',
    value: function add(name, state, params) {

      this.navigation.push({
        name: name,
        state: state,
        params: params
      });

      return this;
    }

    /**
     * @return {PageInfo}
     */

  }, {
    key: 'clear',
    value: function clear() {

      this.navigation = [];

      return this;
    }

    /**
     * Gives signal into breadcrumb and title
     */

  }, {
    key: 'broadcast',
    value: function broadcast() {

      rootScope.$broadcast('page.info');
    }

    /**
     * @return {Array}
     */

  }, {
    key: 'get',
    value: function get() {

      return this.navigation;
    }
  }]);

  return PageInfo;
}();

exports.default = PageInfo;

},{}],118:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * Math.ceil in angular filter
 *
 * @returns {Function}
 *
 * @author Arek Zając <a.zajac@clearcode.cc>
 */
var tmCeilFilter = function tmCeilFilter() {

  return function (number) {

    return Math.ceil(number);
  };
};

exports.default = tmCeilFilter;

},{}],119:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var tmDateFilter = function tmDateFilter($filter, defaultDateFormat) {

    var angularDateFilter = $filter('date');

    return function (theDate) {
        var format = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];


        if (format === null) {

            format = defaultDateFormat;
        }

        return angularDateFilter(theDate, format);
    };
};

exports.default = tmDateFilter;

},{}],120:[function(require,module,exports){
'use strict';

var _tmDateFilter = require('./filters/tmDateFilter.js');

var _tmDateFilter2 = _interopRequireDefault(_tmDateFilter);

var _tmCeilFilter = require('./filters/tmCeilFilter.js');

var _tmCeilFilter2 = _interopRequireDefault(_tmCeilFilter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.sanitize';

_tmDateFilter2.default.$inject = ['$filter', 'defaultDateFormat'];

angular.module(MODULE_NAME, []).filter('tmDate', _tmDateFilter2.default).filter('tmCeil', _tmCeilFilter2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./filters/tmCeilFilter.js":118,"./filters/tmDateFilter.js":119}],121:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name HTMLLinter
 */

var HTMLLinter = function () {
    function HTMLLinter($window) {
        _classCallCheck(this, HTMLLinter);

        this.$window = $window;
    }

    _createClass(HTMLLinter, [{
        key: "lint",
        value: function lint(text, lintRules) {

            return this.$window.HTMLHint ? this.$window.HTMLHint.verify(text, lintRules) : [];
        }
    }]);

    return HTMLLinter;
}();

exports.default = HTMLLinter;

},{}],122:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var HTML_LINT_RULES = {
    'tagname-lowercase': false,
    'attr-lowercase': false,
    'attr-value-double-quotes': false,
    'attr-value-not-empty': false,
    'attr-no-duplication': false,
    'doctype-first': false,
    'tag-pair': false,
    'tag-self-close': false,
    'spec-char-escape': false,
    'id-unique': false,
    'src-not-empty': false,
    'title-require': false,
    'head-script-disabled': false,
    'doctype-html5': false,
    'id-class-value': false,
    'style-disabled': false,
    'inline-style-disabled': false,
    'inline-script-disabled': false,
    'space-tab-mixed-disabled': false,
    'id-class-ad-disabled': false,
    'href-abs-or-rel': false,
    'attr-unsafe-chars': false
};

var JS_LINT_RULES = {
    'browser': true,
    'devel': true,
    'dojo': true,
    'jquery': true,
    'mootools': true,
    'nonstandard': true,
    'prototypejs': true,
    'yui': true,
    'bitwise': false,
    'camelcase': false,
    'curly': true,
    'enforceall': false,
    'eqeqeq': true,
    'es3': false,
    'es5': false,
    'forin': false,
    'freeze': false,
    'funcscope': true,
    'futurehostile': true,
    'immed': false,
    'latedef': false,
    'nocomma': true,
    'nonew': true,
    'notypeof': true,
    'shadow': 'inner',
    'strict': false,
    'sub': false,
    'undef': true,
    'unused': true,
    'asi': true
};

/**
 * @name LintingRules
 */

var LintingRules = function () {
    function LintingRules() {
        _classCallCheck(this, LintingRules);
    }

    _createClass(LintingRules, [{
        key: 'getLintingRules',
        value: function getLintingRules() {
            var jsHintRules = angular.copy(JS_LINT_RULES),
                htmlHintRules = angular.copy(HTML_LINT_RULES);

            htmlHintRules.jshint = jsHintRules;

            return htmlHintRules;
        }
    }]);

    return LintingRules;
}();

exports.default = LintingRules;

},{}],123:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var HTML_HEAD_WRAP_START = '<!DOCTYPE HTML><html><head>';
var HTML_HEAD_WRAP_END = '</head><body></body></html>';

/**
 * @name TagTemplateLinter
 */

var TagTemplateLinter = function () {

    /**
     * @param  TagTemplateToJsConverter tagTemplateToJsConverter
     * @param  LintingRules lintingRules
     * @param  HtmlLinter htmlLinter
     */

    function TagTemplateLinter(tagTemplateToJsConverter, lintingRules, htmlLinter) {
        _classCallCheck(this, TagTemplateLinter);

        this.tagTemplateToJsConverter = tagTemplateToJsConverter;
        this.lintingRules = lintingRules;
        this.htmlLinter = htmlLinter;
    }

    /**
     * @param  {string} text
     * @param  {object} options
     * @param  {object} cm
     * @return {[object]}
     */


    _createClass(TagTemplateLinter, [{
        key: 'validate',
        value: function validate(text, options, cm) {

            if (options.deactivate) {
                return [];
            }

            var htmlWrappedText = this.wrapInHtmlTag(text),
                lines = htmlWrappedText.split('\n');

            this.tagTemplateToJsConverter.convert(lines, cm.options);

            var tagTemplateWithFixedJsText = lines.join('\n'),
                allErrors = this.lint(tagTemplateWithFixedJsText, options),
                jsErrors = this.filterOutHtmlErrors(allErrors, lines.length),
                errors = [];

            for (var i = 0; i < jsErrors.length; i++) {
                errors.push(this.mapToLintRepresentation(jsErrors[i]));
            }

            return errors;
        }

        /**
         * @param  {string} text
         * @return {string}
         */

    }, {
        key: 'wrapInHtmlTag',
        value: function wrapInHtmlTag(text) {

            return HTML_HEAD_WRAP_START + '\n                ' + text + '\n                ' + HTML_HEAD_WRAP_END;
        }

        /**
         * @param  {[object]} errors
         * @param  {number} lineCount
         * @return {[object]}
         */

    }, {
        key: 'filterOutHtmlErrors',
        value: function filterOutHtmlErrors(errors, lineCount) {

            var jsErrors = [];

            for (var i = 0; i < errors.length; i++) {
                var error = errors[i];

                if (!this.isHtmlError(error, lineCount)) {

                    error.line -= 1;
                    jsErrors.push(error);
                }
            }

            return jsErrors;
        }

        /**
         * @param  {object}  error
         * @param  {number}  lastLine
         * @return {Boolean}
         */

    }, {
        key: 'isHtmlError',
        value: function isHtmlError(error, lastLineIdx) {

            return error.line === 0 || error.line === lastLineIdx;
        }

        /**
         * @param  {string} text
         * @param  {object} options
         * @return {[object]}
         */

    }, {
        key: 'lint',
        value: function lint(text, options) {

            options = options || {};

            var lintRules = angular.extend({}, this.lintingRules.getLintingRules(), options);

            return this.htmlLinter.lint(text, lintRules);
        }

        /**
         * @param  {object} message
         * @return {object}
         */

    }, {
        key: 'mapToLintRepresentation',
        value: function mapToLintRepresentation(message) {

            return {
                from: CodeMirror.Pos(message.line - 1, 0),
                to: CodeMirror.Pos(message.line - 1, 0),
                message: message.message,
                severity: message.type
            };
        }
    }]);

    return TagTemplateLinter;
}();

exports.default = TagTemplateLinter;

},{}],124:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var TAG_TEMPLATE_VARIABLE_START = '{{';
var TAG_TEMPLATE_VARIABLE_END = '}}';

var TagTemplateToJsConverter = function () {
    function TagTemplateToJsConverter() {
        _classCallCheck(this, TagTemplateToJsConverter);
    }

    _createClass(TagTemplateToJsConverter, [{
        key: 'convert',


        /**
         * @param  {[string]} textLines
         * @param  {object} options
         * @return {[string]}
         */
        value: function convert(textLines, options) {

            if (!options || !options.variables) {
                return textLines;
            }

            var variables = this.getVariables(options),
                lineIdx = 0;

            while (!this.isCursorAfterTheEndOfText(textLines.length, lineIdx)) {
                var line = textLines[lineIdx],
                    lineContainsVariable = line.indexOf(TAG_TEMPLATE_VARIABLE_START) !== -1;

                if (lineContainsVariable) {
                    textLines[lineIdx] = this.replaceVariablesExpressions(variables, line);
                }

                ++lineIdx;
            }

            return textLines;
        }

        /**
         * @param  {number}  totalLines
         * @param  {number}  line
         * @return {Boolean}
         */

    }, {
        key: 'isCursorAfterTheEndOfText',
        value: function isCursorAfterTheEndOfText(totalLines, line) {

            return line >= totalLines;
        }

        /**
         * @param  {object} options
         * @return {[object]}
         */

    }, {
        key: 'getVariables',
        value: function getVariables(options) {

            var variables = [];

            for (var i = 0; i < options.variables.length; i++) {

                var variable = options.variables[i],
                    words = variable.name.trim().split(/\s+/),
                    variableObj = {
                    name: variable.name,
                    pattern: this.createVariableSearchPattern(variable.name),
                    replacement: '\'' + words.join('_') + '\''
                };

                variables.push(variableObj);
            }

            return variables;
        }

        /**
         * @param  {string} variableName
         * @return {RegExp}
         */

    }, {
        key: 'createVariableSearchPattern',
        value: function createVariableSearchPattern(variableName) {

            var whitespace = '[\\s]',
                words = variableName.split(/[\s]+/),
                regexStr = words.join(whitespace + '+');

            regexStr = '' + TAG_TEMPLATE_VARIABLE_START + whitespace + '*' + regexStr + whitespace + '*' + TAG_TEMPLATE_VARIABLE_END;

            return new RegExp(regexStr, "g");
        }

        /**
         * @param  {[object]} variables
         * @param  {[string]} line
         * @return {string}
         */

    }, {
        key: 'replaceVariablesExpressions',
        value: function replaceVariablesExpressions(variables, line) {

            for (var i = 0; i < variables.length; i++) {
                var variable = variables[i];
                line = line.replace(variable.pattern, variable.replacement);
            }

            return line;
        }
    }]);

    return TagTemplateToJsConverter;
}();

exports.default = TagTemplateToJsConverter;

},{}],125:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var DEFAULT_CONFIG = {
    mode: {
        name: "htmlmixed"
    },
    lineNumbers: true,
    styleActiveLine: true,
    matchBrackets: true,
    theme: 'stg',
    gutters: ["CodeMirror-lint-markers"],
    lint: {}
};

/**
 * @name TextEditorConfig
 */

var TextEditorConfig = function () {

    /**
     * @param {TagTemplateLinter} tagTemplateLinter
     */

    function TextEditorConfig(tagTemplateLinter) {
        _classCallCheck(this, TextEditorConfig);

        this.tagTemplateLinter = tagTemplateLinter;
    }

    /**
     * @param {Object} configOverrides
     *
     * @return {Object}
     */


    _createClass(TextEditorConfig, [{
        key: "getConfig",
        value: function getConfig(configOverrides) {
            var _this = this;

            var overrides = angular.isObject(configOverrides) ? configOverrides : {},
                cfg = angular.extend({}, DEFAULT_CONFIG, overrides);

            cfg.lint.getAnnotations = function (value, opt, cm) {
                return _this.tagTemplateLinter.validate(value, opt, cm);
            };

            return cfg;
        }
    }]);

    return TextEditorConfig;
}();

exports.default = TextEditorConfig;

},{}],126:[function(require,module,exports){
'use strict';

var _TextEditorConfig = require('./service/TextEditorConfig.js');

var _TextEditorConfig2 = _interopRequireDefault(_TextEditorConfig);

var _TagTemplateLinter = require('./service/TagTemplateLinter.js');

var _TagTemplateLinter2 = _interopRequireDefault(_TagTemplateLinter);

var _TagTemplateToJsConverter = require('./service/TagTemplateToJsConverter.js');

var _TagTemplateToJsConverter2 = _interopRequireDefault(_TagTemplateToJsConverter);

var _LintingRules = require('./service/LintingRules.js');

var _LintingRules2 = _interopRequireDefault(_LintingRules);

var _HTMLLinter = require('./service/HTMLLinter.js');

var _HTMLLinter2 = _interopRequireDefault(_HTMLLinter);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.textEditor'; /**
                                              * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                              *
                                              * This program is free software: you can redistribute it and/or modify it under
                                              * the terms of the GNU Affero General Public License as published by the Free
                                              * Software Foundation, either version 3 of the License, or (at your option) any
                                              * later version.
                                              *
                                              * This program is distrubuted in the hope that it will be useful,
                                              * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                              * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                              * GNU Affero General Public License for more details.
                                              *
                                              * You should have received a copy of the GNU Affero General Public License
                                              * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                              */

_TextEditorConfig2.default.$inject = [MODULE_NAME + '.TagTemplateLinter'];

_TagTemplateLinter2.default.$inject = [MODULE_NAME + '.TagTemplateToJsConverter', MODULE_NAME + '.LintingRules', MODULE_NAME + '.HTMLLinter'];

_HTMLLinter2.default.$inject = ['$window'];

angular.module(MODULE_NAME, []).service(MODULE_NAME + '.LintingRules', _LintingRules2.default).service(MODULE_NAME + '.HTMLLinter', _HTMLLinter2.default).service(MODULE_NAME + '.TagTemplateToJsConverter', _TagTemplateToJsConverter2.default).service(MODULE_NAME + '.TagTemplateLinter', _TagTemplateLinter2.default).service(MODULE_NAME + '.TextEditorConfig', _TextEditorConfig2.default).run(['$rootScope', MODULE_NAME + '.TextEditorConfig', function ($rootScope, TextEditorConfig) {

    $rootScope.TextEditorConfig = TextEditorConfig;
}]);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./service/HTMLLinter.js":121,"./service/LintingRules.js":122,"./service/TagTemplateLinter.js":123,"./service/TagTemplateToJsConverter.js":124,"./service/TextEditorConfig.js":125}],127:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var translationFactory = function translationFactory($http, $q) {

    return function (options) {

        var deferred = $q.defer();

        $http({
            method: 'GET',
            url: options.url,
            cache: options.cache
        }).success(function (data) {

            deferred.resolve(data);
        }).error(function () {

            deferred.reject();
        });

        return deferred.promise;
    };
};

exports.default = translationFactory;

},{}],128:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var Translation = function () {
    function Translation(translationFactory) {
        _classCallCheck(this, Translation);

        this.languages = [];
        this.translationFactory = translationFactory;
    }

    /**
     * @returns {*}
     */


    _createClass(Translation, [{
        key: 'getLanguages',
        value: function getLanguages() {

            return this.languages;
        }

        /**
         * @param language
         * @returns {boolean}
         */

    }, {
        key: 'hasLanguage',
        value: function hasLanguage(language) {

            return this.languages.hasOwnProperty(language);
        }
    }, {
        key: 'loadAvailableTranslations',
        value: function loadAvailableTranslations() {
            var _this = this;

            return this.translationFactory({
                'url': '/api/translations',
                'cache': false
            }).then(function (data) {

                for (var key in data) {

                    _this.languages.push({
                        id: key,
                        name: data[key]
                    });
                }
            });
        }
    }]);

    return Translation;
}();

exports.default = Translation;

},{}],129:[function(require,module,exports){
'use strict';

var _TranslationFactory = require('./factory/TranslationFactory.js');

var _TranslationFactory2 = _interopRequireDefault(_TranslationFactory);

var _Translation = require('./services/Translation.js');

var _Translation2 = _interopRequireDefault(_Translation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

var MODULE_NAME = 'clearcode.tm.translation';

_TranslationFactory2.default.$inject = ['$http', '$q'];

_Translation2.default.$inject = [MODULE_NAME + '.translationFactory'];

angular.module(MODULE_NAME, []).factory(MODULE_NAME + '.translationFactory', _TranslationFactory2.default).service(MODULE_NAME + '.translation', _Translation2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./factory/TranslationFactory.js":127,"./services/Translation.js":128}],130:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj; };

/**
 * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
 *
 * This program is free software: you can redistribute it and/or modify it under
 * the terms of the GNU Affero General Public License as published by the Free
 * Software Foundation, either version 3 of the License, or (at your option) any
 * later version.
 *
 * This program is distrubuted in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * @name validator#stgFormDirective
 * @namespace clearcode.tm.validator
 */

var isPromise = function isPromise(promise) {

    if ((typeof promise === 'undefined' ? 'undefined' : _typeof(promise)) === 'object' && typeof promise.then === 'function') {

        return true;
    }

    return false;
};

var stgFormDirective = function stgFormDirective($alertProvider, $alertForm) {

    return {

        restrict: 'A',
        scope: {
            formResource: '='
        },
        link: function link(scope, element) {

            scope.$watch('formResource', function (formPromise) {

                if (formPromise && isPromise(formPromise)) {

                    formPromise.then(function () {}, function (resp) {

                        if (resp.errors.hasOwnProperty('form')) {

                            if (!$alertProvider.getMessagePattern('login.server')) {

                                $alertProvider.addMessagePattern('login.server', resp.errors.form[0]);
                            }

                            $alertForm.getStorage().clean();
                            $alertForm.error('login.server');
                        }

                        for (var field in resp.errors.fields) {

                            if (resp.errors.fields.hasOwnProperty(field)) {

                                var form = scope.$parent[element[0].attributes.name.value];

                                if (resp.errors.fields[field] instanceof Array && resp.errors.fields[field].length !== 0) {

                                    form[field].$invalid = true;
                                    form[field].$setDirty();
                                    form[field].$error[field] = resp.errors.fields[field];
                                } else {

                                    for (var nested in resp.errors.fields[field].fields) {

                                        if (resp.errors.fields[field].fields.hasOwnProperty(nested) && resp.errors.fields[field].fields[nested].length !== 0) {

                                            form[nested].$invalid = true;
                                            form[nested].$error[nested] = resp.errors.fields[field].fields[nested];
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            }, false);
        }

    };
};

exports.default = stgFormDirective;

},{}],131:[function(require,module,exports){
'use strict';

var _stgFormDirective = require('./directives/stgFormDirective.js');

var _stgFormDirective2 = _interopRequireDefault(_stgFormDirective);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MODULE_NAME = 'clearcode.tm.validator'; /**
                                             * Copyright (C) 2015 Digimedia Sp. z.o.o. d/b/a Clearcode
                                             *
                                             * This program is free software: you can redistribute it and/or modify it under
                                             * the terms of the GNU Affero General Public License as published by the Free
                                             * Software Foundation, either version 3 of the License, or (at your option) any
                                             * later version.
                                             *
                                             * This program is distrubuted in the hope that it will be useful,
                                             * but WITHOUT ANY WARRANTY; without even the implied warranty of
                                             * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
                                             * GNU Affero General Public License for more details.
                                             *
                                             * You should have received a copy of the GNU Affero General Public License
                                             * along with this program. If not, see <http://www.gnu.org/licenses/>.
                                             */

_stgFormDirective2.default.$inject = ['clearcode.tm.alert.$alert', 'clearcode.tm.form.alertForm'];

angular.module(MODULE_NAME, ['clearcode.tm.alert', 'clearcode.tm.form']).directive('stgForm', _stgFormDirective2.default);

module.exports = { MODULE_NAME: MODULE_NAME };

},{"./directives/stgFormDirective.js":130}]},{},[1])

